/**
 @Class          : UIDataManager
 @Maker          : 임성진
 @Date           : 2022-04-25
 @Debug DATE     : 
 @Debug Contents : -
 @Version        : 1.0
 @Description    : UI 데이터 관리 클래스
                   데이터 관리, 싱글톤
 */

export default class UIDataManager
{
    static m_objInit;           // 객체 생성 확인용
    static m_uiDataManager;     // 인스턴스 객체
    static m_arrDatas = null;   // 전체 데이터
    static m_arrChatData = [
        { roomType: "Room_Type_Normal", messageType: "chatting", name: "AI", msg: "안녕하세요, AI통일 콘텐츠 전시장에 오신 것을 환영합니다." }
        ,{ roomType: "Room_Type_Lobby", messageType: "chatting", name: "AI", msg: "안녕하세요, AI통일 콘텐츠 전시장에 오신 것을 환영합니다." }];   // 채팅 데이터
    
    static m_objLoginInputData = {}
    static m_objDetailArtData = {}  //상세보기 모달 데이터
    static m_objPivotData = {}  //상세보기 모달 데이터
    static m_isChatAllTyp = false;   // 채팅 룸타입 전체 여부
    static m_arrArtWorkListData = [];   // 검색 된 아트워크 데이터
    

    
    /**===============================================================================
    * constructor()      : 
    * @param _objInit   : 싱글톤 생성 확인용 객체
    *===============================================================================*/
    constructor(_objInit)
    {
        if (_objInit !== UIDataManager.m_objInit) {
            throw new Error( "UIDataManager 생성은 다음과 같이 해주세요 - UIDataManager.GetInstance()" ); 
        }
    }
    
    /**===============================================================================
    * GetInstance()      : 
    * @return            : 커뮤니케이터 인스턴스 리턴
    *===============================================================================*/
    static GetInstance()
    {
        if(UIDataManager.m_uiDataManager == null) 
        {
            UIDataManager.m_uiDataManager = new UIDataManager(UIDataManager.m_objInit);
            UIDataManager.m_objInit = {};
        }
        return UIDataManager.m_uiDataManager;
    }
    
    /**===============================================================================
    * dispose()         : 
    *===============================================================================*/
    static dispose(){
        UIDataManager.m_arrDatas = null;
        UIDataManager.m_objInit = null;
        UIDataManager.m_arrChatData = [];
        UIDataManager.m_isChatAllTyp = false;
        UIDataManager.m_uiDataManager = null;
        UIDataManager.m_objLoginInputData = {};
        UIDataManager.m_objDetailArtData = {};
        UIDataManager.m_objPivotData = {};
    }
    
    /**===============================================================================
    * SetDatas()     : 전체 데이터 세터
    * @param _arrData   : 전체 데이터
    *===============================================================================*/
    static SetDatas(_arrData){

        console.log("SetDatas:::",_arrData)
        UIDataManager.m_arrDatas = _arrData;
    }

    
    /**===============================================================================
    * GetDatas()    : 전체 데이터 게터
    * @return       : 전체 데이터 리턴
    *===============================================================================*/
    static GetDatas(){
        return UIDataManager.m_arrDatas;
    }

    /**===============================================================================
    * SetChatAlltypeData()     : 채팅 룸 타입 세터
    * @param _isData   : 전체 타입 boolean 데이터
    *===============================================================================*/
    static SetChatAlltypeData(_isData){
        UIDataManager.m_isChatAllTyp = _isData;
    }

    /**===============================================================================
    * GetLandMarkDatas()    : 채팅 룸 타입 게터
    * @return       : 채팅 룸이 전체 타입인지 Boolean 리턴
    *===============================================================================*/
    static GetChatAlltypeData(){
        return UIDataManager.m_isChatAllTyp;
    }

    /**===============================================================================
    * SetChatData()     : 채팅데이터 세터
    * @param _objData   : 채팅 데이터
    *===============================================================================*/
    static SetChatData(_objData){
        console.log("SetChatData:::", _objData)
        UIDataManager.m_arrChatData = [...UIDataManager.m_arrChatData, _objData];
    }

    /**===============================================================================
    * GetChatDatas()    : 채팅데이터 게터
    * @return   : 채팅데이터 리턴
    *===============================================================================*/
    static GetChatDatas(_strType){
        let returnData=[];
        console.log("GetChatDatas!!");
        
        let strRoomType=""
        if(UIDataManager.m_isChatAllTyp) strRoomType = "Room_Type_Lobby"
        else strRoomType = "Room_Type_Normal"

        UIDataManager.m_arrChatData.map((data,i)=>{
            if(data.roomType === strRoomType){
                returnData.push(data);
            }
        })

        console.log("returnData:",returnData)
        
        return returnData;
    }


    /**===============================================================================
    * GetLoginInputData()    : 로그인 입력정보 게터
    * @return       : 입력정보 리턴
    *===============================================================================*/
    static GetLoginInputData(){
        return UIDataManager.m_objLoginInputData;
    }

    /**===============================================================================
    * SetLoginInputData()     : 로그인 입력정보 세터
    * @param _objData   : 입력정보 데이터
    *===============================================================================*/
    static SetLoginInputData(_objData = null){
        // console.log("SetLoginInputData:::", typeof(_objData))
        if(!_objData){
            console.log("초기화");
            UIDataManager.m_objLoginInputData={};
        }else{
            UIDataManager.m_objLoginInputData = {
                ...UIDataManager.m_objLoginInputData,
                [_objData.name]: _objData.value
            }
        }
    }

    /**===============================================================================
    * GetDetailArtData()    : 상세보기 모달 표출정보 게터
    * @return       : 입력정보 리턴
    *===============================================================================*/
     static GetDetailArtData(){
        return UIDataManager.m_objDetailArtData;
    }

    /**===============================================================================
    * SetDetailArtData()     : 상세보기 모달 표출정보 세터
    * @param _objData   : Webgl 선택 Art 정보 데이터
    *===============================================================================*/
    static SetDetailArtData(_objData = null){
        console.log("SetDetailArtData:::", _objData)
        if(!_objData){
            console.log("초기화");
            UIDataManager.m_objDetailArtData={};
        }else{
            UIDataManager.m_objDetailArtData = _objData;
        }
    }

    /**===============================================================================
    * GetPivotData()    : 편집모드 선택 피봇 정보 게터
    * @return       : 입력정보 리턴
    *===============================================================================*/
    static GetPivotData(){
        return UIDataManager.m_objPivotData;
    }

    /**===============================================================================
    * SetPivottData()     : 편집모드 선택 피봇 정보 세터
    * @param _objData   : Webgl 선택 Pivot 정보 데이터
    * m_objPivotData{ }
    * 
    * 
    * 
    *===============================================================================*/
    static SetPivotData(_objData = null,isSubChange=false){
        console.log("SetPivotData:::", _objData)
        if(!_objData){
            console.log("초기화");
            UIDataManager.m_objPivotData={};
        }else{
            if(isSubChange){
                UIDataManager.m_objPivotData = {
                    ...UIDataManager.m_objPivotData,
                    [_objData.name]: _objData.value
                }
            }else{
                UIDataManager.m_objPivotData={};
                UIDataManager.m_objPivotData = _objData;
            }
        }
    }



    /**===============================================================================
    * GetFilterArtWorkListData()    : 편집모드 검색 된 데이터 정보 게터
    * @return       : 입력정보 리턴
    *===============================================================================*/
     static GetFilterArtWorkListData(){
        return UIDataManager.m_arrArtWorkListData;
    }

    /**===============================================================================
    * SetFilterArtWorkListData()     : 편집모드 검색 된 데이터 정보 세터
    * @param _objData   : Webgl 선택 Pivot 정보 데이터
    * m_objPivotData{ }
    *===============================================================================*/
    static SetFilterArtWorkListData(_arrArtWorkLists){
        console.log("SetFilterArtWorkListData:::", _arrArtWorkLists)
        
        UIDataManager.m_arrArtWorkListData = _arrArtWorkLists;
    }




};

