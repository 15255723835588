import React from "react";
import { Block, ContainerBox, FlexArea, TextButton } from "../commonLib/StyleMasterComp";
import styled from "styled-components";
import { useNavigate } from "react-router-dom";

let SpacialTextButton=styled(TextButton)`
    background: radial-gradient(50% 50% at 50% 50%, #D9D9D9 0%, rgba(217, 217, 217, 0) 100%);
`;

function PageTest(props){
    const navigate = useNavigate();

    function move()
    {
        alert('클릭');
        navigate('/');
    }

    return(
        <Block>
            <FlexArea>
                <Block Width={props.getSizeReturn("W",20)} Height="100%" BackgroundColor="yellow"></Block>
                <Block Width="80%" Height="100%" BackgroundColor="green">
                    <FlexArea FlexDirection="column">
                        <Block Height="10%" BackgroundColor="magenta">커스터마이징</Block>
                        <Block Height="90%" BackgroundColor="gray">
                            <FlexArea AlignItems="flex-start">
                                <Block Width="94%" Height="98%" BackgroundColor="green" >
                                    <Block Height="90%" BackgroundColor="green">
                                        <FlexArea>
                                            <Block Width="50%" BackgroundColor="red"></Block>
                                            <Block Width="50%" BackgroundColor="green"></Block>
                                        </FlexArea>
                                    </Block>
                                    <Block Height="10%" BackgroundColor="blue">
                                        <FlexArea JustifyContent="space-around">
                                            {/* <TextButton FontColor="white" Width="168px" Height="50px" BorderRadius="25px" BackgroundColor="#684DF1">
                                                저장
                                            </TextButton>
                                            <TextButton FontColor="white" Width="168px" Height="50px" BorderRadius="25px" BackgroundColor="#684DF1">
                                                저장1
                                            </TextButton>
                                            <TextButton FontColor="white" Width="168px" Height="50px" BorderRadius="25px" BackgroundColor="#684DF1">
                                                저장2
                                            </TextButton> */}
                                        </FlexArea>
                                    </Block>
                                </Block>
                            </FlexArea>
                        </Block>
                    </FlexArea>
                </Block>
            </FlexArea>
        </Block>
    )
}

export default PageTest;