/**
@Class          : PageSignup.js
@Maker          : Minnie
@Date           : 2022-08-08
@Version        : 1.0
@Description    : 회원가입 페이지
 */
import React, {useState} from "react";
import { Block, BlockLabel, ContainerBox, FlexArea, ImgBox, ImgButton, TextBox, TextButton, TextInput } from "../commonLib/StyleMasterComp";
import { TextInputS, DefaultButtonS } from "../commonLib/ServiceStyleMaster";
import styled from "styled-components";
import { useNavigate } from "react-router-dom";

import Utils_ui from "../commonProj/Utils_ui";
import IcoPWViewOff from "../images/pageSignup/ico_eye1.png"
import IcoPWViewOn from "../images/pageSignup/ico_eye2.png"
import IcoPWCheck from "../images/pageSignup/ico_check.png"
import IcoCheck from "../images/pageSignup/ico_checkbox.png"
import { NSFetch, _API_URL } from "../NSCommon/middleware/NSCommunication";

let BoldSpan=styled.span`
    font-weight: 500;
`;

function PageSignup(props){
    const navigate = useNavigate();

    const [isPWViewMode, setIsPWViewMode] = useState(false);                // 비밀번호 보기
    const [isConfirmPWViewMode, setIsConfirmPWViewMode] = useState(true);   // 비밀번호 확인
    const [isAgreeCheckbox,setIsAgreeCheckbox] = useState(false);           // 약관 동의 체크박스
    const [isActiveRequestBtn,setIsActiveRequestBtn] = useState(false);     // 가입신청 버튼 활성화

    const [strErrorText,setStrErrorText] = useState("이메일을 입력하지 않았습니다."); // 오류 문구
    const [iSecurityState,setISecurityState] = useState(0);                 // 보안 단계

    const [ email, setEmail ] = useState(null);
    const [ pw, setPW ] = useState(null);
    const [ pw2, setPW2 ] = useState(null);

    const securityArr=[
        { state: "NONE", text: "패스워드 사용 불가", color:"#F34336" },
        { state: "LOW", text:"보안 단계 낮음", color:"#F34336" },
        { state: "HIGH", text:"보안 단계 높음", color:"#00C64F" }
    ]

    let fAgreeCheckbox = isAgreeCheckbox;

    function checkEmail()
    {
        return (email || "").match(
            /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
          );
    }
    
    // 보안등급 확인
    function checkPW(pw)
    {
        setISecurityState(0);
        var passwd = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{6,20}$/;
        if(pw.match(passwd)) 
            setISecurityState(1);
        passwd = /^(?=.*[0-9])(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]{7,15}$/;
        if(pw.match(passwd)) 
            setISecurityState(2);
    }

    function emailChangeHandler(e)
    {
        var _em = e.target.value;
        setEmail( _em );

        validateAll(_em, pw, pw2 );
    }

    function pw1ChangeHandler(e)
    {
        var _pw = e.target.value;
        setPW( _pw );
        checkPW( _pw ); // 보안등급 확인

        validateAll(email, _pw, pw2);
    }

    function pw2ChangeHandler(e)
    {
        var _pw = e.target.value;
        setPW2( _pw );

        validateAll(email, pw, _pw);
    }

    function validateAll(_email, _pw1, _pw2)
    {
        setIsActiveRequestBtn( false );

        console.log(_pw1, _pw2, isAgreeCheckbox, fAgreeCheckbox );

        // 이메일 체크
        if ( !checkEmail(_email) )
        {
            setStrErrorText("올바른 이메일을 입력해 주세요");
            return;
        }

        // 패스워드 입력 체크
        if ( _pw1==null || (_pw1!=null && (_pw1.length < 8)) )
        {
            setStrErrorText("패스워드는 8자 이상 입력해 주세요");
            return;
        }

        // 패스워드 일치 체크
        if ( _pw1!=_pw2 )
        {
            setStrErrorText("패스워드가 일치하지 않습니다");
            return;
        }

        // 약관 체크
        if ( !fAgreeCheckbox )
        {
            setStrErrorText("약관에 동의해 주세요");
            return;
        }

        setStrErrorText("");

        setIsActiveRequestBtn( true );
    }

    /**
     * 회원 가입 폼 전송
     */
    function submit()
    {
        const _url = `${_API_URL}auth/signup`;
        const data = {
                userId: email,
                userPw: pw
            }

        function okFunc(res)
        {
            alert('회원 가입에 성공했습니다.');
            // 이메일을 발송했습니다. 확인하세요.
            window.location.href=res.data.url;
        }

        function errFunc(res, code)
        {
            alert('회원 가입에 실패했습니다.')
        }

        NSFetch(_url, 'POST', data, okFunc, errFunc)
    }

    // function move()
    // {
    //     alert('클릭');
    //     navigate('/');
    // }

    return(
        <ContainerBox>
            <FlexArea>
                {/* <Block Width={Utils_ui.GetClampSize(440)} Height="100%" BackgroundColor="none"> */}
                <Block Width="440px" MinWidth="440px">
                    {/* <FlexArea FlexDirection="column" JustifyContent="start"> */}
                        {/* 타이틀 */}
                        <Block MarginTop="55px">
                            {/* <FlexArea FlexDirection="column"> */}
                                {/* <TextBox Width="none" Height="40%" FontSize={Utils_ui.GetClampSize(52)} FontWeight="400"><BoldSpan>가입</BoldSpan>하기</TextBox>  */}
                                <TextBox Height="65px" FontSize="52px" LineHeight="65px" FontWeight="400" TextAlign="center">
                                    <BoldSpan>가입</BoldSpan>하기
                                </TextBox>
                                {/* <TextBox Width="none" Height="10%" FontSize={Utils_ui.GetClampSize(14)} FontColor="#F34336" BackgroundColor="none">{strErrorText}</TextBox>     */}
                            {/* </FlexArea> */}
                        </Block>

                        <Block MarginTop="8px">
                            <TextBox Height="21px" FontSize="14px" FontColor="#F34336" TextAlign="center">
                                {/* <FlexArea> */}
                                {strErrorText}
                                {/* </FlexArea> */}
                            </TextBox>
                        </Block>

                        {/* 텍스트 인풋 */}
                        {/* <form style={{}} onSubmit={handleSubmit} noValidate> */}
                        <Block Height="auto">
                            {/* <FlexArea FlexDirection="column" JustifyContent="start" BackgroundColor="none"> */}
                                {/* 이메일 */}
                                {/* <BlockLabel PointerEvents="all" Width="100%" Height={Utils_ui.GetClampSize(55)} Border="1px solid #C2C2C2" BorderRadius={Utils_ui.GetClampSize(10)} BackgroundColor="#EDEDED">
                                    <FlexArea>
                                        <TextInput Width="96%" Height="90%" BackgroundColor="#EDEDED"
                                        FontSize={Utils_ui.GetClampSize(16)} PFontSize={Utils_ui.GetClampSize(16)}
                                        placeholder="이메일 주소를 입력하세요." PFontColor="#666666" onChange={emailChangeHandler} value={email}>
                                        </TextInput>
                                    </FlexArea>
                                </BlockLabel> */}
                                <FlexArea Position="relative" MarginTop="32px">
                                    <TextInputS
                                        autoComplete="off"
                                        placeholder="이메일 주소를 입력하세요."
                                        maxLength={256}
                                        type="email" name="email"
                                        onChange={emailChangeHandler}
                                        // value={objValues.email || ""}
                                        value={email || ""}
                                        required
                                    />
                                </FlexArea>

                                {/* 비밀번호 */}
                                {/* <BlockLabel PointerEvents="all" Width="100%" Height={Utils_ui.GetClampSize(55)} Border="1px solid #C2C2C2" BorderRadius={Utils_ui.GetClampSize(10)} BackgroundColor="#EDEDED"
                                MarginTop="2%">
                                    <FlexArea>
                                        <TextInput Width="90%" Height="90%" BackgroundColor="#EDEDED"
                                        FontSize={Utils_ui.GetClampSize(16)} PFontSize={Utils_ui.GetClampSize(16)}
                                        placeholder="비밀번호를 입력 하세요." PFontColor="#666666" onChange={pw1ChangeHandler} value={pw}>
                                        </TextInput>
                                        
                                        <Block Width="6%">
                                            <ImgButton BackgroundSize="contain" BackgroundColor="none"
                                            Width={Utils_ui.GetClampSize(20)}
                                            BackgroundImage={isPWViewMode?IcoPWViewOn:IcoPWViewOff}
                                            onClick={()=>{
                                                setIsPWViewMode(!isPWViewMode);
                                            }}></ImgButton>
                                        </Block>
                                    </FlexArea>
                                </BlockLabel> */}
                                <FlexArea Position="relative" MarginTop="10px">
                                    <TextInputS
                                        autoComplete="new-password"
                                        placeholder="비밀번호를 입력하세요."
                                        maxLength={20}
                                        type={isPWViewMode?"text":"password"} name="password"
                                        onChange={pw1ChangeHandler}
                                        // value={objValues.password || ""}
                                        value={pw || ""}
                                        required
                                    />
                                    {/* 비밀번호 보기 버튼 */}
                                    <ImgButton Position="absolute" 
                                        Width="16px" Height="16px"
                                        Top="22px" Right="16px"
                                        BackgroundImage={isPWViewMode?IcoPWViewOff:IcoPWViewOn}
                                        type="button"
                                        onClick={()=>{
                                            setIsPWViewMode(!isPWViewMode);
                                        }}
                                    />
                                </FlexArea>

                                {/* 보안 단계 텍스트 */}
                                {/* <Block Height="auto"> */}
                                    <FlexArea JustifyContent="end" MarginTop="5px">
                                        <TextBox Width="none" Height="none" PaddingRight="16px" FontSize="12px" FontColor={securityArr[iSecurityState].color} BackgroundColor="none">{securityArr[iSecurityState].text}</TextBox>
                                    </FlexArea>
                                {/* </Block> */}
                                
                                {/* 비밀번호 확인 */}
                                {/* <BlockLabel PointerEvents="all"  Width="100%" Height={Utils_ui.GetClampSize(55)} Border="1px solid #C2C2C2" BorderRadius={Utils_ui.GetClampSize(10)} BackgroundColor="#EDEDED"
                                MarginTop="2%">
                                    <FlexArea>
                                    <TextInput Width="90%" Height="90%" BackgroundColor="#EDEDED"
                                        FontSize={Utils_ui.GetClampSize(16)} PFontSize={Utils_ui.GetClampSize(16)}
                                        placeholder="비밀번호 확인." PFontColor="#666666" onChange={pw2ChangeHandler} value={pw2}>
                                        </TextInput>
                                        
                                        <Block Width="6%">
                                            <ImgBox BackgroundSize="contain" BackgroundColor="none"
                                            Width={Utils_ui.GetClampSize(20)}
                                            BackgroundImage={isConfirmPWViewMode?IcoPWCheck:null}
                                            ></ImgBox>
                                        </Block>
                                    </FlexArea>
                                </BlockLabel> */}
                                <FlexArea Position="relative" MarginTop="5px">
                                    <TextInputS
                                        autoComplete="new-password"
                                        placeholder="비밀번호 확인"
                                        maxLength={20}
                                        type={isPWViewMode?"text":"password"} name="password"
                                        onChange={pw2ChangeHandler}
                                        // value={objValues.password || ""}
                                        value={pw2 || ""}
                                        required
                                    />
                                    {/* 비밀번호 확인 아이콘 */}
                                    <ImgButton Position="absolute" 
                                        Width="16px" Height="16px"
                                        Top="22px" Right="16px"
                                        BackgroundImage={isConfirmPWViewMode?IcoPWCheck:null}
                                        type="button"
                                    />
                                </FlexArea>
                            {/* </FlexArea> */}
                        </Block>

                        {/* 약관 확인 */}
                        {/* <Block> */}
                            <FlexArea FlexDirection="column" MarginTop="14px">
                                {/* 약관 체크박스 */}
                                {/* <Block>
                                    <BlockLabel PointerEvents="all">
                                        <FlexArea JustifyContent="center" BackgroundColor="none">
                                            <Block Width="6%" MarginRight="1%" BackgroundColor="none">
                                                <FlexArea Height="none" JustifyContent="end">
                                                    <ImgButton Width={Utils_ui.GetClampSize(18)} Height={Utils_ui.GetClampSize(18)} 
                                                    Border="1px solid #C2C2C2" BorderRadius={Utils_ui.GetClampSize(4)} BackgroundColor="#DFDFDF"
                                                    BackgroundSize={Utils_ui.GetClampSize(8)} BackgroundImage={isAgreeCheckbox?IcoCheck:null}
                                                    onClick={()=>{
                                                        setIsAgreeCheckbox(!isAgreeCheckbox);
                                                    }}></ImgButton>
                                                </FlexArea>
                                            </Block>
                                            <TextBox Width="68%" MarginRight="7%" TextAlign="center"
                                                FontSize="14px" FontColor="#666666" LineHeight="18px">
                                                갤러리 2.0의 이용약관 및 개인정보 취급방침에 대한 내용을 확인하였으며 이에 동의합니다.
                                            </TextBox>
                                        </FlexArea>
                                    </BlockLabel>
                                </Block> */}
                                <FlexArea>
                                    <Block Padding="0 56px">
                                        <FlexArea AlignItems="start" JustifyContent="center">
                                            <ImgButton Width="18px" Height="18px"
                                                Border="1px solid #C2C2C2" BorderRadius="4px" BackgroundColor="#DFDFDF"
                                                BackgroundSize="10px 8px" BackgroundPositon="center" BackgroundImage={isAgreeCheckbox ? IcoCheck : null}
                                                onClick={() =>
                                                {
                                                    fAgreeCheckbox = !fAgreeCheckbox;
                                                    setIsAgreeCheckbox(fAgreeCheckbox);
                                                    validateAll(email, pw, pw2);
                                                }}></ImgButton>
                                            <TextBox TextAlign="center" FontSize="14px" FontColor="#666666" LineHeight="18px">
                                                갤러리 2.0의 이용약관 및 개인정보 취급방침에 대한 내용을 확인하였으며 이에 동의합니다.
                                            </TextBox>
                                        </FlexArea>
                                    </Block>
                                </FlexArea>
                                {/* 약관 상세 보기 버튼 */}
                                {/* <TextButton Width={Utils_ui.GetClampSize(145)} Height={Utils_ui.GetClampSize(41)} 
                                FontSize={Utils_ui.GetClampSize(14)} FontColor="#333333"
                                MarginTop="2%"
                                Border="1px solid #999999" BorderRadius={Utils_ui.GetClampSize(20)} BackgroundColor="#FFFFFF">약관 상세 보기 &gt;</TextButton> */}
                                <TextButton Width="145px" Height="41px" LineHeight="18px" MarginTop="17px"
                                    FontSize="14px" FontColor="#333333" Border="1px solid #999999" BorderRadius="20px">
                                    약관 상세 보기 &gt;</TextButton>
                            </FlexArea>
                        {/* </Block> */}

                        {/* 가입신청 버튼 */}
                        {/* <Block >
                            <FlexArea>
                                <TextButton Height={Utils_ui.GetClampSize(72)} BorderRadius={Utils_ui.GetClampSize(10)} 
                                PointerEvents={isActiveRequestBtn?"all":"none"}
                                BackgroundColor={isActiveRequestBtn?"#684DF1":"#DEDEDE"}
                                FontSize={Utils_ui.GetClampSize(24)} FontColor="#FFFFFF" onClick={submit}>가입신청</TextButton>
                            </FlexArea>
                        </Block> */}
                        <FlexArea>
                            <DefaultButtonS MarginTop="60px"
                                PointerEvents={isActiveRequestBtn? "all" : "none"}
                                BackgroundColor={isActiveRequestBtn? "#684DF1":"#DEDEDE"}
                                onClick={submit}
                            >
                                가입신청
                            </DefaultButtonS>
                        </FlexArea>

                        
                        {/* 하단 텍스트 */}
                        {/* <Block Height="12%" BackgroundColor="none">
                        </Block>
                         */}
                        <FlexArea FlexDirection="column" MarginTop="67px">
                            <TextBox Height="38px" LineHeight="38px" TextAlign="center" FontSize="24px" FontFamily="Roboto" FontWeight="800"><span style={{ fontSize: "32px" }}>1</span> / 3</TextBox>
                            <TextBox Height="18px" LineHeight="18px" FontSize="14px" FontColor="#333333" TextAlign="center" MarginTop="6px">몇 단계만 거치면 회원가입이 완료됩니다.</TextBox>
                        </FlexArea>
                    {/* </FlexArea> */}
                </Block>
            </FlexArea>
        </ContainerBox>
    )
}

export default PageSignup;