/**
@Class          : PageFindID.js
@Date           : 2022-08-14
@Version        : 1.0
@Description    : 아이디 찾기 페이지
 */
import React, { useState } from "react";
import { Block, BlockLabel, ContainerBox, FlexArea, ImgBox, ImgButton, TextBox, TextButton, TextInput } from "../commonLib/StyleMasterComp";
import { TextInputS, DefaultButtonS } from "../commonLib/ServiceStyleMaster";
import styled from "styled-components";
import { useNavigate } from "react-router-dom";

// import Utils_ui from "../commonProj/Utils_ui";
// import IcoPWViewOff from "../images/pageSignup/ico_eye1.png"
// import IcoPWViewOn from "../images/pageSignup/ico_eye2.png"
// import IcoPWCheck from "../images/pageSignup/ico_check.png"
// import IcoCheck from "../images/pageSignup/ico_checkbox.png"
import { NSFetch, _API_URL } from "../NSCommon/middleware/NSCommunication";

let BoldSpan = styled.span`
    font-weight: 500;
`;

function PageFindID(props)
{
    // const navigate = useNavigate();

    const [isActiveRequestBtn, setIsActiveRequestBtn] = useState(false);     // 확인 버튼 활성화
    const [strErrorText, setStrErrorText] = useState("이메일을 입력하지 않았습니다."); // 오류 문구
    const [email, setEmail] = useState(null);

    function checkEmail()
    {
        return (email || "").match(
            /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        );
    }

    function emailChangeHandler(e)
    {
        var _em = e.target.value;
        setEmail(_em);

        validateAll(_em);
    }

    function validateAll(_email)
    {
        setIsActiveRequestBtn(false);

        if (!checkEmail(_email))
        {
            setStrErrorText("올바른 이메일을 입력해 주세요");
            return;
        }

        setStrErrorText("");

        setIsActiveRequestBtn(true);
    }

    /**
     * 아이디 찾기 폼 전송
     */
    function submit()
    {
        const _url = `${_API_URL}auth/userId/find`;
        const data = {
            userId: email
        }

        function okFunc(res)
        {
            alert(email +'로 가입한 회원 정보가 있습니다.');
            // window.location.href = res.data.url;
        }

        function errFunc(res, code)
        {
            alert(email+'로 가입한 회원 정보가 없습니다.\n다른 이메일을 입력해주세요.')
        }

        NSFetch(_url, 'POST', data, okFunc, errFunc)
    }

    return (
        <ContainerBox>
            <FlexArea>
                <Block Width="440px" MinWidth="440px">
                    {/* 타이틀 */}
                    <Block MarginTop="55px">
                        <TextBox Height="65px" FontSize="52px" LineHeight="65px" FontWeight="400" TextAlign="center">
                            아이디 <BoldSpan>찾기</BoldSpan>
                        </TextBox>
                    </Block>

                    {/* 안내 문구 */}
                    <Block MarginTop="8px">
                        <TextBox Height="40px" FontSize="14px" FontColor="#333333" TextAlign="center">
                            입력한 이메일 주소로 가입한<br />
                            회원 기록을 확인해 드립니다.
                        </TextBox>
                        {/* <TextBox Height="21px" FontSize="14px" FontColor="#F34336" TextAlign="center">
                            {strErrorText}
                        </TextBox> */}
                    </Block>

                    {/* 이메일 */}
                    <FlexArea Position="relative" MarginTop="32px">
                        <TextInputS
                            autoComplete="off"
                            placeholder="이메일 주소를 입력하세요."
                            maxLength={20}
                            type="email" name="email"
                            onChange={emailChangeHandler}
                            value={email || ""}
                            required
                        />
                    </FlexArea>
                    
                     {/* 문의 */}
                    <FlexArea MarginTop="12px">
                        <TextButton Width="auto" Height="18px" FontSize="14px" LineHeight="18px" FontColor="#333333" FontWeight="500">
                            메일이 오지 않았나요?
                        </TextButton>
                    </FlexArea>

                    {/* 확인 버튼 */}
                    <FlexArea>
                        <DefaultButtonS MarginTop="60px"
                            PointerEvents={isActiveRequestBtn ? "all" : "none"}
                            BackgroundColor={isActiveRequestBtn ? "#684DF1" : "#DEDEDE"}
                            onClick={submit}
                        >
                            확인
                        </DefaultButtonS>
                    </FlexArea>

                </Block>
            </FlexArea>
        </ContainerBox>
    )
}

export default PageFindID;