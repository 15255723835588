import { useNavigate } from "react-router-dom";
import { Block, FlexArea, ModalContainerBox, ModalBlackBox, ImgBox, TextBox, TextButton, ImgButton } from "../commonLib/StyleMasterComp";
import { DefaultButtonS } from "../commonLib/ServiceStyleMaster";
import styled from "styled-components";

import BgImage from "../images/pageService/carousel/bgImage.png";
import IcoCloseModal from "../images/pageService/modal/ico_closeModal.png";
import ImgWelcome from "../images/pageService/modal/img_welcome.png";

let ContentOutBlock = styled(Block)`
    box-shadow: 1px 4px 15px 1px rgba(0, 0, 0, 0.25);
`;

let FlexAreaBB = styled(FlexArea)`
  box-sizing: border-box;
`;

function onClickClose(){
  // navigate('/');
  window.location.href = process.env.REACT_APP_SERVICE_PAGE_URL_BASE;
}

function onClickOK(e, url)
{
  // navigate('/');
  if( url && url.length > 0)
    window.location.href = `${process.env.REACT_APP_BASE_GALLERY_URL}${url}`;
  else
    window.location.href = process.env.REACT_APP_SERVICE_PAGE_URL_BASE;
}

function ModalWelcome(props){ 
  const navigate = useNavigate();

  return (
    <ModalContainerBox>
      {/* <ImgBox Position="absolute" Height="100%" Width="100%" BackgroundImage={BgImage}></ImgBox> */}
      <ModalBlackBox Opacity=".5" />
      <Block Width={props.getSizeReturn("W", 100)} Height={props.getSizeReturn("H", 100)}
        Position="relative">
        <FlexArea>
          {/* Height="472px" */}
          <ContentOutBlock Position="relative" Width="374px" Height="auto" BackgroundColor="#FFFFFF" BorderRadius="11px">
            <ImgButton Position="absolute" Width="24px" Height="24px" Right="20px" Top="20px" BackgroundImage={IcoCloseModal}
              onClick={onClickClose}
            ></ImgButton>
            <FlexAreaBB FlexDirection="column" Padding="39px 30px 32px 30px">
              <TextBox Width="auto" Height="30px" LineHeight="30px" FontSize="24px" FontWeight="500" FontColor="#333333">
                {props.Title? props.Title:"회원가입 완료"}
              </TextBox>
              <ImgBox Width="128px" Height="129px" MarginTop="28px" BackgroundImage={ImgWelcome}></ImgBox>
              <TextBox Width="auto" Height="40px" LineHeight="40px" MarginTop="36px"
                FontSize="24px" FontWeight="700"
                FontColor={props.FontColor? props.FontColor:"#684DF1"}
              >환영합니다.</TextBox>
              <TextBox Width="auto" Height="51px"
                TextAlign="center" LineHeight="26.75px" FontSize="16px" >
                {props.Text ? props.Text : "넷스트림 회원 가입이 완료되었습니다.\n당신의 메타버스를 보여주세요!"}
              </TextBox>
              <DefaultButtonS Width="260px" Height="60px" MarginTop="27px"
                FontSize="20px" LineHeight="25px"
                BackgroundColor={props.ButtonColor ? props.ButtonColor : null}
                onClick={(e) => { onClickOK(e, props.URL) }}
              >{props.ButtonText ? props.ButtonText : "확인"}
              </DefaultButtonS>
            </FlexAreaBB>
          </ContentOutBlock>
        </FlexArea>
      </Block>
      </ModalContainerBox>
  )
}

export default ModalWelcome;