export default class Constants
{
    'use strict';
    static DEBUG_MODE = false;
    static BASE_URL = "http://localhost";
    static MULTI_SERVER_URL = {
        url: 'ws://localhost',
        port: '4000'
    };
    
    static ASSETS_URL = "./assets/";
    static UI_DATA_URL = "./json/ui_datas.json";

    static OS_ECT = "Os_Ect";
    static OS_IOS = "Os_Ios";
    static OS_ANDROID = "Os_Android";
    static OS_PLATFORM = null;
    static SCREEN_PORTRAIT = "Screen_Portrait";
    static SCREEN_LANDSCAPE = "Screen_Landscape";
    static SCREEN_MODE = Constants.SCREEN_PORTRAIT;

    
}