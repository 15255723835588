import styled from "styled-components";

export const AppContainerBox = styled.div`
  width: ${props => props.Width || '100vw'};
  height: ${props => ((props.Height))};
  background-color: ${props => props.BackgroundColor || null};
  pointer-events: ${props => props.PointerEvents || "none"};
  position: fixed;
`;

export const ContainerBox = styled.div`
  position: relative;
  background-color: ${props => props.BackgroundColor || null};
  pointer-events:none;
  margin: 0px;
  padding: 0px;
`;

export const CommonDiv = styled.div`
  display: ${props => props.Display || null};
  position: ${props => props.Position || null};
  width: ${props => props.Width || '100%'};
  min-width: ${props => props.MinWidth || null};
  max-width: ${props => props.MaxWidth || null};
  height:${props => props.Height || '100%'};
  min-height: ${props => props.MinHeight || null};
  max-height: ${props => props.MaxHeight || null};
  background-color: ${props => props.BackgroundColor || null};
  background: ${props => props.Background || null};
  left: ${props => props.Left || null};
  top: ${props => props.Top || null};
  right: ${props => props.Right || null};
  bottom: ${props => props.Bottom || null};
  margin: ${props => props.Margin || null};
  margin-top: ${props => props.MarginTop || null};
  margin-right: ${props => props.MarginRight || null};
  margin-bottom: ${props => props.MarginBottom || null};
  margin-left: ${props => props.MarginLeft || null};
  padding: ${props => props.Padding || null};
  padding-top: ${props => props.PaddingTop || null};
  padding-right: ${props => props.PaddingRight || null};
  padding-bottom: ${props => props.PaddingBottom || null};
  padding-left: ${props => props.PaddingLeft || null};
  z-index: ${props => props.ZIndex || null};
  opacity: ${props => props.Opacity || null};
  border: ${props => props.Border || null};
  border-radius: ${props => props.BorderRadius || null};
  pointer-events: ${props => props.PointerEvents || "none"};
  font-family: ${props => props.FontFamily || 'Spoqa HanSansNeo'};
  transform: ${props => props.Transform || null};
  -webkit-text-size-adjust: none;
`;

export const Block = styled(CommonDiv)`
`;

export const OneItemBlock = styled(Block)`
    border-top: 1px solid #E3E3E3;
`;

export const FlexArea = styled(CommonDiv)`
  display: flex;
  justify-content: ${props => props.JustifyContent||"center"};
  -webkit-justify-content: ${props => props.JustifyContent||"center"};
  align-items: ${props => props.AlignItems||"center"};
  -webkit-align-items: ${props => props.AlignItems||"center"};
  flex-direction: ${props => props.FlexDirection || null};
  flex-wrap: ${props => props.FlexWrap || null};
`;

export const TextBox = styled(CommonDiv)`
  color: ${props => props.FontColor || "#000"}; 
  font-size: ${props => props.FontSize || "16px"}; 
  font-weight: ${props => props.FontWeight || null};
  word-spacing: ${props => props.WordSpacing || null};
  letter-spacing: ${props => props.LetterSpacing || null};
  text-align: ${props => props.TextAlign || null};
  line-height: ${props => props.LineHeight || null};
  text-decoration: ${props => props.TextDecoration || null}; 
  white-space: pre-line;
`;

export const ImgBox = styled(CommonDiv)`
  background-image: url(${props => props.BackgroundImage});
  background-size: ${props => props.BackgroundSize || "cover"};
  background-repeat: ${props => props.BackgroundRepeat || "no-repeat"};
  background-position: ${props => props.BackgroundPosition || "center center"};
`;

// 스크롤 컨테이너 기본형
export const ScrollContainerBlock = styled(Block)`
    pointer-events:all;
    overflow-x: hidden;
    overflow-y: overlay;
    ::-webkit-scrollbar {
        width: ${props => props.SWidth || "10px"};
    }
    ::-webkit-scrollbar-track {
        background: ${props => props.STrackBackgroundColor || "white"};
        /* border-left: 4px solid white; */
        /* border-right: 4px solid white; */
    }
    ::-webkit-scrollbar-thumb {
        width: ${props => props.SThumbWidth || "10px"};
        background: ${props => props.SThumbBackgroundColor || "#666666"};
        /* border-radius: 10px;     */
    }

    ::-webkit-scrollbar-thumb:hover{
      background: ${props => props.SThumbHBackgroundColor || "#777777"};
    }
`;

export const CommonButton = styled.button`
  position: ${props => props.Position || null};
  width: ${props => props.Width || '100%'};
  min-width: ${props => props.MinWidth || null};
  max-width: ${props => props.MaxWidth || null};
  height:${props => props.Height || '100%'};
  min-height: ${props => props.MinHeight || null};
  max-height: ${props => props.MaxHeight || null};
  position: ${props => props.Position || null};
  display: ${props => props.Display || null};
  background: ${props => props.Background || "none"};;
  background-color: ${props => props.BackgroundColor || null};
  font-family: ${props => props.FontFamily || 'Spoqa HanSansNeo'};
  border: ${props => props.Border || "none"};
  border-radius: ${props => props.BorderRadius || null};
  pointer-events: ${props => props.PointerEvents || "all"};
  cursor: pointer;
  left: ${props => props.Left || null};
  top: ${props => props.Top || null};
  right: ${props => props.Right || null};
  bottom: ${props => props.Bottom || null};
  opacity: ${props => props.Opacity || null};
  margin: ${props => props.Margin || null};
  margin-top: ${props => props.MarginTop || null};
  margin-right: ${props => props.MarginRight || null};
  margin-bottom: ${props => props.MarginBottom || null};
  margin-left: ${props => props.MarginLeft || null};
  padding: ${props => props.Padding || null};
  padding-top: ${props => props.PaddingTop || null};
  padding-right: ${props => props.PaddingRight || null};
  padding-bottom: ${props => props.PaddingBottom || null};
  padding-left: ${props => props.PaddingLeft || null};
  -webkit-text-size-adjust: none;
`;

export const TextButton = styled(CommonButton)`
  color: ${props => props.FontColor || "#000"}; 
  font-size: ${props => props.FontSize || "16px"}; 
  font-weight: ${props => props.FontWeight || null};
  word-spacing: ${props => props.WordSpacing || null};
  letter-spacing: ${props => props.LetterSpacing || null};
  text-align: ${props => props.TextAlign || null};
  line-height: ${props => props.LineHeight || null};
  text-decoration: ${props => props.TextDecoration || null}; 
  white-space: pre-line;
`;

export const TextHoverButton = styled(TextButton)`
  :hover{
    background-color: ${props => props.HBackgroundColor || null};
    border: ${props => props.HBorder || null};
  }
`;

export const ImgButton = styled(CommonButton)`
  background-image: url(${props => props.BackgroundImage});
  background-size: ${props => props.BackgroundSize || "contain"};
  background-repeat: ${props => props.BackgroundRepeat || "no-repeat"};
  background-position: ${props => props.BackgroundPosition || "center center"};
`;



export const CommonInput = styled.input`
  width: ${props => props.Width || '100%'};
  min-width: ${props => props.MinWidth || null};
  max-width: ${props => props.MaxWidth || null};
  height:${props => props.Height || '100%'};
  min-height: ${props => props.MinHeight || null};
  max-height: ${props => props.MaxHeight || null};
  background-color: ${props => props.BackgroundColor || null};
  pointer-events: ${props => props.PointerEvents || "all"};
  font-family: ${props => props.FontFamily || 'Spoqa HanSansNeo'};
  border: ${props => props.Border || "none"};
  border-radius: ${props => props.BorderRadius || null};
  margin: ${props => props.Margin || null};
  margin-top: ${props => props.MarginTop || null};
  margin-right: ${props => props.MarginRight || null};
  margin-bottom: ${props => props.MarginBottom || null};
  margin-left: ${props => props.MarginLeft || null};
  padding: ${props => props.Padding || null};
  padding-top: ${props => props.PaddingTop || null};
  padding-right: ${props => props.PaddingRight || null};
  padding-bottom: ${props => props.PaddingBottom || null};
  padding-left: ${props => props.PaddingLeft || null};
  ::-webkit-outer-spin-button{
    -webkit-appearance: none;
  };
  ::-webkit-inner-spin-button {
    -webkit-appearance: none;
  };
`;

export const TextInput = styled(CommonInput)`
  color: ${props => props.FontColor || "#000"}; 
  font-size: ${props => props.FontSize || "16px"}; 
  font-weight: ${props => props.FontWeight || null};
  word-spacing: ${props => props.WordSpacing || null};
  letter-spacing: ${props => props.LetterSpacing || null};
  text-align: ${props => props.TextAlign || null};
  line-height: ${props => props.LineHeight || null};
  text-decoration: ${props => props.TextDecoration || null}; 
  white-space: pre-line;
  ::placeholder {
        font-size: ${props => props.PFontSize || "16px"}; ;
        color: ${props => props.PFontColor || "#000"}; ;
  }
  :focus {
      outline: none; border: none;
  }
`;




export const CommonLable = styled.label`
  display: ${props => props.Display || null};
  position: ${props => props.Position || null};
  width: ${props => props.Width || '100%'};
  min-width: ${props => props.MinWidth || null};
  max-width: ${props => props.MaxWidth || null};
  height:${props => props.Height || '100%'};
  min-height: ${props => props.MinHeight || null};
  max-height: ${props => props.MaxHeight || null};
  background-color: ${props => props.BackgroundColor || null};
  background: ${props => props.Background || null};
  left: ${props => props.Left || null};
  top: ${props => props.Top || null};
  right: ${props => props.Right || null};
  bottom: ${props => props.Bottom || null};
  margin: ${props => props.Margin || null};
  margin-top: ${props => props.MarginTop || null};
  margin-right: ${props => props.MarginRight || null};
  margin-bottom: ${props => props.MarginBottom || null};
  margin-left: ${props => props.MarginLeft || null};
  padding: ${props => props.Padding || null};
  padding-top: ${props => props.PaddingTop || null};
  padding-right: ${props => props.PaddingRight || null};
  padding-bottom: ${props => props.PaddingBottom || null};
  padding-left: ${props => props.PaddingLeft || null};
  z-index: ${props => props.ZIndex || null};
  opacity: ${props => props.Opacity || null};
  border: ${props => props.Border || null};
  border-radius: ${props => props.BorderRadius || null};
  pointer-events: ${props => props.PointerEvents || "none"};
  font-family: ${props => props.FontFamily || 'Spoqa HanSansNeo'};
  transform: ${props => props.Transform || null};
  cursor: ${props => props.Cursor || null};
  -webkit-text-size-adjust: none;
`;

export const BlockLabel = styled(CommonLable)`
`;













export const ModalContainerBox = styled(ContainerBox)`
    position: fixed;
    left: 0;
    top: 0;
    z-index: 100;
`;

export const ModalBlackBox = styled.div`
    position: fixed;
    left: 0;
    top: 0;
    height:100vh;
    width:100vw;
    pointer-events: ${props => props.PointerEvents || "all"};
    background-color: ${props => props.BackgroundColor || "black"};
    opacity: ${props => props.Opacity || 0.8};
    z-index: 0;
`;
