import {React , useEffect, useState} from "react"
import { Block, FlexArea, ContainerBox,CommonButton ,ModalContainerBox,ModalBlackBox,TextBox, TextButton, ImgBox, ImgButton } from "../../commonLib/StyleMasterComp"
import styled from "styled-components";
// import IcoChevronDown from "../../images/pageMain/pageMemberManagement/ico_chevronDown.png";
import IcoChevronDown from "../../images/pageService/footer/ico_chevronDown.png";
import Utils_ui from '../../commonProj/Utils_ui';



// 드롭다운을 구성하는 버튼들 hover사용
let DropButton=styled(TextButton)`
:hover
{
    background-color: ${props => props.HBackgrondColor || "#999999"};
}
`;


/**
 * @marker Shim yujin(zinna)
 * 
 * @param {*} props.optionList         : 드롭 다운에 들어갈 항목 배열 07.21기준 양식[{name: "ddd"},{name: "ddd"},{name: "ddd"}]
 * @param {Object} props.selectCallback(item) : 선택된 드롭 다운 항목을 콜백으로 전달한다.(07.21기준 item오브젝트 반환)
 * @param {Number} props.iSelectOption : 선택된 드롭 다운 항목의 배열 번호를 넘긴다.
 * 
 * @description 드롭 다운을 생성한다. 
 * 
 */

// props.TextAlign가 센터가 아니고 left일 경우 "0.56rem" PaddingLeft 값 적용.


function DropdownF(props) 
{
    const [isOpenDrop, setIsOpenDrop] = useState(false);                      // 드롭 박스가 펼쳐져있는지 확인용
    const [strSelect, setStrSelect ]= useState(props.optionList[0].name);   // 선택된 옵션 표시 용도 변수(0 번째를 디폴트로 한다.)

    useEffect(()=>
    {
        console.log("드롭 다운 오픈 여부: ",isOpenDrop)
    },[isOpenDrop])

    // 선택한 옵션에 따라 업데이트
    useEffect(()=>
    {
        if(props.iSelectOption != null) setStrSelect(props.optionList[props.iSelectOption].name);
    },[props.iSelectOption])

    return(
        // 크기 지정
        <Block Position="relative" MarginLeft={props.MarginLeft} Width={props.Width} Height={props.Height} backgroundColor={props.BackgroundColor}>
            {/* 전체 버튼 */}
            <CommonButton Position="absolute" onClick={()=>{setIsOpenDrop(!isOpenDrop);}} BackgroundColor="none"></CommonButton>
            {/* 테두리가 들어간 박스 */}
            <Block Position="absolute" Border="1px solid #757575" BorderRadius="clamp(1.5px,0.12rem,6px)" BackgroundColor="none">
                {/* 현재 선택된 옵션 표시 */}
                <FlexArea Position="absolute" JustifyContent="center">
                    <TextBox Position="absolute" PaddingLeft={props.TextAlign == "center"? 0 : "1rem"} Height="none" FontSize="16px" FontColor={props.FontColor} TextAlign={props.TextAlign} BackgroundColor="none">{strSelect}</TextBox>
                </FlexArea>
                {/* 아래 화살표 아이콘  */}
                <FlexArea Position="absolute" JustifyContent="right">
                    <ImgBox PaddingRight="15px" Width="9px" Height="6px" BackgroundSize="contain" BackgroundImage={IcoChevronDown} BackgroundColor="none"></ImgBox>
                </FlexArea>
            </Block>
            {/* 펼쳐진 옵션 박스 */}
            {isOpenDrop ? <Block Top="100%" Position="relative" Width="100%" Border="1px solid #757575" Height={100 * (props.optionList.length) +"%"} BackgroundColor="white" ZIndex="100">
                {/* 선택할 수 있는 옵션 출력 */}
                    {props.optionList?.map((item,i)=>{
                        // if(item.name != strSelect)
                        return(
                            // 옵션별 버튼 생성
                            <DropButton key={i} Width="100%" Height={100 / (props.optionList.length) + "%"} FontColor={props.FontColor} HBackgrondColor="#F4F4F4" BackgroundColor="none" PaddingLeft={props.TextAlign == "center" ? 0 : "0.56rem"} TextAlign={props.TextAlign} FontSize="16px"
                            onClick=
                            {()=>{props.selectCallback(item); 
                                setIsOpenDrop(false); 
                                setStrSelect(item.name)}}> {item.name} </DropButton> 
                            )})
                    }
            </Block>:null}
        </Block>
    );
}

export default DropdownF;