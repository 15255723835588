/**
@Class          : PageChangePW.js
@Date           : 2022-08-14
@Version        : 1.0
@Description    : 비밀번호 재설정 페이지
 */
import React, { useState } from "react";
import { Block, BlockLabel, ContainerBox, FlexArea, ImgBox, ImgButton, TextBox, TextButton, TextInput } from "../commonLib/StyleMasterComp";
import { TextInputS, DefaultButtonS } from "../commonLib/ServiceStyleMaster";
import styled from "styled-components";
import { useNavigate } from "react-router-dom";

import Utils_ui from "../commonProj/Utils_ui";
import IcoPWViewOff from "../images/pageSignup/ico_eye1.png"
import IcoPWViewOn from "../images/pageSignup/ico_eye2.png"
import IcoPWCheck from "../images/pageSignup/ico_check.png"
import IcoCheck from "../images/pageSignup/ico_checkbox.png"
import { NSFetch, _API_URL } from "../NSCommon/middleware/NSCommunication";

let BoldSpan = styled.span`
    font-weight: 500;
`;

function PageChangePW(props)
{
    const navigate = useNavigate();

    const [isPWViewMode, setIsPWViewMode] = useState(false);                // 비밀번호 보기
    const [isConfirmPWViewMode, setIsConfirmPWViewMode] = useState(true);   // 비밀번호 확인
    const [isAgreeCheckbox, setIsAgreeCheckbox] = useState(false);           // 약관 동의 체크박스
    const [isActiveRequestBtn, setIsActiveRequestBtn] = useState(false);     // 가입신청 버튼 활성화

    const [strErrorText, setStrErrorText] = useState("이메일을 입력하지 않았습니다."); // 오류 문구
    const [iSecurityState, setISecurityState] = useState(0);                 // 보안 단계

    const [email, setEmail] = useState(null);
    const [pw, setPW] = useState(null);
    const [pw2, setPW2] = useState(null);

    const securityArr = [
        { state: "NONE", text: "", color: "" },
        { state: "LOW", text: "보안 단계 낮음", color: "#F34336" },
        { state: "HIGH", text: "보안 단계 높음", color: "#00C64F" }
    ]

    function checkEmail()
    {
        return email.match(
            /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        );
    }

    function checkPW(pw)
    {
        var passwd = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{6,20}$/;
        if (pw.value.match(passwd))
            setISecurityState(1);
        passwd = /^(?=.*[0-9])(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]{7,15}$/;
        if (pw.value.match(passwd))
            setISecurityState(2);

    }

    function emailChangeHandler(e)
    {
        var _em = e.target.value;
        setEmail(_em);

        validateAll(_em, pw, pw2);
    }

    function pw1ChangeHandler(e)
    {
        var _pw = e.target.value;
        setPW(_pw);
        checkPW(_pw);

        validateAll(email, _pw, pw2);
    }

    function pw2ChangeHandler(e)
    {
        var _pw = e.target.value;
        setPW2(_pw);

        validateAll(email, pw, _pw);
    }

    function validateAll(_email, _pw1, _pw2)
    {
        setIsActiveRequestBtn(false);

        console.log(_pw1, _pw2);

        if (!checkEmail(_email))
        {
            setStrErrorText("올바른 이메일을 입력해 주세요");
            return;
        }

        if (_pw1 != _pw2)
        {
            setStrErrorText("패스워드가 일치하지 않습니다.");
            return;
        }

        setStrErrorText("");

        setIsActiveRequestBtn(true);
    }

    /**
     * 회원 가입 폼 전송
     */
    function submit()
    {
        const _url = `${_API_URL}auth/signup`;
        const data = {
            userId: email,
            userPw: pw
        }

        function okFunc(res)
        {
            alert('회원 가입에 성공했습니다.');
            window.location.href = res.data.url;
        }

        function errFunc(res, code)
        {
            alert('회원 가입에 실패했습니다.')
        }

        NSFetch(_url, 'POST', data, okFunc, errFunc)
    }

    function move()
    {
        alert('클릭');
        navigate('/');
    }

    return (
        <ContainerBox>
            <FlexArea>
                <Block Width="440px" MinWidth="440px">
                    {/* 타이틀 */}
                    <Block MarginTop="55px">
                        <TextBox Height="65px" FontSize="52px" LineHeight="65px" FontWeight="400" TextAlign="center">
                            비밀번호 <BoldSpan>재설정</BoldSpan>
                        </TextBox>
                    </Block>

                    {/* 안내 문구 */}
                    <Block MarginTop="8px">
                        <TextBox Height="40px" FontSize="14px" FontColor="#333333" TextAlign="center">
                            가입한 이메일 주소를 입력해주세요.<br />
                            비밀번호 재설정 링크 메일이 발송됩니다.
                            {/* 새로운 비밀번호를 변경해주세요. */}
                        </TextBox>
                        {/* <TextBox Height="21px" FontSize="14px" FontColor="#F34336" TextAlign="center">
                            {strErrorText}
                        </TextBox> */}
                    </Block>

                    {/* 1. 이메일 확인 */}
                    <Block>
                        {/* 이메일 */}
                        <FlexArea Position="relative" MarginTop="32px">
                            <TextInputS
                                autoComplete="off"
                                placeholder="이메일 주소를 입력하세요."
                                maxLength={20}
                                type="email" name="email"
                                onChange={emailChangeHandler}
                                // value={objValues.email || ""}
                                value={email || ""}
                                required
                            />
                        </FlexArea>
                        
                        {/* 문의 */}
                        <FlexArea MarginTop="12px">
                            <TextButton Width="auto" Height="18px" FontSize="14px" LineHeight="18px" FontColor="#333333" FontWeight="500">
                                메일이 오지 않았나요?
                            </TextButton>
                        </FlexArea>
                    </Block>

                    {/* 2. 비밀번호 변경 재설정 */}
                    <Block MarginTop="32px" Display="none">
                        {/* 비밀번호 */}
                        <FlexArea Position="relative" MarginTop="10px">
                            <TextInputS
                                autoComplete="new-password"
                                placeholder="비밀번호를 입력하세요."
                                maxLength={20}
                                type={isPWViewMode ? "text" : "password"} name="password"
                                onChange={pw1ChangeHandler}
                                // value={objValues.password || ""}
                                value={pw}
                                required
                            />
                            {/* 비밀번호 보기 버튼 */}
                            <ImgButton Position="absolute"
                                Width="16px" Height="16px"
                                Top="22px" Right="16px"
                                BackgroundImage={isPWViewMode ? IcoPWViewOff : IcoPWViewOn}
                                type="button"
                                onClick={() =>
                                {
                                    setIsPWViewMode(!isPWViewMode);
                                }}
                            />
                        </FlexArea>

                        {/* 보안 단계 텍스트 */}
                        <FlexArea JustifyContent="end" MarginTop="5px">
                            <TextBox Width="none" Height="none" PaddingRight="16px" FontSize="12px" FontColor={securityArr[iSecurityState].color} BackgroundColor="none">{securityArr[iSecurityState].text}</TextBox>
                        </FlexArea>

                        {/* 비밀번호 확인 */}
                        <FlexArea Position="relative" MarginTop="5px">
                            <TextInputS
                                autoComplete="new-password"
                                placeholder="비밀번호 확인"
                                maxLength={20}
                                type={isPWViewMode ? "text" : "password"} name="password"
                                onChange={pw2ChangeHandler}
                                // value={objValues.password || ""}
                                value={pw2}
                                required
                            />
                            {/* 비밀번호 확인 아이콘 */}
                            <ImgButton Position="absolute"
                                Width="16px" Height="16px"
                                Top="22px" Right="16px"
                                BackgroundImage={isConfirmPWViewMode ? IcoPWCheck : null}
                                type="button"
                            />
                        </FlexArea>
                    </Block>


                    {/* 확인 버튼 */}
                    <FlexArea>
                        <DefaultButtonS MarginTop="60px"
                            PointerEvents={isActiveRequestBtn ? "all" : "none"}
                            BackgroundColor={isActiveRequestBtn ? "#684DF1" : "#DEDEDE"}
                            onClick={submit}
                        >
                            확인
                        </DefaultButtonS>
                    </FlexArea>

                </Block>
            </FlexArea>
        </ContainerBox>
    )
}

export default PageChangePW;