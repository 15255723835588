import axios from 'axios';
import DataManager from '../../../common/data/DataManager';
import Constants_ui from '../../commonProj/Constants_ui';
import { GetAccessToken, SetAccessToken } from '../NSUtils';
import { GetCurrUser, GotoLoginPage, Logout } from './NSAuth';

// export const _API_URL = Constants_ui.IS_SERVERTEST?Constants_ui.TESTSERVERURL:"https://dev.netstream.co.kr/peata/api/";
export const _API_URL = process.env.REACT_APP_BASE_SERVER_URL;
const API_URL = _API_URL;

/**
 * Netstream Fetch
 * 
 * access_token, refresh_token에 대한 처리가 자동 고려 되어 있다.
 * (access_token은 localStorage에, refresh_token은 secured cookie에 저장 및 API콜마다 자동 전송)
 * 401 Unauthorized에 대한 처리가 자동으로 되어 
 * 로그 아웃이 인식되는 경우 서비스 페이지의 로그인 창으로 바로 이동한다.
 * 
 * @param {*} _url 
 * @param {*} _method GET POST DELETE UPDATE CREATE PUT 
 * @param {*} _body     body of object, not string
 * @param {*} func      통신이 성공적으로 일어났을 때 실행될 콜백 함수. result와 statusCode(http)를 인수로 받음.
 * @param {*} errFunc   통신이 실패했을 때 실행될 콜백 함수. err내용, statusCode(http), result를 인수로 받음.
 * @param {*} customHeader  json of custom header
 * @param {*} ssff      통신시 돌아갈 Spinner를 보이게 하는 state에 대한 set 함수의 레퍼런스
 * @returns 
 */
export async function NSFetch(_url, _method, _body, func, errFunc, customHeader, ssff) {
    __NSFetch(_url, _method, _body, func, errFunc, customHeader, { relayCode: false }, ssff);
}

export async function NSFetchLogin(_url, _method, _body, func, errFunc, customHeader, ssff) {
    __NSFetch(_url, _method, _body, func, errFunc, customHeader, { relayCode: true }, ssff);
}

export async function NSFetchRelayCode(_url, _method, _body, func, errFunc, customHeader, ssff) {
    __NSFetch(_url, _method, _body, func, errFunc, customHeader, { relayCode: true }, ssff);
}

/**
 * files - input[type=file]인 것들의 배열(document.querySelector('input[type="file"]'))
 * @param {*} files     [{key, [files]}, {key, [files]}]
 */
export async function NSFetchUploadFile(_url, _method, _body, func, errFunc, files, ssff) {
    __NSFetch(_url, _method, _body, func, errFunc, false, { relayCode: true, files }, ssff);
}


/**
 * relayCode : true일 경우 401의 handling을 외부에서 하겠다는 뜻.
 */
async function __NSFetch(_url, _method, _body, func, errFunc, customHeader, other, setSpinnerFlagFunc) {
    if ( setSpinnerFlagFunc ) setSpinnerFlagFunc(true);
    // const navigate = useNavigate();
    const option = {
        method: _method,
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json'
            // 'Content-Type': 'application/x-www-form-urlencoded'
        }
    }
    if (customHeader && typeof customHeader === 'object') {
        option.headers = { ...option.header, ...customHeader };
    }
    else if ( typeof customHeader==='boolean' && customHeader===false ) // customHeader값이 false라면 헤더를 쓰지 않는다.
    {
        option.headers = {};
    }

    const accessToken = GetAccessToken();
    // if ( bearerToken && typeof bearerToken==='string' && bearerToken.length>0 ) {
    // _l('access : ', accessToken);
    if (accessToken) {
        option.headers.Authorization = `Bearer ${accessToken}`;
    }

    console.log('Option.header :', option.headers);

    let method = _method;
    switch (method) {
        case 'CREATE':
            method = 'POST';
            break;
        case 'UPDATE':
            method = 'PATCH';
            break;
        case 'READ':
            method = 'GET';
            break;
        default:
    }
    switch (method) {
        case 'PATCH':
        case 'POST':
        case 'DELETE':
        case 'PUT':
            if (_body && typeof _body === 'object') {
                option.body = JSON.stringify(_body);
                option.mode = 'cors';
                // option.referrerPolicy = _RP.UNSF;
            }
            break;
        case 'GET':
            if (_body && typeof _body === 'object') {
                _url +=
                    "?"
                    + Object.keys(_body).map(
                        item => {
                            return item + "=" + _body[item];
                        }).join('&');
            }

        default:
    }

    // 파일 전송의 경우
    if ( other.files )
    {
        if ( !Array.isArray(other.files) )
        {
            console.error("files에는 Array를 넣어야 합니다. usage> [{key, [files]}, {key, [files]}]");
            return;
        }

        var _formData = new FormData();
        // other.files     [{key, files:[file1, file2,...]}, {key, files:[]}]
        for (const file of other.files) 
        {
            for (const aFile of file.files) 
            {
                _formData.append(file.key, aFile);
            }
        }

        // body가 있을 경우 formData에 모두 때려 넣어야 함
        if (_body && typeof _body === 'object') {
            option.body = JSON.stringify(_body);
            option.mode = 'cors';
            // option.referrerPolicy = _RP.UNSF;

            for( const key of Object.keys(_body) )
                _formData.append(key, _body[key])
        }
        option.body = _formData;
    }

    console.log(`[I] ${method} "${_url}"`);

    const result = await fetch(_url, option)
        .then((response) => 
            {
                if ( setSpinnerFlagFunc ) setSpinnerFlagFunc(false);
                console.log("response : ", response.headers);
                // 헤더에 access_token이 온다면 무조건 localStorage에 저장한다.
                // const accessToken = response.headers.get('access_token');
                // if ( accessToken )

                response.headers.forEach( (val, key) => { 
                    if ( key==="access_token" )
                    {
                        console.log("AT : ", val);
                        SetAccessToken(val);
                    }
                })
                return response.json()
            }
        )
        .then((res) => {
            const sCode = res.statusCode || res.status;
            if ( setSpinnerFlagFunc ) setSpinnerFlagFunc(false);
            if (sCode && parseInt(sCode, 10) === 401) {
                alert('NSCommunications:NSFetch - 401 Unauthorized');
                // 로그아웃 되었을 때
                Logout();
                // relayCode가 설정되었을 땐 401에 대한 에러코드 처리는 errFunc에서 한다.
                if (other.relayCode && errFunc) {
                    const e = new Error(`${res.message}, status : ${res.statusCode}`);
                    errFunc(e, res.statusCode);
                }
                else {
                    // alert('로그인에 실패했거나 로그인 상태가 아닙니다.');
                    // window.location.replace('/');
                    GotoLoginPage();
                }

                return;
            }
            console.log('NSCommunications:NSFetch result - ', res);
            if (sCode && parseInt(sCode, 10) > 299) // 오류 상황 발생
            {
                const e = new Error(`${res.message}, status : ${sCode}`);
                if (errFunc) errFunc(e, sCode, res.data);
                else alert("NSCommunications statusCode error " + e.message);
                return;
            }
            // 최종적으로 아무 문제 없다면 성공시 실행할 함수 실행.
            if (func) func(res);
        })
        .catch((e) => {
            if ( setSpinnerFlagFunc ) setSpinnerFlagFunc(false);
            console.error(`[E] ${_method} "${_url}" fetch중 오류 발생 : `);
            console.error(e);
            if (errFunc) {
                errFunc(e);
            }
            else {
                alert("NSCommunications error caught : " + e.message);
            }
        });

    return result;
}

// 특정 회사 회원 목록 받아오기
export function FetchMemberList(companyId) {
    let data = {};
    const accessToken = GetCurrUser().access_token;
    return axios
    .get(API_URL + `manage/company/${companyId}/users`, {
        headers: {
            "Content-Type": `application/json`,
            "Authorization" : `Bearer ${accessToken}`
        }
    })
    .then(res => {
        console.log("[S] Fetch Company Member List OK : ", res.data.data);
        return res.data.data;
    })
    .catch(ex=>{
        console.log("[E] Fetching member list Failed : " + ex);
    })
    .finally(()=>{console.log("[I] Fetching member list completed")});
}

// 특정 회사 회원 목록 받아오기
export function FetchArtList(companyId) {
    let data = {};
    const accessToken = GetCurrUser().access_token;
    return axios
    .get(API_URL + `manage/company/${companyId}/objects`, {
        headers: {
            "Content-Type": `application/json`,
            "Authorization" : `Bearer ${accessToken}`
        }
    })
    .then(res => {
        console.log("[S] Fetch Artwork List OK : ", res.data.data);
        return res.data.data;
    })
    .catch(ex=>{
        console.log("[E] Fetching Artwork List Failed : " + ex);
    })
    .finally(()=>{console.log("[I] Fetching Artwork List completed")});
}
