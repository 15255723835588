import Utils_ui from "../../../commonProj/Utils_ui";

function LoginConfirmRules(objNeedChekData){
    let objErrors={}

    if(!objNeedChekData.email){
        objErrors.email = "아이디 입력값이 충분치 않습니다.";
    }else if(!Utils_ui.VerifyEmailStr(objNeedChekData.email)){
        objErrors.email = "아이디 입력양식을 다시한번 확인해주세요.";
    }

    // 기획안 기준 비밀번호 오류 메세지가 없어서 확인필요
    if(!objNeedChekData.password){
        objErrors.password = "Password를 입력해주세요!";
    }else if(Utils_ui.SecurityLevelPWStr(objNeedChekData.password) === 0){
        objErrors.password = "Password 입력값이 충분치 않습니다.";
    }else if(Utils_ui.SecurityLevelPWStr(objNeedChekData.password) === 99){
        objErrors.password = "Password는 공백 없이 입력해주세요.";
    }

    console.log("데이터 체크한다 : ", objNeedChekData, objErrors);
    return objErrors;
}

export default LoginConfirmRules;