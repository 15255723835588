import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import Constants_ui from '../../commonProj/Constants_ui';
import { GetAccessToken, RemoveAccessToken, SaveLocalStorage, SetAccessToken } from '../NSUtils';
import { NSFetch, NSFetchLogin, NSFetchRelayCode, _API_URL } from './NSCommunication';

// const API_URL = Constants_ui.IS_SERVERTEST?Constants_ui.TESTSERVERURL:"https://dev.netstream.co.kr/peata/api/";
const API_URL = _API_URL;
console.log("API_URL : ", _API_URL);

export function ParseJwt(token)
{
    const base64Url = token.split('.')[1];
    const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    const jsonPayload = decodeURIComponent(atob(base64).split('').map((c) => {
        const inner = `00${c.charCodeAt(0).toString(16)}`;
        return `%${inner.slice(-2)}`;
        // return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
    }).join(''));

    return JSON.parse(jsonPayload);
}

export function checkJWTValid()
{
    const jwtJSON = ParseJwt( GetAccessToken() );
    if ( Date.now()>jwtJSON.exp*1000 ) // expire된 상태
    {
        console.warn("[W] JWT expired");
        return false;
    }
    else
    {
        return true;
    }
}


// export function getAccessToken()
// {
//     const user = JSON.parse(localStorage.getItem("user"));
//     // console.log("유저는 : ", user);
//     if ( user )
//         return user.access_token;
//     else // 이경우 login 페이지로 보내야 함.
//     {
//         // 로그아웃 처리 필요 by KDG 2022. 06. 08
//         window.location.href="/";
//         return null;
//     }
// }

/**
 * 현재 로그인이 되어 있는지 체크.
 * 로그인이 되어 있는지 체크하기 위해서는 반드시 본 함수를 사용해야 함.
 * (향후 업그레이드 될 예정이므로 넷스트림 전체 환경에서 공통으로 사용해야 함.)
 * 
 * @return   true/false
 */
export function CheckLoggedIn()
{
    // 2022. 07. 28. 현재는 localStorage에 user가 있는지 판단하는 것으로 로그인 되었는지 확인하고 있다.
    // 2022. 07. 29. localStorage에 access_token가 있다면 로그인 한것으로 판단.
    const jwt = GetAccessToken();
    if ( jwt === null || !checkJWTValid() )
    {
        Logout();
        return false;
    }
    else
    {
        const _j = ParseJwt(jwt);
        console.log("JWT 내용 : ", _j);
        // if ( _j.expired )

        return true;
    }
}

export function CheckLoggedInFromServer(okFunc, errFunc)
{
    const _url = API_URL + "auth/check/login";

    const loginSuccessFunc = (res) => {
        if ( res.status===401 ) // 로그인이 되지 않은 상태
        {
            console.log("[E] 로그인 되지 않았음 --> 로컬 로그아웃시킴.");
            Logout();
        }
        if ( okFunc )
            okFunc();
        return res.data;
    }

    const loginFailFunc = (e, statusCode, data) => {
        if ( statusCode===401 )
        {
            console.log("[E] 로그인 되지 않았음 --> 로컬 로그아웃시킴.");
            Logout();
        }
        if (errFunc )
            errFunc(statusCode);
    }
    NSFetchRelayCode(_url, "GET", null, loginSuccessFunc, loginFailFunc, null);
}

// 로그인
/**
 * 로그인에 성공하면 localStorage에 토큰을 저장하고 okFunc 을 실행한다.
 * @param {*} userName 
 * @param {*} password 
 * @param {*} okFunc 
 * @param {*} errFunc 
 */
export function Login(userName, password, okFunc, errFunc) {
    let data = {userId : userName, userPw : password};

    const _url = API_URL + "auth/login";

    const loginSuccessFunc = (res) => {
        console.log("res.data.accessToken : " + res.data.access_token);
        if (res.data.access_token) {
            // NSFetch에서 header에 access_token이 오면 자동으로 세팅되므로 필요 없음
            // SetAccessToken(res.data.access_token) 
            localStorage.setItem("user", JSON.stringify(res.data));
        }

        if ( okFunc )
            okFunc();
        return res.data;
    }

    const loginFailFunc = (res, statusCode) => {
        console.log("[E] Login Failed!!! ");
        Logout();
        if (errFunc )
            errFunc(statusCode);
    }
    NSFetchLogin(_url, "POST", data, loginSuccessFunc, loginFailFunc, null);
}

/**
 * 서버에게 로그아웃을 요청하는 함수
 * 일반 로그아웃은 localStorage레벨에서 내용을 정리하기 때문에 
 * 계정을 통째로 로그아웃 시키기 위해 서버에서 JWT에 대한 무효화를 요청한다.
 * 
 * *** 주의 : Logout 함수와 이 함수를 함께 사용하면 안된다. 
 * 특히, 이 함수보다 Logout을 먼저 하게 되면 access_token 사용에 문제가 생긴다.
 * 
 * @param {*} okFunc 
 * @param {*} errFunc 
 */
 export function RequestLogout(okFunc, errFunc) {
    const _url = API_URL + "auth/logout";

    const loginSuccessFunc = (res) => {
        console.log("로그아웃 됨. res : " + res);
        Logout();

        if ( okFunc )
            okFunc(res);
        return res.data;
    }

    const loginFailFunc = (e, statusCode, data) => {
        if ( statusCode===401 ) // 정상 로그아웃 케이스
        {
            if ( okFunc )
                okFunc(data);
            return;
        }
        console.log("[E] Logout Failed!!! ", statusCode, e);
        Logout();
        if ( errFunc )
            errFunc(statusCode);
    }
    NSFetchRelayCode(_url, "POST", null, loginSuccessFunc, loginFailFunc, null);
}


//로그아웃
export function Logout(){
    console.log("Logout")
    localStorage.removeItem("user");
    RemoveAccessToken();
}

//회원가입
export function Register(userId,userPw,name){

    let data = {userId : userId
                ,userPw : userPw
                ,name : name};

    return axios
    .post(API_URL + "auth/signup", JSON.stringify(data), {
        headers: {
            "Content-Type": `application/json`,
    }})
    .then(res => {
        console.log("res.data : " + res.data);
        return res.data;
    })
}

// 회원탈퇴
export function ResignMembership(accessToken){
    axios.defaults.headers = {
        Authorization : `Bearer ${accessToken}`
    }

    return axios
    .post(API_URL + "auth/withdraw")
    .then(res => {
        console.log("res.data : " + res.data);
        return res.data;
    })
}

// 회원 문의하기
export function InquiryMembership(accessToken){
    axios.defaults.headers = {
        Authorization : `Bearer ${accessToken}`
    }

    return axios
    .post(API_URL + "question")
    .then(res => {
        console.log("res.data : " + res.data);
        return res.data;
    })
}

//현재 유저 체크
export function GetCurrUser(){
    return JSON.parse(localStorage.getItem("user"));
}

// 전송 토큰 유저 정보(라이트)
export function GetProfile(accessToken){
    console.log("GetProfile")

    return axios
    .get(API_URL + "auth/profile", {
        headers: {
            Authorization: `Bearer ${accessToken}`
    }})
    .then(res => {
        console.log("res.data : " + res.data);
        return res.data;
    })
}

// 전송 토큰 유저 정보(디테일)
export function GetTotalProfile(cb){
    console.log("GetTotalProfile")
    // console.log("accessToken",accessToken)

    const _url = API_URL + "auth/profile";

    function okFunc(data)
    {
        if ( cb!==null )
            cb(data)
    }

    NSFetch(_url, 'GET', null, okFunc, null, null, null);

    // return axios
    // .get(API_URL + "my", 
    // {
    //     headers: {
    //         Authorization: `Bearer ${accessToken}`
    // }})
    // .then(res => {
    //     console.log("res.data : " + res.data);
    //     return res.data;
    // })
}

//가입 시 중복 체크
export function GetDupli(strName){
    console.log("GetDupli")

    return axios
    .get(API_URL + "auth/signup/dupli", {
        params: {
            name: strName
        }
    })
    .then(res => {
        console.log("res.data : " + res.data);
        return res.data;
    })
}

/*********************************************************************************
 * Subdomain JWT sharing을 위한 iframe postMessage 관련 설정
 * 
 * subdomain의 서비스가 로그인을 하려 할 때 서비스 페이지의 로그인 창을 띄워야 한다.
 * URL : https://service.domain?dest=https://subdomain.domain?
 * 서비스 페이지의 로그인창에서 로그인이 완료되고 나면 다음의 과정이 진행된다.
 * 0. iframe 관련 창(탭)을 window.open으로 오픈.
 * 1. iframe으로 포워딩되는 subdomain url의 base url을 로딩
 * 2. postMessage를 이용해 jwt 토큰과 forward URL(dest)을 (1.)의 base url에 전송
 * 3. (2.) 의 subdomain base url에서 "message" 이벤트 발생
 * 4. (3.)에서 받은 메시지 이벤트 핸들러에서 JWT를 localStorage에 저장
 * 5. (2.)에서 받은 dest URL 로 parent window의 window.href를 변경.
 * 

*********************************************************************************/
// $(document).ready(function(){
//     window.addEventListener("message",receivePostMsg);
// });

// receiveMsg의 event listener는 모든 서브도메인에서 공통적으로 쓰인다.
// 즉, service page와 여타 subdomain들(갤러리, cms, ...)에서 
// 공통적으로 NSAuth.js파일을 적당한 위치에 넣어 두기만 하면 된다.
// data에 들어오는 메시지 내용에 따라 처리를 달리하고 있다.
window.addEventListener("message",receivePostMsg);
function receivePostMsg(event)
{
//    alert("이벤트 들어옴");
    console.log("이벤트 들어옴 : ", event.data, window.location.origin);

    // 서브도메인 (iframe 속)
    // access_token과 target_url이 존재하면 access_token을 받는 쪽이다.
    if ( event.data.access_token && event.data.target_url)
    {
        console.log("액세스 토큰 저장 : ", event.data.access_token);
        console.log("url 비교 : ", event.data.target_url, new URL(event.data.target_url).origin, window.location.origin );
        // 액세스 토큰을 로컬스토리지에 저장한다.
        SaveLocalStorage("access_token", event.data.access_token);
            
        // target_url이 현재 열린 창과 동일하다면
        if ( new URL(event.data.target_url).origin==window.location.origin )
        {
            // 현재 iframe을 open한 부모 쪽에 성공적으로 받았음을 알린다.
            const msg = {
                at_received: true,
                target_url: event.data.target_url
            };
            event.source.postMessage(msg, "*");
        }
    }
    // 서비스 페이지 (iframe 밖)
    // at_received 가 존재하면 AT를 받은 subdomain측에서 reply를 postmessage로 날린 것이다.
    else if ( event.data.at_received )
    {
        // 답이 왔으므로 본 윈도우를 close하고 부모를 dest로 이동시킨다.
        alert("at 전송 완료. 이동 : " +  decodeURI(event.data.target_url) );
        window.opener.location = decodeURI(event.data.target_url);
        alert("오프너 이동 완료");
        window.close();

    }
}

/**
 * 
 * 로그인을 해야 할 경우 반드시 이 함수를 통해 로그인 페이지로 이동해야 한다.

 * @param {*} dest  로그인 후 이동하게 될 목적지의 full url. 이 값이 없으면 서비스페이지(랜딩페이지) 홈으로 이동한다.
 */
export function GotoLoginPage(dest='')
{
    // dest가 들어오지 않았을 경우 현재 페이지를 root(/)를 dest로 넣는다. 
    if ( dest.trim().length==0 )
        dest = window.location.href;

    const _service_base = process.env.REACT_APP_SERVICE_PAGE_URL_BASE;
    const _service_auth = process.env.REACT_APP_SERVICE_PAGE_URL_AUTH;
    let URL = `${_service_base}${_service_auth}?dest=${encodeURI(dest)}`;

    alert('Auth:GotoLoginPage - 로그인 페이지로 이동 00:48');
    console.log(`** 로그인 페이지로 이동. 로그인후 이동할 페이지 : [${dest}]`);

    // 새탭으로 로그인 창을 연다.
    window.open(URL, "_blank")
}
