import React, { useEffect, useState } from "react";
import ReactTooltip from "react-tooltip";
import { useNavigate } from "react-router-dom";
import DataManager from "../../common/data/DataManager";
import { Block, ContainerBox, FlexArea, ImgBox, TextBox, ScrollContainerBlock, TextButton, ImgButton, TextInput, CommonDiv, CommonButton } from "../commonLib/StyleMasterComp";
import Utils_project from "../commonProj/Utils_project";
import { CheckLoggedIn, CheckLoggedInFromServer, GetCurrUser, GetTotalProfile, GotoLoginPage, Logout, ParseJwt } from "../NSCommon/middleware/NSAuth";
import styled from "styled-components";

import ModalCreateGallery from "../components/pagePMS/ModalCreateGallery";
import ModalWelcome from "../page/ModalWelcome";

import FullLoadingModal from "../modals/common/FullLoadingModal";
import ImgAuthBg from "../images/pageAuth/imb_authBg.png"
import Constants_ui from "../commonProj/Constants_ui";
import { GetAccessToken, RemoveAccessToken } from "../NSCommon/NSUtils";

import DropdownSort from "../components/common/DropdownSort";

import IcoCheck from "../images/pageMain/pageGalleryManagement/ico_check.png";
import IcoZoomIn from "../images/pageMain/pageGalleryManagement/ico_zoomIn.png";

//////////////////////////////////////////////////////////////////////////////// clew

import LogoSmall from "../images/pageService/menu/logo.png";
import LogoMedium from "../images/pageService/menu/logo@2x.png";
import LogoLarge from "../images/pageService/menu/logo@3x.png";
import RandomSmall from "../images/pageService/menu/random.png";
import IcoUser from "../images/pageService/menu/ico_user.png";
import IcoHome from "../images/pageService/menu/ico_home.png";
import IcoMail from "../images/pageService/menu/ico_mail.png";
import IcoSearch from "../images/pageService/menu/ico_search.png";

import BgImageTemp from "../images/pageService/carousel/bgImage.png";
// import ImgGirl from "../images/pageService/carousel/img_girl.png";
import BgImage from "../images/pageService/carousel/bg_group.png";
import IcoPrev from "../images/pageService/carousel/ico_prev.png";
import IcoNext from "../images/pageService/carousel/ico_next.png";
import ArrowBlack from "../images/pageService/carousel/arrow_black.png";
import ArrowWhite from "../images/pageService/carousel/arrow_white.png";

import BgGallery01 from "../images/pageService/gallery/gal_img01.png";
import BgGallery02 from "../images/pageService/gallery/gal_img02.png";
import Arrow from "../images/pageService/gallery/arrow.png";
import IcoPerson from "../images/pageService/gallery/ico_persion.png";
import BgGalMini01 from "../images/pageService/gallery/gal_mini_img01.png";
import GalleryNone from "../images/pageService/gallery/gal_none.png";

import IcoMore from "../images/pageService/common/ico_more.png";
import IcoClose from "../images/pageService/common/ico_close.png";

import BG001 from "../images/pageService/metaverse/bg001.png";

import IcoArrow from "../images/pageService/ratePlan/ico_arrow.png";
import IcoCheck1 from "../images/pageService/ratePlan/ico_check_1.png";
import IcoCheck2 from "../images/pageService/ratePlan/ico_check_2.png";
import IcoCheck3 from "../images/pageService/ratePlan/ico_check_3.png";
import IcoCheck4 from "../images/pageService/ratePlan/ico_check_4.png";

import Adv01 from "../images/pageService/metaverse/adv_01.png";
import Adv02 from "../images/pageService/metaverse/adv_02.png";
import Adv03 from "../images/pageService/metaverse/adv_03.png";

import BgFeatures from "../images/pageService/metaverse/bg_Features.png";

import Prev from "../images/pageService/common/prev.png";
import Next from "../images/pageService/common/next.png";

import IcoGray from "../images/pageService/si/ico_gray.png";
import IcoGreen from "../images/pageService/si/ico_green.png";
import IcoOrange from "../images/pageService/si/ico_orange.png";
import IcoTurquoise from "../images/pageService/si/ico_turquoise.png";
import IcoWhite from "../images/pageService/si/ico_white.png";
import Img01 from "../images/pageService/si/img_01.png";
import Img02 from "../images/pageService/si/img_02.png";
import Img03 from "../images/pageService/si/img_03.png";
import Img04 from "../images/pageService/si/img_04.png";
import Img05 from "../images/pageService/si/img_05.png";

import LogoSWhite from "../images/pageService/footer/logo_s_white.png";
import Facebook from "../images/pageService/footer/ico_facebook.png";
import Tweeter from "../images/pageService/footer/ico_tweeter.png";
import Google from "../images/pageService/footer/ico_google.png";
import Linkedin from "../images/pageService/footer/ico_linkedin.png";
import DropdownF from "../components/common/DropdownF";
import { NSFetch, NSFetchRelayCode, _API_URL } from "../NSCommon/middleware/NSCommunication";
import ReactElasticCarousel from "react-elastic-carousel";



//////////////////////////////////////////////////////////////////////////////// clew
export const ScrollBox = styled(Block)`
    pointer-events:all;
    overflow-x: hidden;
    overflow-y: scroll;
`;

// 상단메뉴
// export const LandingPageMenu = styled(TextBox)`
//   width: auto;
//   padding: 0 12px;
//   color: #555555; 
//   font-size: ${props => props.FontSize || "18px"};
//   font-weight: bold;
// `;

export const FlexChild = styled(CommonDiv)`
    flex: 1;
`;

export const IconTextButton = styled(ImgButton)`
    box-sizing: border-box;
    color: ${props => props.FontColor || "#555555"};
    font-size: ${props => props.FontSize || "18px"};
    font-weight: ${props => props.FontWeight || "500"};
    word-spacing: ${props => props.WordSpacing || null};
    letter-spacing: ${props => props.LetterSpacing || null};
    text-align: ${props => props.TextAlign || "left"};
    line-height: ${props => props.LineHeight || "23px"};
    text-decoration: ${props => props.TextDecoration || null};
    white-space: pre-line;
    box-shadow: ${props => props.BoxShadow || null};
    background-color: ${props => props.BackgroundColor || "#ffffff"};
`;

export const SearchBox = styled(TextInput)`
    box-sizing: border-box;
    background-color: #EEEEEE;
    border: 1px solid #C2C2C2;
    border-radius: 27px;
    text-indent: 20px;

    background-image: url(${props => props.BackgroundImage});
    background-position: right 17px center;
    background-size: 32px 32px;
    background-repeat: no-repeat;

    :focus {
        border: 1px solid #C2C2C2;
  }
`;

export const TextBoxEllipsis = styled(TextBox)`
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
`;

// 툴팁 스타일 수정
export const ToolTipS = styled(ReactTooltip)`
    // padding: 0 !important;
    margin-left: -24px !important;
    // border: 0 !important;
    background-color: #fff !important;
    opacity: 1 !important;
    
    border: 1px solid #E0E0E0 !important;
    border-radius: 5px !important;
    padding: 19.5px 21px !important;
    box-shadow: 2px 13px 18px rgba(0, 0, 0, 0.1) !important;

    :before {
        border: none !important;
    }
    :after {
        border: none !important;
    }
`;



////////////////////////////////////////////////////////////////////////////////

// let MenuBlock = styled(Block)`
//     box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.25);
// `;

function PageService(props){
    const navigate = useNavigate();

    const [strSelectPageName, setStrSelectPageName] = useState(Constants_ui.PAGE_MEMBERMANAGEMENT);

    let [isFullLoadingModal, setIsFullLoadingModal] = useState(false); //로딩 모달
    const [flagLoggedIn, setFlagLoggedIn ] = useState( false );

    const [userInfo, setUserInfo] = useState({});   // 유저 정보
    const [myGalInfos, setMyGalInfos] = useState();  // 내가 소유한 갤러리 리스트
    const [joinedGalInfos, setJoinedGalInfos] = useState();  // 내가 가입한 갤러리 리스트
    const [recommendGalInfos, setRecommendGalInfos] = useState();  // 내가 가입한 갤러리 리스트

    const [tooltip, showTooltip] = useState(true);
    const [flagShowGetFreeGal, setFlagShowGetFreeGal] = useState(false);
    const [flagShowGotGal, setFlagShowGotGal] = useState(false);
    const [urlGotGal, setUrlGotGal] = useState("");

    let mounted = false;

    // 유저 정보 수급
    useEffect(()=>{
        if ( mounted ) return;
        mounted = true;

        if( CheckLoggedIn() )
        {
            setFlagLoggedIn( true );
            function goOn()
            {
                setFlagLoggedIn( true );
                getUserInfo();
                getGalleryListForLoginUser(); // 사용자의 소유 갤러리 가져오기
                getJoinedGalleryListForLoginUser(); // 사용자의 가입한 갤러리 가져오기
            }

            function setLogout()
            {
                setFlagLoggedIn( false );
            }

            CheckLoggedInFromServer(goOn, setLogout);
        }

        getRecommendGalleryList();

        // console.log("유저데이터 수급!!!");

        // if (mounted) {
        //     let objUser = GetCurrUser();
        //     setIsFullLoadingModal(true);

        //     if(objUser !== null){
        //         GetTotalProfile(objUser.access_token).then(
        //             (result) => {
        //                 console.log("유저데이터 성공!");
        //                 console.log("result.data",result.data);
        //                 setIsFullLoadingModal(false);
                        
        //                 if(result.data === null){
        //                     //에러 팝업 띄워야함(데이터 내용이 없습니다)
        //                     return;
        //                 }
        //                 DataManager.SetUserData(Utils_project.SetUserProfileData(result.data));
        //                 setIsSetUser(true);
        //             },
        //             error => {
        //                 console.log("오류! 로그인하러 보내기");
        //                 console.log(error);
        //                 //에러 팝업 후 어스페이지로

        //                 navigate("/auth");
        //             }
        //         );
        //     }
        // }

    },[]);

    //로그아웃
    let logout = ()=>{
        console.log("Logout!!");
        DataManager.SetUserData({});
        setFlagLoggedIn(false);
        Logout();
        navigate("/");
    }

    const login = () => {
        GotoLoginPage();
    }

    //전시장 이동
    let moveGallery = ()=>{
        console.log("전시장으로!!");
        // navigate("/main");
    }

    ////////////////////////////////////////////////////////////////////////////////

    function onUserMouseOver()
    {
        console.log("onUserMouseOver");
    }

    function buyGalClickHandler()
    {
        console.log("갤러리가 구매됨");
        // navigate("");
    }

    function signupClickHandler()
    {
        navigate('/signup');
    }

    const optionList = [
        // { name: "ALL" , code:"ALL"},
        { name: "정렬 방식", code: "CODE" },
        { name: "방식 1", code: "CODE1" },
        { name: "방식 1", code: "CODE2" },
    ];

    // 갤러리 정렬 방식 dropdown 선택
    const onClickSort = (value) =>
    {
        console.log("클릭 sort: ", value);
    }
    
    // Family Site dropdown 선택
    const onClickFamilySite = (value) =>
    {
        console.log("클릭 family site: ", value);
    }
    
    // 사용자 정보 받아오기
    function getUserInfo()
    {
        // const memID = ParseJwt(GetAccessToken()).memId;
        const _url = `${_API_URL}auth/profile`;
        function okFunc(res)
        {
            console.log("===== 유저 데이터 ", res);

            DataManager.SetUserData(res.data);
            setUserInfo( DataManager.GetUserData() );
        }
        function errFunc(e, code, res)
        {
            if ( code===401 )    // 그냥 현재 로그 아웃된 상태이므로 아무것도 하지 않는다.
            {
                console.log("로그아웃이 되어 있는 상태");
                return;
            }
            else        // 다른종류의 오류라면 오류 메시지를 보여야 함.
            {
                alert('서버와 정상적으로 통신할 수 없습니다.');
            }
        }

        const obj = null;
        console.log("===== 유저 데이터 가져오기 ", _url);
        NSFetchRelayCode(_url, 'GET', obj, okFunc, errFunc);
    }

    // 사용자의 소유 갤러리 리스트 받아오기
    function getGalleryListForLoginUser()
    {
        // const memID = ParseJwt(GetAccessToken()).memId;
        // const _url = `${_API_URL}user/${memID}/gallery`;
        const _url = `${_API_URL}my/gallery`;

        function okFunc(res)
        {
            console.log("----- 소유 갤러리 리스트", res);
            // setMyGalInfos(res.data.gallery);
            setMyGalInfos([res.data.gallery]);

        }
        function errFunc(res, code)
        {
            console.log("!!!!!!!!!! 소유 갤러리 리스트를 받아오지 못함", res);
        }

        // const obj = {
        //     name : '',
        //     phone: '',
        // }
        const obj = null;

        // console.log("--- 로그인 유저 소유 갤러리 리스트 ", _url);
        NSFetch(_url, 'GET', obj, okFunc, errFunc);
    }

    // 사용자가 가입한 갤러리 리스트 받아오기
    function getJoinedGalleryListForLoginUser()
    {
        // const memID = ParseJwt(GetAccessToken()).memId;
        // const _url = `${_API_URL}user/${memID}/gallery`;
        const _url = `${_API_URL}my/joined-gallery`;
        
        function okFunc(res)
        {
            console.log("----- 가입한 갤러리 리스트", res);
            // setJoinedGalInfos(res.data.gallery);
            setJoinedGalInfos(res.data.gallery);

        }
        function errFunc(res, code)
        {
            console.log("!!!!!!!!!! 가입한 갤러리 리스트를 받아오지 못함", res);
        }

        // const obj = {
        //     name : '',
        //     phone: '',
        // }
        const obj = null;

        // console.log("--- 로그인 유저 가입한 갤러리 리스트 ", _url);
        NSFetch(_url, 'GET', obj, okFunc, errFunc);
    }

    // 추천 갤러리 리스트 받아오기
    function getRecommendGalleryList()
    {
        // const memID = ParseJwt(GetAccessToken()).memId;
        // const _url = `${_API_URL}user/${memID}/gallery`;
        const _url = `${_API_URL}service/gallery/all`;
        
        function okFunc(res)
        {
            console.log("----- 가입한 갤러리 리스트", res);
            // setJoinedGalInfos(res.data.gallery);
            setRecommendGalInfos(res.data.gallery);

        }
        function errFunc(res, code)
        {
            console.log("!!!!!!!!!! 가입한 갤러리 리스트를 받아오지 못함", res);
        }

        // const obj = {
        //     name : '',
        //     phone: '',
        // }
        const obj = null;

        // console.log("--- 로그인 유저 가입한 갤러리 리스트 ", _url);
        NSFetch(_url, 'GET', obj, okFunc, errFunc);
    }

    function onClickCMS(e, url)
    {
        e.preventDefault();
        e.stopPropagation();

        // console.log(`${process.env.REACT_APP_BASE_CMS_URL}${url}`);
        window.location.href = `${process.env.REACT_APP_BASE_CMS_URL}${url}`;
    }

    function onClickGallery(e, url)
    {
        // e.preventDefault();
        // e.stopPropagation();

        // alert("shareURL " + props.info.shareUrl);
        // if (props.info) window.location.href = props.info.shareUrl;

        // console.log(`${process.env.REACT_APP_BASE_GALLERY_URL}${url}`);
        window.location.href = `${process.env.REACT_APP_BASE_GALLERY_URL}${url}`;
    }

    // 갤러리 섹션 내 갤러리 정보 하나
    function Gallery(props)
    {
        return (
            // <ImgBox Height="260px" MarginRight={props.MarginRight} BackgroundImage={props.BackgroundImg}>
            <CommonButton Width="auto" Padding="0" onClick={(e) => { onClickGallery(e, props.info.urlPathName) }}>
                <ImgBox Width="633px" Height="260px" MarginRight={props.MarginRight} BackgroundImage={props.info ? props.info.thumbnail.uri.url : ""}>
                    <FlexArea FlexDirection="column" JustifyContent="space-between">
                        {/* 우상단 관리자 페이지 바로가기 */}
                        <FlexArea Height="auto" MarginTop="21px" JustifyContent="right">
                            <TextButton Width="auto" onClick={(e) => { onClickCMS(e, props.info.urlPathName) }}>
                                <FlexArea>
                                    <TextBox Width="auto" Height="auto" FontColor="#ffffff" FontWeight="500" MarginRight="10px">관리자 페이지</TextBox>
                                    <ImgButton Width="26px" Height="26px" MarginRight="18px" BorderRadius="50%" BackgroundImage={Arrow} BackgroundSize="8px 12px" BackgroundColor="#ffffff" ></ImgButton>
                                </FlexArea>
                            </TextButton>
                        </FlexArea>
                        {/* 하단 정보 */}
                        <FlexArea Height="92px" BackgroundColor="rgba(0, 0, 0, 0.6)">
                            <ImgBox Width="72px" Height="72px" MarginLeft="10px" MarginRight="17px" BackgroundImage={props.info ? props.info.logo.uri.url : ""} BackgroundColor="#ffffff"></ImgBox>
                            <FlexChild Height="100%">
                                <FlexArea FlexDirection="column" JustifyContent="space-between">
                                    {/* LineHeight="110%" */}
                                    <TextBox MarginTop="20px" FontColor="#ffffff" FontWeight="500" FontSize="24px" TextAlign="left">{props.info ? props.info.name : ""}</TextBox>
                                    <FlexArea MarginBottom="13px">
                                        <FlexArea JustifyContent="left">
                                            <ImgBox Width="24px" Height="24px" MarginRight="3px" BackgroundImage={IcoPerson}></ImgBox>
                                            {/* LineHeight="155%" */}
                                            <TextBox Width="auto" Height="auto" FontColor="#BDBDBD" FontSize="18px" FontWeight="700">12345</TextBox>
                                        </FlexArea>
                                        <FlexArea JustifyContent="right">
                                            <TextBox Width="auto" Height="auto" FontColor="#BDBDBD" FontSize="16px" FontWeight="500" MarginRight="8px">개설일</TextBox>
                                            <TextBox Width="auto" Height="auto" FontColor="#BDBDBD" FontSize="16px" FontWeight="700" MarginRight="18px">{props.info ? props.info.createDate.split('T')[0].replace(/-/g, ".") : ""}</TextBox>
                                        </FlexArea>
                                    </FlexArea>
                                </FlexArea>
                            </FlexChild>
                        </FlexArea>
                    </FlexArea>
                </ImgBox>
            </CommonButton>
        )
    }

    // 갤러리 섹션 내 갤러리 미니정보 하나
    function GalleryMini(props)
    {
        return(
            <CommonButton Width="auto" Padding="0" onClick={(e) => { onClickGallery(e, props.info.urlPathName) }}>
                <ImgBox Width="240px" Height="240px" MarginRight={props.MarginRight} BackgroundImage={props.info ? props.info.landingImgUrl : ""} BackgroundColor="#F5F5F5">
                    <FlexArea FlexDirection="column" JustifyContent="end">
                        {/* 하단 정보 */}
                        <FlexArea Height="60px" JustifyContent="left" BackgroundColor="rgba(0, 0, 0, 0.6)">
                            <ImgBox Width="42px" Height="42px" MarginLeft="10px" MarginRight="17px" BackgroundImage={props.info ? props.info.logoUrl : ""} BackgroundColor="#ffffff"></ImgBox>
                            <TextBoxEllipsis Width="150px" Height="auto" FontColor="#ffffff" FontWeight="500">{props.info?(props.info.name.trim().length===0?props.info.explain:props.info.name):""}</TextBoxEllipsis>
                        </FlexArea>
                    </FlexArea>
                </ImgBox>
            </CommonButton>
        )
    }

    // 더보기 버튼
    function MoreButton(props)
    {
        return (
            <TextButton Width="103px" Height="38px" BackgroundColor="#4D4D4D" BorderRadius="19px" onClick={buyGalClickHandler}>
                <FlexArea>
                    <ImgBox Width="10px" Height="10px" BackgroundImage={IcoMore} MarginRight="6px"></ImgBox>
                    <TextBox Width="auto" Height="auto" FontColor="#ffffff" FontSize="16px" FontWeight="500" LineHeight="110%">더보기</TextBox>
                </FlexArea>
            </TextButton>
        )
    }

    // 닫기 버튼
    function CloseButton(props)
    {
        return (
            <TextButton Width="103px" Height="38px" BorderRadius="19px" Border="1px solid #999999" onClick={buyGalClickHandler}>
                <FlexArea>
                    <ImgBox Width="10px" Height="2px" BackgroundImage={IcoClose} MarginRight="6px"></ImgBox>
                    <TextBox Width="auto" Height="auto" FontColor="#666666" FontSize="16px" FontWeight="500" LineHeight="110%">닫기</TextBox>
                </FlexArea>
            </TextButton>
        )
    }

    // 요금제 박스
    function RatePlan(props)
    {
        return (
            <Block Width="305px" Height="465px" MarginRight={props.MarginRight} Padding="0 20px" Border="1px solid #E0E0E0">
                <TextBox Height="40px" LineHeight="110%" FontSize="36px" FontColor={props.Colored} FontWeight="600" FontFamily="Roboto" TextAlign="center" MarginTop="31px">{props.Title}</TextBox>
                <TextBox Height="25px" LineHeight="155%" FontSize="16px" FontColor="#333333" FontWeight="500" TextAlign="center" MarginTop="3px">{props.SubTitle}</TextBox>
                <TextBox Height="92px" LineHeight="110%" FontSize="83.6364px" FontColor={props.Colored} FontWeight="800" FontFamily="Roboto" TextAlign="center" MarginTop="36.14px">{props.GigaByte}GB</TextBox>
                <TextBox Height="25px" LineHeight="155%" FontSize="16px" FontColor="#999999" FontWeight="500" TextAlign="center" MarginTop="-8.14px">이미지 용량</TextBox>
                <Block Height="50px" MarginTop="43px">
                    <FlexArea Height="auto">
                        <ImgBox Width="14px" Height="12px" BackgroundImage={props.IcoCheck} MarginRight="8px"></ImgBox>
                        <TextBox Width="auto" Height="auto" FontColor="#666666" FontSize="16px" FontWeight="500" LineHeight="155%">램/DB 용량 무제한</TextBox>
                    </FlexArea>
                    <FlexArea Height="auto">
                        <ImgBox Width="14px" Height="12px" BackgroundImage={props.IcoCheck} MarginRight="8px"></ImgBox>
                        <TextBox Width="auto" Height="auto" FontColor="#666666" FontSize="16px" FontWeight="500" LineHeight="155%">트래픽 무제한</TextBox>
                    </FlexArea>
                </Block>
                <TextButton Height="40px" LineHeight="155%" FontSize="14px" FontColor="#ffffff" MarginTop="19px" BackgroundColor={props.Colored}>구매하기</TextButton>
                <FlexArea Height="auto">
                    <TextButton Width="auto" Height="auto" MarginTop="20px" onClick={buyGalClickHandler}>
                        <FlexArea Height="auto">
                            <ImgBox Width="14px" Height="12px" BackgroundImage={IcoArrow} MarginRight="8px"></ImgBox>
                            <TextBox Width="auto" Height="auto" FontColor="#979797" FontSize="14px" LineHeight="155%">더 자세히 알아보기</TextBox>
                        </FlexArea>
                    </TextButton>
                </FlexArea>
            </Block>
        )
    }

    // SI 프로젝트
    function SIProject(props)
    {
        return (
            <FlexArea Width="254px" Height="221px" FlexDirection="column" JustifyContent="space-between">
                <ImgBox Height="162px" BackgroundImage={props.ProjectImage} BackgroundSize={props.ProjectImgSize} BackgroundPosition="center center"></ImgBox>
                <TextButton Width="auto" Height="auto ">
                    <FlexArea Height="auto">
                        <ImgBox Width="29px" Height="29px" BackgroundImage={props.IconImage}></ImgBox>
                        <TextBox Width="auto" Height="auto" FontSize="24px" FontColor={props.FontColor} FontWeight="700" MarginLeft="8px">{props.Title}</TextBox>
                    </FlexArea>
                </TextButton>
            </FlexArea>
        )
    }


    return(
        <>
        <ScrollBox Width="100%" Height="100%">
            {/* {DataManager.GetUserData()?.name && isSetUser
            ?   
                <ImgBox Width={props.getSizeReturn("W",100)} Height={props.getSizeReturn("H",100)} Display="null"
                    PointerEvents="all" BackgroundImage={ImgAuthBg}>
                    <FlexArea>
                        <Block BackgroundColor="rgba(255, 255, 255, 0.5)" BorderRadius="35px"
                            MinWidth="700px" Width="54rem" MaxWidth="2800px"
                            MinHeight="455px" Height="32.4rem" MaxHeight="1820px"
                            >
                            <FlexArea>
                                <MenuBlock Width="15%" BackgroundColor="#FFFFFF" BorderRadius="35px 0px 0px 35px">
                                    <PageMainMenu logout = {logout} moveGallery = {moveGallery} setStrSelectPageName ={setStrSelectPageName}/>
                                </MenuBlock>
                                <Block Width="85%">
                                    <SelectSubPage getSizeReturn={props.getSizeReturn} strPageName={strSelectPageName}/>
                                </Block>
                            </FlexArea>
                        </Block>
                    </FlexArea>
                </ImgBox>
            :   null } */}

            {/* 로딩 팝업 */}
            {/* {isFullLoadingModal
            ?   <FullLoadingModal getSizeReturn = {props.getSizeReturn}/>
            :   null} */}
            
            {/* 상단 메뉴 */}
            <FlexArea Position="fixed" Height="100px" BackgroundColor="#ffffff" BoxShadow="0px 4px 64px rgba(0, 0, 0, 0.25)" style={{ backdropFilter: "blur(14px)" }} ZIndex="1">
                <Block Width="1280px" MinWidth="1280px">
                    <FlexArea>
                        {/* 로고 및 왼쪽 메뉴 */}
                        <Block>
                            <FlexArea Width="700px" JustifyContent="flex-start">
                                {/* <ImgBox Width="194.59px" Height="42.11px" BackgroundImage={Logo} BackgroundSize="auto" Border="1px dotted cyan"></ImgBox> */}
                                {/* <img width="194.59px" src={LogoSmall} srcSet={`${LogoSmall} 1x, ${LogoMedium} 2x, ${LogoLarge} 3x`} />  */}
                                {/* <FlexArea><TextButton Width="auto" Height="auto" onClick={() => { buyGalClickHandler("aa"); }}>갤러리구매</TextButton></FlexArea> */}
                                <ImgButton Width="195px" Height="43px" BackgroundImage={LogoSmall} onClick={buyGalClickHandler}></ImgButton>
                                <TextButton Width="auto" Height="auto" MarginLeft="50.41px" FontSize="22px" FontColor="#555555" FontWeight="500" LineHeight="28px" onClick={buyGalClickHandler}>갤러리구매</TextButton>
                                <TextButton Width="auto" Height="auto" MarginLeft="30px" FontSize="22px" FontColor="#555555" FontWeight="500" LineHeight="28px" onClick={buyGalClickHandler}>파트너갤러리</TextButton>
                                <IconTextButton Width="138px" Height="44px" MarginLeft="22px" PaddingLeft="37px" Border="1px solid #A8A8A8" BorderRadius="5px" BoxShadow="2px 2px 0px rgba(0, 0, 0, 0.06);" BackgroundImage={RandomSmall} BackgroundSize="14px 16px" BackgroundPosition="16px 13px" onClick={buyGalClickHandler}>랜덤갤러리</IconTextButton>
                            </FlexArea>
                        </Block>
                        {/* 검색 및 오른쪽 메뉴 */}
                        <Block>
                            <FlexArea JustifyContent="flex-end">
                                <SearchBox Width="275px" Height="50px" BackgroundImage={IcoSearch} MarginRight="26px">
                                </SearchBox>
                                {
                                    // CheckLoggedIn()     // 로그인이 되었는가?
                                    flagLoggedIn
                                        ?
                                            <>
                                                {/* <CommonButton onClick={logout}>
                                                    <TextBox Width="auto" Height="auto" FontSize="15px" FontColor="#333333" Padding="22px 21px" BorderRadius="5px" Border="1px solid #E0E0E0" BoxShadow="2px 13px 18px rgba(0, 0, 0, 0.1)" MarginRight="24px">
                                                        <span style={{ color: "#977D3A" }}> {userInfo?.name}({userInfo?.userId}) </span>님 환영합니다.
                                                    </TextBox>
                                                </CommonButton> */}
                                                <ImgButton Width="22px" Height="22px" MarginRight="25px" 
                                                    BackgroundImage={IcoUser} BackgroundSize="contain" 
                                                    data-tip data-for="USERtooltip"
                                                    onClick={()=>{navigate('/manage');}}
                                                    // onMouseEnter={() => showTooltip(true)}
                                                    // 툴팁 사라지게 하기
                                                    // 툴팁 사라지면 클릭이 안돼서 임시로 막음
                                                    // onMouseLeave={() =>
                                                    // {
                                                    //     showTooltip(false);
                                                    //     setTimeout(() => showTooltip(true), 50);
                                                    // }}
                                                    >
                                                </ImgButton>
                                                {tooltip && <ToolTipS id="USERtooltip" place="left" effect="solid">
                                                    <CommonButton Padding="0" onClick={logout}>
                                                        <TextBox Width="auto" Height="auto" FontSize="15px" FontColor="#333333" >
                                                            <span style={{ color: "#977D3A" }}> {userInfo?.name}({userInfo?.userId}) </span>님 환영합니다.
                                                        </TextBox>
                                                    </CommonButton>
                                                </ToolTipS>}
                                            </>
                                        :
                                            <>
                                                <TextButton Width="auto" Height="auto" MarginRight="16px" FontSize="15px" FontColor="#333333" LineHeight="19px" onClick={signupClickHandler}>회원가입</TextButton>
                                                <TextButton Width="auto" Height="auto" MarginRight="22px" FontSize="15px" FontColor="#333333" LineHeight="19px" onClick={login}>로그인</TextButton>
                                            </>
                                }
                                <ImgButton Width="23px" Height="23px" MarginRight="25px" BackgroundImage={IcoHome} BackgroundSize="contain" onClick={buyGalClickHandler}></ImgButton>
                                <ImgButton Width="25px" Height="20px" BackgroundImage={IcoMail} BackgroundSize="contain" onClick={buyGalClickHandler}></ImgButton>
                            </FlexArea>
                        </Block>
                    </FlexArea>
                </Block>
            </FlexArea>
            
            {/* 상단 카로셀 영역 */}
            {/* <FlexArea Height="613px"> */}
            <ImgBox Height="613px" BackgroundImage={BgImage} MarginTop="100px">
                <FlexArea Height="613px" >
                    <FlexArea JustifyContent="left">
                        <ImgButton Width="34px" Height="60px" MarginLeft="112.55px" BackgroundImage={IcoPrev} onClick={buyGalClickHandler}></ImgButton>
                    </FlexArea>
                    <FlexArea Width="1280px" MinWidth="1280px">
                        {/*  */}
                        {/* <ImgBox Width="419px" Height="608px" BackgroundImage={ImgGirl} BackgroundSize="contain"></ImgBox> */}
                        {/* <ImgBox Width="840px" Height="608px" MarginLeft="121px"></ImgBox> */}
                        <FlexArea FlexDirection="column" MarginLeft="587px">
                            {/* 폰트 체크할 것 */}
                            <TextBox Height="106px" LineHeight="110%" FontSize="95.961px" FontColor="#ffffff" FontWeight="800" FontFamily="Roboto">SOO<span style={{ fontWeight: "100" }}>_</span>K</TextBox>
                            <TextBox Height="57px" LineHeight="110%" FontSize="51.8732px" FontColor="#ffffff" FontWeight="300" FontFamily="Roboto" MarginTop="-13px">Illustration</TextBox>
                            <TextBox Height="44px" LineHeight="155%" FontSize="14px" FontColor="#ffffff" FontWeight="500" MarginTop="15px">독창적인 재해석을 통해 NFT ART의<br />라이징 일러스트레이터 SOO_K의 메타 전시회</TextBox>
                            <FlexArea Height="auto" JustifyContent="left" MarginTop="33px">
                                <IconTextButton 
                                    Width="143px" Height="40px" FontSize="14px" FontColor="#333333" TextAlign="center"
                                    PaddingRight="11px" Border="1px solid #ffffff" MarginRight="10px" 
                                    BackgroundImage={ArrowBlack} BackgroundSize="8px 12px" BackgroundPosition="top 13px right 36px" 
                                    onClick={buyGalClickHandler}>
                                        방문하기
                                </IconTextButton>
                                <IconTextButton
                                    Width="143px" Height="40px" FontSize="14px" FontColor="#ffffff" TextAlign="center"
                                    PaddingRight="11px" Border="1px solid #ffffff"
                                    BackgroundColor="rgba(255, 255, 255, 0.1)" 
                                    BackgroundImage={ArrowWhite} BackgroundSize="8px 12px" BackgroundPosition="top 13px right 26px"
                                    onClick={buyGalClickHandler}>
                                        구매 및 구독
                                </IconTextButton>
                            </FlexArea>
                        </FlexArea>
                    </FlexArea>
                    <FlexArea JustifyContent="right">
                        <ImgButton Width="34px" Height="60px" MarginRight="112.55px" BackgroundImage={IcoNext} onClick={buyGalClickHandler}></ImgButton>
                    </FlexArea>
                </FlexArea>
            </ImgBox>




            {/* </FlexArea> */}
            {
                flagLoggedIn
                // CheckLoggedIn()     // 로그인이 되었는가?
                ?
                    <>
                        {/* 사용자의 소유 갤러리 정보  섹션 */}
                        <FlexArea Height="auto" MarginTop="71px">
                            <Block Width="1280px" MinWidth="1280px">
                                <Block MarginBottom="13px" >
                                    <FlexArea>
                                        <TextBox FontColor="#333333" FontSize="24px"><span style={{ color: "#977D3A" }}>{userInfo?.name}</span>님의 갤러리</TextBox>
                                        <Block Width="auto">
                                            <DropdownSort optionList={optionList} Width="206px" Height="42px" TextAlign="left" FontColor="#666666" selectCallback={onClickSort}></DropdownSort>
                                        </Block>
                                    </FlexArea>
                                </Block>
                                <Block>
                                    <FlexArea JustifyContent="left">
                                        {/* 갤러리 정보들 */}
                                        { myGalInfos
                                            ? 
                                                myGalInfos.map( 
                                                    (i, idx)=>
                                                    <Gallery info={i} key={`gal${idx}`} MarginRight={idx%2==0?"14px":null} />
                                                )
                                            : // 내 소유 갤러리가 없을 때
                                            <ImgBox Height="169px" BackgroundImage={BgImageTemp} BackgroundSize="cover" BackgroundPosition="left bottom">
                                                <FlexArea FlexDirection="column" JustifyContent="top" BackgroundColor="rgba(0, 0, 0, 0.5)">
                                                    <TextBox Height="63px" LineHeight="155%" FontSize="24px" FontColor="#ffffff" FontWeight="700" TextAlign="center" MarginTop="30px">
                                                        소유한 갤러리가 없습니다.<br />
                                                        <span style={{ color: "#B3B3B3", lineHeight: "27.9px", fontSize: "18px", fontWeight: "500" }}>무료로 METAVERSE 갤러리를 소유해보세요.</span>
                                                    </TextBox>
                                                    <IconTextButton
                                                        Width="238px" Height="43px" FontSize="14px" FontColor="#ffffff" TextAlign="center"
                                                        PaddingRight="11px" Border="1px solid #ffffff" MarginTop="16px"
                                                        BackgroundColor="rgba(255, 255, 255, 0.1)"
                                                        BackgroundImage={ArrowWhite} BackgroundSize="5.5px 11px" BackgroundPosition="top 14.5px right 31.5px"
                                                        onClick={() => { setFlagShowGetFreeGal(true); }}>
                                                        무료 갤러리 신청하기
                                                    </IconTextButton>
                                                </FlexArea>
                                            </ImgBox>
                                        }
                                        {/* <Gallery info={} BackgroundImg={BgGallery01} MarginRight="14px"></Gallery>
                                        <Gallery info={} BackgroundImg={BgGallery02}></Gallery> */}
                                    </FlexArea>
                                </Block>
                                <FlexArea MarginTop="30px">
                                    <MoreButton></MoreButton>
                                    {/* <CloseButton></CloseButton> */}
                                </FlexArea>
                            </Block>
                        </FlexArea>

                        {/* 사용자가 가입한 갤러리 미니정보 섹션 */}
                        <FlexArea Height="auto" MarginTop="40px"           >
                            <Block Width="1280px" MinWidth="1280px">
                                <Block MarginBottom="13px" >
                                    <FlexArea>
                                        <TextBox FontColor="#333333" FontSize="24px">가입한 갤러리</TextBox>
                                        <Block Width="auto">
                                            <DropdownSort optionList={optionList} Width="206px" Height="42px" TextAlign="left" FontColor="#666666" selectCallback={onClickSort}></DropdownSort>
                                        </Block>
                                    </FlexArea>
                                </Block>
                                <Block>
                                    <FlexArea JustifyContent="left">
                                        {/* 갤러리 정보들 */}
                                        {joinedGalInfos
                                            ?
                                                <ReactElasticCarousel id="RECarousel" itemsToShow={5} itemsToScroll={5} style={{pointerEvents:"all"}}>
                                                {
                                                    joinedGalInfos.map(
                                                        (i, idx) =>
                                                            <GalleryMini info={i} key={`galmini${idx}`} MarginRight={idx % 5 == 4 ? "20px" : null} />
                                                    )
                                                }
                                                </ReactElasticCarousel>
                                            : // 내 소유 갤러리가 없을 때
                                                <TextBox Height="26px" LineHeight="110%" FontColor="#777777" fontSize="24px" TextAlign="center" Margin="30px 0">가입한 갤러리가 없습니다. 인기 메타버스 갤러리에 가입해보세요.</TextBox>
                                        }
                                    </FlexArea>
                                </Block>
                                <FlexArea MarginTop="30px">
                                    <MoreButton></MoreButton>
                                    {/* <CloseButton></CloseButton> */}
                                </FlexArea>
                            </Block>
                        </FlexArea>
                    </>
                : null
            }
            {/* 추천 갤러리 미니정보 섹션 */}
            <FlexArea Height="auto" MarginTop="40px"           >
                <Block Width="1280px" MinWidth="1280px">
                    <Block MarginBottom="13px" >
                        <FlexArea>
                            <TextBox FontColor="#333333" FontSize="24px">추천 갤러리</TextBox>
                            <Block Width="auto">
                                <DropdownSort optionList={optionList} Width="206px" Height="42px" TextAlign="left" FontColor="#666666" selectCallback={onClickSort}></DropdownSort>
                            </Block>
                        </FlexArea>
                    </Block>
                    <Block>
                        <FlexArea JustifyContent="left" id="FALeft">
                            {/* 갤러리 정보들 */}
                            {recommendGalInfos
                                ?
                                    <ReactElasticCarousel id="RECarousel" itemsToShow={5} itemsToScroll={5} style={{pointerEvents:"all"}}>
                                    {
                                        recommendGalInfos.map(
                                            (i, idx) =>
                                                <GalleryMini info={i} key={`galrecomm${idx}`} MarginRight={idx % 5 == 4 ? "20px" : null} />
                                        )
                                    }
                                    </ReactElasticCarousel>
                                :
                                    null
                            }
                        </FlexArea>
                    </Block>
                    <FlexArea MarginTop="30px">
                        <MoreButton></MoreButton>
                        {/* <CloseButton></CloseButton> */}
                    </FlexArea>
                </Block>
            </FlexArea>
            {/* 사용자의 갤러리 정보  섹션 */}
            {/* <FlexArea Height="auto" MarginTop="71px">
                <Block Width="1280px" MinWidth="1280px">
                    <Block MarginBottom="13px" >
                        <FlexArea>
                            <TextBox FontColor="#333333" FontSize="24px"><span style={{ color: "#977D3A" }}>ALVIN999</span>님의 갤러리</TextBox>
                            <Block Width="auto">
                                <DropdownSort optionList={optionList} Width="206px" Height="42px" TextAlign="left" FontColor="#666666" selectCallback={onClickSort}></DropdownSort>
                            </Block>
                        </FlexArea>
                    </Block>
                    <Block>
                        <FlexArea>
                            <Gallery BackgroundImg={BgGallery01} MarginRight="14px"></Gallery>
                            <Gallery BackgroundImg={BgGallery02}></Gallery>
                        </FlexArea>
                    </Block>
                    <FlexArea MarginTop="30px">
                        <MoreButton></MoreButton>
                    </FlexArea>
                </Block>
            </FlexArea> */}

            {/* 가입한 갤러리 미니정보 섹션 */}
            {/* <FlexArea Height="auto" MarginTop="40px"           >
                <Block Width="1280px" MinWidth="1280px">
                    <Block MarginBottom="13px" >
                        <FlexArea>
                            <TextBox FontColor="#333333" FontSize="24px">가입한 갤러리</TextBox>
                            <Block Width="auto">
                                <DropdownSort optionList={optionList} Width="206px" Height="42px" TextAlign="left" FontColor="#666666" selectCallback={onClickSort}></DropdownSort>
                            </Block>
                        </FlexArea>
                    </Block>
                    <Block>
                        <FlexArea>
                            <GalleryMini BackgroundImage={BgGalMini01} MarginRight="20px"></GalleryMini>
                            <GalleryMini MarginRight="20px"></GalleryMini>
                            <GalleryMini MarginRight="20px"></GalleryMini>
                            <GalleryMini MarginRight="20px"></GalleryMini>
                            <GalleryMini></GalleryMini>
                        </FlexArea>
                    </Block>
                    <FlexArea MarginTop="30px">
                        <MoreButton></MoreButton>
                    </FlexArea>
                </Block>
            </FlexArea> */}

            {/* 카로셀 소개 섹션 */}
            <Block Height="auto" MarginTop="97px">
                <TextBox Height="auto" TextAlign="center" FontColor="#777777" FontSize="24px">NETSTREAM과 함께 만든</TextBox>
                <TextBox Height="auto" TextAlign="center" FontColor="#434343" FontSize="54px" FontFamily="Roboto" FontWeight="500" MarginTop="5px" MarginBottom="20px"><span style={{ color: "#6D42C8", fontWeight: "300" }}>WEB 3.0</span> METAVERSE</TextBox>
            </Block>
            {/* 카로셀 */}
            <FlexArea Height="423px">
                <ImgBox Width="478px" MinWidth="478px" Height="423px" BackgroundImage={props.BackgroundImage} BackgroundColor="#F5F5F5" Border="1px solid #cccccc"></ImgBox>

                <ImgBox Width="478px" MinWidth="478px" Height="423px" BackgroundImage={BG001} BackgroundColor="#F5F5F5">
                    <FlexArea FlexDirection="column" JustifyContent="space-between">
                        <FlexArea FlexDirection="column" Height="auto" >
                            <FlexArea>
                                <TextBox Height="auto" FontColor="#FFFFFF" FontSize="42px" FontFamily="Roboto" FontWeight="500" Padding="31px 0 0 24px"><span style={{  fontWeight: "300" }}>WEB 3.0</span> METAVERSE</TextBox>
                            </FlexArea>
                            <FlexArea>
                                <TextBox Height="auto" FontColor="#FFFFFF" FontSize="17px" Padding="9px 0 0 24px">NETSTREAM과 함께 만든</TextBox>
                            </FlexArea>
                        </FlexArea>
                        <FlexArea Height="auto">
                            <TextBox Height="auto" FontColor="#FFFFFF" FontSize="19.6839px" FontFamily="Roboto" TextAlign="right" Padding="0 19px 16px 0">OPEN 2020.05</TextBox>
                        </FlexArea>
                    </FlexArea>
                </ImgBox>
                
                <ImgBox Width="478px" MinWidth="478px" Height="423px" BackgroundImage={props.BackgroundImage} BackgroundColor="#F5F5F5" Border="1px solid #cccccc"></ImgBox>
                <ImgBox Width="478px" MinWidth="478px" Height="423px" BackgroundImage={props.BackgroundImage} BackgroundColor="#F5F5F5" Border="1px solid #cccccc"></ImgBox>
            </FlexArea>

            {/* 요금제 소개 섹션 */}
            <Block Height="auto" MarginTop="70px">
                <TextBox Height="46px" LineHeight="110%" LetterSpacing="-0.035em" TextAlign="center" FontColor="#A0A0A0" FontSize="42px" MarginTop="5px" MarginBottom="20px">다양한 <span style={{ color: "#323232", fontWeight: "500" }}>요금제를 제공</span>하고 있습니다.</TextBox>
            </Block>
            {/* 요금제 */}
            <FlexArea Height="auto" MarginTop="21px">
                <Block Width="1280px" MinWidth="1280px">
                    <FlexArea>
                        <RatePlan Title="STANDARD" SubTitle="가볍게 시작하기" GigaByte="1" IcoCheck={IcoCheck1} Colored="#34B9D7" MarginRight="21px"></RatePlan>
                        <RatePlan Title="PRO" SubTitle="고도화된 서비스" GigaByte="2" IcoCheck={IcoCheck2} Colored="#349CD7" MarginRight="21px"></RatePlan>
                        <RatePlan Title="MASTER" SubTitle="단독 서버로 프라이빗" GigaByte="5" IcoCheck={IcoCheck3} Colored="#5B58CC" MarginRight="21px"></RatePlan>
                        <RatePlan Title="VIP" SubTitle="파트너쉽" GigaByte="10" IcoCheck={IcoCheck4} Colored="#7744CB"></RatePlan>
                    </FlexArea>
                </Block>
            </FlexArea>

            {/* 서비스 소개 섹션 */}
            <Block Height="auto" MarginTop="170px">
                <TextBox Height="45px" LineHeight="110%" TextAlign="center" FontColor="#777777" FontSize="41.3389px">NETSTREAM과 함께 만든</TextBox>
                <TextBox Height="102px" LineHeight="110%" TextAlign="center" FontColor="#434343" FontSize="93.0125px" FontFamily="Roboto" FontWeight="500" MarginTop="8.4px"><span style={{ color: "#DF50A6", fontWeight: "300" }}>WEB 3.0</span> METAVERSE</TextBox>
            </Block>
           
            {/* 서비스 소개 01 */}
            <FlexArea Height="auto" MarginTop="91.6px">
                <Block Width="1280px" MinWidth="1280px">
                    <FlexArea JustifyContent="left">
                        <ImgBox Width="563px" Height="539.55px" BackgroundImage={Adv01} BackgroundSize="contain" BackgroundPosition="left" MarginLeft="214px" ></ImgBox>
                        <Block Width="auto" MarginLeft="-38px" MarginBottom="60px">
                            <TextBox Height="26px" LineHeight="110%" FontColor="#333333" FontSize="24px" >NETSTREAM과 함께 만든</TextBox>
                            <TextBox Height="59px" LineHeight="110%" FontColor="#4B4B4B" FontSize="54px" FontFamily="Roboto" FontWeight="700" MarginTop="3px">META SPACE</TextBox>
                            <TextBox Height="44px" LineHeight="155%" FontColor="#666666" FontSize="14px" FontWeight="500" MarginTop="21px">오프라인 참여가 어려운 참관객 및 관계자를 위해 <br></br>IBK 창공 데모데이의 현장 분위글 실시간으로 느낄 수 있도록 기업부스를</TextBox>
                        </Block>
                    </FlexArea>
                </Block>
            </FlexArea>

            {/* 서비스 소개 02 */}
            <FlexArea Height="auto">
                <Block Width="1280px" MinWidth="1280px">
                    <FlexArea JustifyContent="right">
                        <Block Width="auto" MarginRight="70px">
                            <TextBox Height="26px" LineHeight="110%" FontColor="#333333" FontSize="24px" TextAlign="right">NETSTREAM과 함께 만든</TextBox>
                            <TextBox Height="59px" LineHeight="110%" FontColor="#4B4B4B" FontSize="54px" TextAlign="right" FontFamily="Roboto" FontWeight="700" MarginTop="3px">GALLERY 2.0</TextBox>
                            <TextBox Height="44px" LineHeight="155%" FontColor="#666666" FontSize="14px" TextAlign="right" FontWeight="500" MarginTop="21px">오프라인 참여가 어려운 참관객 및 관계자를 위해 <br></br>IBK 창공 데모데이의 현장 분위글 실시간으로 느낄 수 있도록 기업부스를</TextBox>
                        </Block>
                        <ImgBox Width="547px" Height="448.74px" BackgroundImage={Adv02} BackgroundSize="contain" BackgroundPosition="right" MarginTop="-27px" MarginRight="-37px"></ImgBox>
                    </FlexArea>
                </Block>
            </FlexArea>

            {/* 서비스 소개 03 */}
            <FlexArea Height="auto">
                <Block Width="1280px" MinWidth="1280px">
                    <FlexArea JustifyContent="left" AlignItems="top">
                        <ImgBox Width="727px" Height="413.5px" BackgroundImage={Adv03} BackgroundSize="contain" BackgroundPosition="left" MarginLeft="37px"></ImgBox>
                        <Block Width="auto" MarginLeft="-200px" MarginTop="37px">
                            <TextBox Height="26px" LineHeight="110%" FontColor="#333333" FontSize="24px" >NETSTREAM</TextBox>
                            <TextBox Height="59px" LineHeight="110%" FontColor="#4B4B4B" FontSize="54px" FontFamily="Roboto" FontWeight="700" MarginTop="3px">META CAD</TextBox>
                            <TextBox Height="44px" LineHeight="155%" FontColor="#666666" FontSize="14px" FontWeight="500" MarginTop="21px">오프라인 참여가 어려운 참관객 및 관계자를 위해 <br></br>IBK 창공 데모데이의 현장 분위글 실시간으로 느낄 수 있도록 기업부스를</TextBox>
                        </Block>
                    </FlexArea>
                </Block>
            </FlexArea>

            {/* 특장점 */}
            <FlexArea Height="auto" MarginTop="70px">
                <Block Width="1280px" MinWidth="1280px">
                    <TextBox Height="45px" LineHeight="110%" FontColor="#333333" FontSize="24px" FontWeight="500">특장점</TextBox>
                </Block>
            </FlexArea>
            {/* <ImgBox Height="608px" BackgroundImage={BgFeatures}> */}
            <ImgBox Height="559px" BackgroundImage={BgFeatures} BackgroundSize="cover" MarginTop="-49px">
                <FlexArea Height="559px">
                    <Block Width="1280px" MinWidth="1280px">
                        <FlexArea FlexDirection="Column" Height="auto">
                            <TextBox Height="143px" LineHeight="55.44px" FontColor="#4B4B4B" FontSize="59.29px" FontFamily="Roboto" MarginTop="89px" PaddingLeft="79px">
                                WEB BASED<br />
                                <span style={{ color: "#4B4B4B", fontSize: "90.35px", fontWeight: "700", lineHeight: "84.48px" }}>METAVERSE</span>
                            </TextBox>
                            <TextBox Height="66px" LineHeight="155%" FontColor="#666666" FontSize="14px" MarginTop="23px" PaddingLeft="79px">
                                넷스트림의 웹 기반 메타버스는 설치 없이 간편하게 메타버스 환경에<br />
                                접속할 수 있으며 PC·태블릿·모바일 등 크로스 플랫폼을<br />
                                지원하여 다양한 기기에서 운영할 수 있습니다.
                            </TextBox>
                        </FlexArea>
                    </Block>
                </FlexArea>
            </ImgBox>

            {/* SI프로젝트 섹션*/}
            <Block Height="auto" MarginTop="87px" MarginBottom="22px">
                <TextBox Height="26px" LineHeight="110%" TextAlign="center" FontColor="#333333" FontSize="24px" FontWeight="700">SI프로젝트</TextBox>
            </Block>
            <FlexArea Height="auto">
                <FlexArea JustifyContent="right">
                    <ImgButton Width="33.38px" Height="59.81px" MarginRight="30px" BackgroundImage={Prev} BackgroundSize="33.38px 59.81px" onClick={buyGalClickHandler}></ImgButton>
                </FlexArea>
                {/* 캐로셀 컨텐츠 영역 */}
                <FlexArea Width="1280px" MinWidth="1280px" JustifyContent="space-between">
                    {/* 첫번째 거 */}
                    <FlexArea Width="254px" Height="221px" FlexDirection="column" JustifyContent="space-between">
                        <ImgBox Height="162px" BackgroundImage={Img01} BackgroundSize="227px 149px" BackgroundPosition="center center"></ImgBox>
                        {/* white 아이콘은 크기가 다름 */}
                        <TextButton  Width="auto" Height="auto">
                            <FlexArea>
                                <ImgBox Width="43px" Height="43px" BackgroundImage={IcoWhite} BackgroundSize="contain"></ImgBox>
                                <TextBox Width="auto" Height="auto" FontSize="20px" FontColor="#666666" FontWeight="700" MarginBottom="2px">IBK창공</TextBox>
                            </FlexArea>
                        </TextButton>
                    </FlexArea> 
                    {/* 두번째부터 */}
                    <SIProject ProjectImage={Img02} Title="IBK창공" ProjectImgSize="182.77px 131.56px" IconImage={IcoGreen} FontColor="#CAC193"></SIProject>
                    <SIProject ProjectImage={Img03} Title="IBK창공" ProjectImgSize="253px 146px" IconImage={IcoTurquoise} FontColor="#CAC193"></SIProject>
                    <SIProject ProjectImage={Img04} Title="IBK창공" ProjectImgSize="228.07px 138.9   px" IconImage={IcoOrange} FontColor="#CAC193"></SIProject>
                    <SIProject ProjectImage={Img05} Title="IBK창공" ProjectImgSize="254px 147px" IconImage={IcoGray} FontColor="#CAC193"></SIProject>
                </FlexArea> 
                <FlexArea JustifyContent="left">
                    <ImgButton Width="33.38px" Height="59.81px" MarginLeft="30px" BackgroundImage={Next} BackgroundSize="33.38px 59.81px" onClick={buyGalClickHandler}></ImgButton>
                </FlexArea>
            </FlexArea>

            {/* Footer */}
            <FlexArea Height="257px" MarginTop="95px" BackgroundColor="#4A4A4A">
                <Block Width="1280px" MinWidth="1280px">
                    {/* border 위쪽 */}
                    <FlexArea Height="auto" JustifyContent="space-between" Padding="31px 0 22px 0">
                        <ImgBox Width="153px" Height="34px" BackgroundImage={LogoSWhite} MarginLeft="6px"></ImgBox>
                        <FlexArea Width="auto">
                            <ImgButton Width="30.15px" Height="30.15px" MarginRight="28px" BackgroundImage={Facebook} onClick={buyGalClickHandler}></ImgButton>
                            <ImgButton Width="31.9px" Height="31.9px" MarginRight="28px" BackgroundImage={Tweeter} onClick={buyGalClickHandler}></ImgButton>
                            <ImgButton Width="31.9px" Height="31.9px" MarginRight="28px" BackgroundImage={Google} onClick={buyGalClickHandler}></ImgButton>
                            <ImgButton Width="29.01px" Height="29.64px" MarginRight="34.31px" BackgroundImage={Linkedin} onClick={buyGalClickHandler}></ImgButton>
                            <DropdownF optionList={optionList} Width="180px" Height="36px" TextAlign="center" FontColor="#999999" selectCallback={onClickFamilySite}></DropdownF>
                        </FlexArea>
                    </FlexArea>
                    <Block Height="1px" BackgroundColor="#605D61"></Block>
                    {/* border 아래쪽 */}
                    <FlexArea Height="auto" AlignItems="top" MarginTop="23px">
                        <Block Width="auto">
                            <FlexArea Height="14px" JustifyContent="left" MarginLeft="-6px">
                                <TextButton Width="auto" LineHeight="20px" FontColor="#999999" FontSize="16px">이용약관</TextButton>
                                <TextBox Width="auto" LineHeight="23px" FontColor="#999999" FontSize="16px" Margin="0 8px">&#124;</TextBox>
                                <TextButton Width="auto" LineHeight="20px" FontColor="#999999" FontSize="16px">개인정보처리방침</TextButton>
                            </FlexArea>
                            <TextBox Width="387px" LineHeight="16px" FontColor="#999999" FontSize="14px" FontFamily="Roboto" MarginTop="14px">© 2022. Netstream. All Rights Reserved</TextBox>
                        </Block>
                        <Block>
                            <TextBox Width="auto" LineHeight="20.03px" FontColor="#999999" FontSize="16px">
                                사업자 등록번호   306-87-01683<br />
                                서울특별시 금천구 가산디지털2로 166(가산동), 에이스K1 타워 622호
                            </TextBox>
                            <TextBox Width="auto" LineHeight="18.75px" FontColor="#999999" FontSize="16px" FontFamily="Roboto">
                                info@netstream.co.kr<br />
                                02-6013-0603</TextBox>
                        </Block>
                    </FlexArea>
                </Block>
            </FlexArea>
        </ScrollBox>
        {
        flagShowGetFreeGal
            ?
            <ModalCreateGallery funcFlagShow={setFlagShowGetFreeGal} funcNextFlagShow={setFlagShowGotGal} funcSetURL={setUrlGotGal} getSizeReturn={props.getSizeReturn} />
            : null
        }
        {
        flagShowGotGal
            ?
            <ModalWelcome FontColor="#3F8FFF" ButtonColor="#5E9DF5"
                Title="갤러리 생성 완료"
                Text="NETSTREAM의 METAVERSE&#10;갤러리를 멋지게 완성해주세요."
                ButtonText="갤러리 이동하기"
                URL={urlGotGal}
                getSizeReturn={props.getSizeReturn}
            />
            : null
        }
        </>
    )
}

export default PageService;