export default class DataLoader
{
constructor(){}
static LoadData(_strFileURL, _funcCallBack = null, _strRequestType = "GET", _data = null, isText= false,_token = null)
{
    let xmlhttp = new XMLHttpRequest();
    let isAsync = (_funcCallBack !== null);

    //console.log("LoadData >>> ", _strFileURL, _strRequestType, _data, isText, _token);
    xmlhttp.open(_strRequestType, _strFileURL, isAsync);
    let returnData = null;

    xmlhttp.onreadystatechange = function() {
        if (xmlhttp.readyState === XMLHttpRequest.DONE)
        {
            let objData = {
                success:false,
                message:null,
                errors:xmlhttp.status,
                data:null
                }
            if(xmlhttp.status === 200 || xmlhttp.status === 400) {
                objData = isText ? xmlhttp.responseText : JSON.parse(xmlhttp.responseText);	
            }
            returnData = objData;
            if(_funcCallBack)_funcCallBack(objData);
        }
    }
    
    xmlhttp.setRequestHeader("Content-Type", "application/json","charset=UTF-8");
    xmlhttp.withCredentials = true;
    xmlhttp.send(JSON.stringify(_data));

    return returnData;
}
}