import { createGlobalStyle } from "styled-components";
import reset from "styled-reset";

const GlobalStyles = createGlobalStyle` 
    ${reset}
    a{
        text-decoration: none;
        color: inherit;
    }

    /* div{
        margin:10;
        padding:10;
        border: 1px solid #ffffff
    } */
    
    body,
    div,
    dl,
    dt,
    dd,
    ul,
    ol,
    li,
    h1,
    h2,
    h3,
    h4,
    h5,
    h6,
    pre,
    code,
    form,
    fieldset,
    legend,
    input,
    textarea,
    p,
    blockquote,
    th,
    td {
        margin:0px;
        padding:0px;
    }
    table {
        border-collapse:collapse;
        border-spacing:0;
    }
    fieldset,
    img {
        border:0;
    }
    /*
        TODO think about hanlding inheritence differently, maybe letting IE6 fail a bit...
    */
    address,
    caption,
    cite,
    code,
    dfn,
    em,
    strong,
    th,
    var {
        font-style:normal;
        font-weight:normal;
    }
    
    ol,
    ul {
        list-style:none;
    }
    
    caption,
    th {
        text-align:left;
    }
    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
        font-size:100%;
        font-weight:normal;
    }
    q:before,
    q:after {
        content:'';
    }
    abbr,
    acronym {
        border:0;
        font-variant:normal;
    }
    /* to preserve line-height and selector appearance */
    sup {
        vertical-align:text-top;
    }
    sub {
        vertical-align:text-bottom;
    }
    input,
    textarea,
    select {
        font-family:inherit;
        font-size:inherit;
        font-weight:inherit;
    }
    /*to enable resizing for IE*/
    input,
    textarea,
    select {
        *font-size:100%;
    }
    /*because legend doesn't inherit in IE */
    legend {
        color:#000;
    }
`;

export default GlobalStyles;