import Constants_ui from "../commonProj/Constants_ui";
// import PageAllNotice from "./PageAllNotice";

function SelectPMSSubPage(props) {
    console.log("PAGE : ", props)
    // if (props.strPageName === Constants_ui.PAGE_MEMBERDETAIL){
    //     return <PageMemberDetail/>;
    // } else if (props.strPageName === Constants_ui.PAGE_MEMBERMANAGEMENT){
    //     return <PageMemberManagement getSizeReturn={props.getSizeReturn}/>;
    // }
}

export default SelectPMSSubPage;