export default class Utils_ui
{
    'use strict';
    
    /**===============================================================================
    * SortJSON()        : JSON 데이터 소팅
    * @param data       : JSON 데이터
    * @param key        : attribute명(소팅기준 값)
    * @param type       : "asc","desc"
    *===============================================================================*/
    static SortJSON (data, key, type) 
    {
        if (type === undefined) type = "asc";

        return (data.sort(function (a, b) {
            let x = a[key];
            let y = b[key];
            let returnData=null;
            if (type === "desc") {
                returnData =  x > y ? -1 : x < y ? 1 : 0;
            } else if (type === "asc") {
                returnData =  x < y ? -1 : x > y ? 1 : 0;
            }

            return returnData;
        }));
    };

    /**===============================================================================
    * PercentToPx()        : full size기준으로 입력%만큼 리턴
    * @param nFullSize     : 대상 full 사이즈
    * @param nPercent      : 리턴받기 원하는 %
    * @param isAddPx       : 뒤에 "px" 붙여서 리턴 여부 결정
    *===============================================================================*/
    static PercentToPx (nFullSize, nPercent, isAddPx=true) 
    {
        let returnData = ""
        
        if(isAddPx) returnData = (nFullSize / 100)*nPercent+"px";
        else returnData = (nFullSize / 100)*nPercent;

        return returnData;
    };

    /**===============================================================================
    * ObjDeepCopy()        : 문자,obj만 들어있는 원본 Object를 복사해야 할 때 사용(함수, 정규표현식,.. 데이터 유실위험있음)
    * @param objOri        : 복사할 원본 Object
        *===============================================================================*/
    static ObjDeepCopy(objOri) {
        let objClone = {};
        for (let key in objOri) {
            if (typeof objOri[key] == "object" && objOri[key] != null) {
                objClone[key] = Utils_ui.ObjDeepCopy(objOri[key]);
            } else {
                objClone[key] = objOri[key];
            }
        }
    
        return objClone;
    };

    /**===============================================================================
    * VerifyEmailStr()     : 이메일 형식 체크 후 true/ false 반환
    * @param strEmail      : 체크할 string 값
        *===============================================================================*/
    static VerifyEmailStr(strEmail) {
        // var regExp = /^[0-9a-zA-Z]([-_.]?[0-9a-zA-Z])*@[0-9a-zA-Z]([-_.]?[0-9a-zA-Z])*.[a-zA-Z]{2,3}$/i;
        // var regExp = /^([a-z0-9_\.-]+)@([\da-z\.-]+)\.([a-z\.]{2,6})$/;
        var regExp = /(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))/;
        
        if(strEmail.match(regExp) !== null) return true;
        else return false;
    };

    /**===============================================================================
    * SecurityLevelPWStr()     : password 구조 체크 후 보안레벨 0 ~ 2 반환
    * 0: length 4이하, 1: length 4~, 영문,숫자 조합, 2: length 6~, 영문, 숫자, 대문자, 특수문자
    * 99: 한글 or 공백 있을 시
    * @param strPassword      : 체크할 password 값
        *===============================================================================*/
    static SecurityLevelPWStr(strPassword) {
        let iLev = 0;

        var regIncludStr_Min = /^(?=.*\d)(?=.*[a-zA-Z]).{4,}$/;
        var regIncludStr_Max = /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$ %^&*-]).{5,}$/;
        var regExceptStr = /[ㄱ-ㅎ|ㅏ-ㅣ|가-힣|\s]/;

        if(strPassword.match(regIncludStr_Min) !== null) iLev++;

        if(strPassword.match(regIncludStr_Max) !== null) iLev++;

        if(strPassword.match(regExceptStr) !== null) iLev=99;

        return iLev;
    };

    /**
     * byte 용량을 환산하여 반환
     * 용량의 크기에 따라 MB, KB, byte 단위로 환산함
     * @param fileSize  byte 값
     * @returns {String}
     */
     static ChangeFilebyte(fileSize) {
        var returnStr

        //MB 단위 이상일때 MB 단위로 환산
        if (fileSize >= 1024 * 1024) {
            fileSize = fileSize / (1024 * 1024);
            returnStr = Math.ceil(fileSize) + ' MB';
        }
        //KB 단위 이상일때 KB 단위로 환산
        else if (fileSize >= 1024) {
            fileSize = fileSize / 1024;
            returnStr = Math.ceil(fileSize) + ' KB';
        }
        //KB 단위보다 작을때 byte 단위로 환산
        else {
            returnStr = Math.ceil(fileSize) + ' byte';
        }
        return returnStr;
    }


    /**
     * 디자인 px값 입력값을 환산하여 clamp반환
     * @param nSize  디자인 px 값
     * @param nStandard  기준 fontSize px 값
     * @param nMultiple  최소,최대비(기본값 2)
     * @returns {String}
     */
    static GetClampSize(nSize,nStandard=25,nMultiple=2){
        let nMin = nSize/nMultiple;
        let nMax = nSize*nMultiple;
        let nRem = nSize/nStandard;

        return "clamp("+nMin+"px,"+nRem+"rem,"+nMax+"px)";
    }
};

