import { Navigate,  Outlet } from "react-router-dom";
import {GetCurrUser} from "../NSCommon/middleware/NSAuth";

function useAuth() {
    let returnData = (GetCurrUser() !== null ? true : false );
    return returnData;
}

function PrivateOutlet() {
    const auth = useAuth();
    return auth ? <Outlet /> : <Navigate to="/auth" />;
}

export default PrivateOutlet;