import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { useNavigate, useLocation } from "react-router";
import DataManager from "../../common/data/DataManager";
import { Block, ContainerBox, FlexArea, ImgBox, TextBox, TextButton } from "../commonLib/StyleMasterComp";
import ImgAuthBg from "../images/pageAuth/imb_authBg.png"
import { checkJWTValid, CheckLoggedIn, CheckLoggedInFromServer, GetCurrUser, Login, Logout } from "../NSCommon/middleware/NSAuth";
import OneBtnModal from "../modals/common/OneBtnModal";
import SignInModal from "../modals/pageAuth/SignInModal";
import { GetAccessToken } from "../NSCommon/NSUtils";
// import SignInModal from "../modals/pageAuth/SignInModal";

let CheckButton=styled(TextButton)`
    box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.1);
`;

function PageAuthServicePage(props){
    const navigate = useNavigate();
    const location= useLocation();

    const [ urlIFRAME, setUrlIFRAME ] = useState( null );
    const [ urlIFrameCMS, setUrlIFrameCMS ] = useState( null );
    const [ urlIFrameGal, setUrlIFrameGal ] = useState( null );
    const [ moveTo, setMoveTo ] = useState(window.location.origin); // 기본적으로 service page 자신에게 로그인 하는 경우를 상정

    //Auth UI데이터
    let [objAuthUIData, setObjAuthUIData] = useState(null)

    //로딩 모달
    let [isFullLoadingModal, setIsFullLoadingModal] = useState(false);

    let mounted = false;

    //UI데이터 수급
    useEffect(()=>{
        setObjAuthUIData(DataManager.GetUIDatas("auth"));
        console.log("objAuthUIData:",objAuthUIData)
    },[]);

    useEffect( () => {
        if ( mounted ) return;
        mounted = true;
        alert("PageAuthServicePage : 220810 07:00");
        // 로그인 url에 param으로 목적지의 full url을 받는다. url은 urlencoded된 상태라야 한다.
        const params = new URLSearchParams(window. location. search);
        var dest = '';
        if ( params.has('dest') )
        {
            var dest = decodeURI( params.get('dest') );
            console.log("Destination url : ", dest, decodeURI(dest));
            setMoveTo(dest); // 목적지를 moveTo에 기록
        }

        function okFunc() { afterLogin(dest); }
        function errFunc(code) 
        { 
            Logout();
            if ( code==="401" ) // 로그인이 풀린 경우 
            {
                alert('서버에서 현재 등록된 JWT를 파기함.');
            }
        }
        // 현재 service page가 로그인이 되어 있고 jwt가 valid하다면, login 이후를 진행
        if ( CheckLoggedIn() )
        {
            // ********************* spinner 띄워야 함.
            CheckLoggedInFromServer(okFunc, errFunc);
        }
            // afterLogin(dest);
    }, [])

    function move()
    {
        console.log("다음 경로로 이동 : ", moveTo);
        // window.open(moveTo, "_parent");
        window.opener.location.href = moveTo;
        if ( window.opener ) window.close();
        // navigate(moveTo); 
    }

    function toggleDoNotShowAgain()
    {

    }

    // 로그인 이후에 iframe을 만들고 요청 url에 access_token을 전송하는 부분
    // moveTo에 값이 제대로 세팅 되기 전에 들어올 수 있어 dest로 목적지를 받는 기능 추가
    function afterLogin(dest=null)
    {
        // alert('로그인 됨');
        // console.log("로그인 된 사람:",GetCurrUser());
        const baseURL = new URL(dest?dest:moveTo).origin;
        console.log("로그인이 된 상태", GetAccessToken() );
        console.log('다음 페이지를 iframe으로 : ', baseURL)
        // 로그인이 되고 나면 iframe이 활성화 되도록 한다.
        // iframe에서 리다이렉션 될 목적지의 url base를 open 하고
        // 새롭게 open된 iframe에 postMessage를 통해 access_token을 보낸다.
        // alert('iframe을 설정.');
        // setUrlIFRAME(baseURL);
        // alert('url iframe 세팅됨.' + baseURL);
        
        setUrlIFRAME(baseURL);

        const urlCMS = process.env.REACT_APP_BASE_CMS_URL;
        const urlGal = process.env.REACT_APP_BASE_GALLERY_URL;
        console.log("url : ", urlCMS, urlGal);
        setUrlIFrameCMS(urlCMS)
        setUrlIFrameGal(urlGal)

        // 로그인에 성공하면 notice를 보여 주고 main으로 넘어간다.
        // 2022/08/02 이제 로그인 되도 바로 보내지 않는다.
        // move();

    }

    //로그인 시도
    function loginStart(strInputID,strInputPW,callbackErrFunc)
    {
        // setIsFullLoadingModal(true);

        // Login(strInputID, strInputPW).then(
        

        // alert('로그인을 시도함. CheckLoggedIn ' + CheckLoggedIn());
        Login(strInputID, strInputPW,
            afterLogin ,
            statusCode => {
                console.log("로그인 실패", statusCode);
                //localStorage.setItem("user", JSON.stringify({access_token:"eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJtZW1JZCI6IjEzIiwidXNlcklkIjoiYWRtaW4iLCJlbWFpbCI6Imphc21pbkBuZXRzdHJlYW0uY28ua3IiLCJyb2xlIjozLCJuYW1lIjoi7KeA65ilIiwiaWF0IjoxNjQyNDExNjg0LCJleHAiOjE2NzM5NjkyODR9.qfz6d-bFPrY0xraDaGcYsR6DIwInuUw4tDxS1W_mIwo"}));
                // console.log(error);
                // console.log(error.response);

                switch(statusCode){
                    case 400:
                        callbackErrFunc("아이디 또는 비밀번호가 틀렸습니다.");
                        break;
                    case 404:
                        callbackErrFunc("존재하지 않는 회원입니다.");
                        break;
                    case 409:
                        callbackErrFunc("회원가입 미 승인 상태입니다.\n 관리자에게 문의해주세요.");
                        break;
                    // default:callbackErrFunc(String(error.response.data.status));
                    default:;
                }

                // setIsFullLoadingModal(false);
            }
        );
    }

    /**
     * 로그인이 완료 됐을 때 JWT를 iframe(원래 로그인 하던 subdomain)으로 넘겨 
     * 원래의 subdomain의 localStrage에 access_token을 저장하기 위해 사용.
     * 
     * iFrame의 onLoad에서 실행.
     */
    function setJWT(e)
    {
        // alert('JWT 뜸');
        console.log("setJWT : ", e);
        const targetWindow = e.target;
        // 이 함수가 실행되는 때는 이미 localStorage에 access_token이 저장 되어 있는 시점이다.
        const at = GetAccessToken();
        const params = new URLSearchParams(window.location.search);
        const msg = {
            access_token: at,
            target_url: decodeURI(moveTo)
        };
        // const redirTarget = document.getElementById('redirTarget');
        const redirTarget = e.target;
        console.log("access_token전송 : ", at, redirTarget);
        redirTarget.contentWindow.postMessage(msg, "*");
    }

    return(
        <>
        <ContainerBox>
            {/* <ImgBox Width={props.getSizeReturn("W",100)} Height={props.getSizeReturn("H",100)} BackgroundImage={ImgAuthBg}> </ImgBox> */}
            <SignInModal getSizeReturn = {props.getSizeReturn} loginStart={loginStart} objAuthUIData={objAuthUIData}/>
        </ContainerBox>
        { urlIFRAME?
        <iframe id="redirTarget" src={urlIFRAME} onLoad={setJWT} style={{height:"1px"}}></iframe>
        :null
        }
        { urlIFrameCMS ?
        <iframe id="redirTargetCMS" src={urlIFrameCMS} onLoad={setJWT} style={{height:"1px"}}></iframe>
        :null
        }
        { urlIFrameGal ?
        <iframe id="redirTargetGal" src={urlIFrameGal} onLoad={setJWT} style={{height:"1px"}}></iframe>
        :null
        }
        </>
    )
}

export default PageAuthServicePage;