export default class Constants_ui {
    static IS_TESTMODE = true;
    static TESTMODE_CHAT = false;

    static IS_SERVERTEST = true;
    static TESTSERVERURL = "http://localhost:4000/";  //서버 올리기전 로컬에서 기기 테스트용



	static PAGE_GALLERYINFO          = "PAGE_GalleryIfo";
	static PAGE_GALLERYPURCHASE     = "PAGE_GalleryPurchase";
	static PAGE_ITEMPURCHASE        = "PAGE_ItemPurchase";
	static PAGE_GALLERYMANAGEMENT   = "PAGE_GalleryManagement";
	static PAGE_LEAVESERVICE        = "PAGE_LeaveService";
}