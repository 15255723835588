import React, { useEffect, useState } from "react";
import {debounce} from "lodash";
import GlobalStyles from './commonProj/GlobalStyles';

import Utils_ui from './commonProj/Utils_ui';
import DataManager from "../common/data/DataManager";
import { AppContainerBox, Block, FlexArea, TextBox } from "./commonLib/StyleMasterComp";
import Constants_ui from "./commonProj/Constants_ui";
import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import PageAuth from "./page/PageAuth";
import PrivateOutlet from "./commonProj/PrivateOutlet";
import PageTest from "./page/PageTest";
import PageService from "./page/PageService";
import PageTest2 from "./page/PageTest2";
import PageAuthServicePage from "./page/PageAuthServicePage";
import PageSignup from "./page/PageSignup";
import ModalWelcome from "./page/ModalWelcome";
import PageFindID from "./page/PageFindID";
import PageChangePW from "./page/PageChangePW";
import PageContact from "./page/PageContact";
import PagePMS from "./page/PagePMS";
import PageMailAuthSuccess from "./page/PageMailAuthSuccess";
import ModalMailAuthFail from "./page/ModalMailAuthFail";

function App() {
  const rootElement = document.documentElement;
  const [windowSize, setWindowSize] = useState({
    Width: window.innerWidth,
    Height: window.innerHeight
  });

  //갤러리 데이터 수급
  useEffect(()=>{
    //UI 구성 데이터 수급
    console.log("DataManager.GetUIDatas()",DataManager.GetUIDatas())
  },[]);
  
  //화면 사이즈 기준 요청 퍼센트 px반환
  function getSizeReturn(strType,nPer,isAddPx=true){
    if(strType==="W") return Utils_ui.PercentToPx(windowSize.Width,nPer,isAddPx)
    else if(strType==="H") return Utils_ui.PercentToPx(windowSize.Height,nPer,isAddPx)
  }

  // 화면 사이즈 갱신
  const handleResize = debounce(() => {
  setWindowSize({Width: window.innerWidth,Height: window.innerHeight});
  }, 500);

  function getWindowDimensions() {
    const { innerWidth: width, innerHeight: height } = window
    return {width,height}
  }

  useEffect(() => {
    window.addEventListener('resize', handleResize);
    const ratioHeight = 1 / 1.78
    const ratioWidth = 1
    const { width: vw, height: vh } = getWindowDimensions();

    const width = (vw > vh)
    ? (vh * ratioWidth <= vw)
      ? (vh * ratioWidth)
      : (vw)
    : (vh * ratioHeight <= vw)
      ? (vh * ratioHeight)
      : (vw)

    const height = (vw > vh)
    ? (vw * ratioHeight <= vh)
      ? (vw * ratioHeight)
      : (vh)
    : (vw * ratioWidth <= vh)
      ? (vw * ratioWidth)
      : (vh)

    const longestSide = height > width ? height : width
    const fontSize = longestSide/37.5
    rootElement.style.fontSize = `${fontSize}px`
    console.log("fontSize",fontSize);

    return () => {
      window.removeEventListener('resize', handleResize);
    }
  }, [handleResize, rootElement.style]);

  return (
    <>
      <GlobalStyles />
      <AppContainerBox Height={Utils_ui.PercentToPx(windowSize.Height,100)} BackgroundColor="none">
        {/* {
          Constants_ui.IS_TESTMODE
          ?   <Block Position="absolute"  ZIndex="1000" BackgroundColor="rgba(0,0,0,.5)" Height="clamp(20px,1.5rem,37px)" Display="null">
                  <TextBox FontColor="white" FontSize="clamp(12px,1rem,25px)">
                      <FlexArea BackgroundColor="none">
                          TEST MODE : {windowSize.Width} , {windowSize.Height}
                      </FlexArea>
                  </TextBox>
              </Block>
          :   null
        } */}
        <BrowserRouter>
          <Routes>
            <Route path="/auth" element={
              // <PageTest getSizeReturn={getSizeReturn}/>
              <PageAuthServicePage getSizeReturn={getSizeReturn} />
            } />
            <Route path="/test" element={
              <PageTest2 getSizeReturn={getSizeReturn}/>
            } />
            {/* <Route path="/main" element={<PrivateOutlet />}>
                <Route path="" element={
                    <PageMain getSizeReturn={getSizeReturn} />
                }/>
            </Route> */}
            <Route path="/" element={
                <PageService getSizeReturn={getSizeReturn} />
            } />
            <Route path="/signup" element={
                <PageSignup getSizeReturn={getSizeReturn} />
            } />
            <Route path="/welcome" element={
              <ModalWelcome getSizeReturn={getSizeReturn} />
            } />
            <Route path="/findid" element={
              <PageFindID getSizeReturn={getSizeReturn} />
            } />
            <Route path="/changepw" element={
              <PageChangePW getSizeReturn={getSizeReturn} />
            } />
            <Route path="/contact" element={
              <PageContact getSizeReturn={getSizeReturn} />
            } />
            <Route path="/manage" element={
              <PagePMS getSizeReturn={getSizeReturn} />
            } />
            <Route path="/mail-auth-success" element={
              <ModalWelcome getSizeReturn={getSizeReturn} />
            } />
            <Route path="/mail-auth-success" element={
              <ModalMailAuthFail getSizeReturn={getSizeReturn} />
            } />
            <Route path="*" element={<Navigate to="/service" replace />} />
          </Routes>
        </BrowserRouter>
      </AppContainerBox>
    </>
  );
}

export default App;
