import React, { useState } from "react";
import {Block, BlockLabel, FlexArea, ImgBox, TextBox} from "../../commonLib/StyleMasterComp";

import Constants_ui from "../../commonProj/Constants_ui";

// import Constants_ui from "../../../commonProj/Constants_ui";

const OPTIONS = [
	{ name: "갤러리 정보", code:Constants_ui.PAGE_INFOMANAGEMENT, defaultIco:null, activeIco:null },
	{ name: "갤러리 구매", code:Constants_ui.PAGE_MEMBERMANAGEMENT, defaultIco:null, activeIco:null },
	{ name: "아이템 구매", code:Constants_ui.PAGE_ARTMANAGEMENT, defaultIco:null, activeIco:null },
	{ name: "전시관 관리", code:Constants_ui.PAGE_GALLERYMANAGEMENT, defaultIco:null, activeIco:null },
	{ name: "회원 탈퇴", code:Constants_ui.PAGE_GALLERYNOTICE, defaultIco:null, activeIco:null },
];

function PMSSelectMenuContainer(props){
    const [iSelectIndex,setISelectIndex] = useState(0);

    let onClickAction=(value)=>{
        
        setISelectIndex(parseInt(value));
        
        // console.log("code:",OPTIONS[value].code);
        props.setStrSelectPageName(OPTIONS[value].code);
    }

    return(
        <Block BackgroundColor="none">
            {OPTIONS.map((option,i) => (
                <BlockLabel Position="relative" Height={100/OPTIONS.length+"%"} key={i} PointerEvents="all" 
                    Cursor="pointer"
                    onClick={(e)=>{
                        if(e.target.value){
                            console.log("value:",e.target.value)
                            onClickAction(e.target.value) 
                        } 
                    }}>
                    <Block Display={iSelectIndex===i?"null":"none"} Position="absolute" Width="clamp(3px,0.28rem,14px)" Height="70%"
                        Transform="translateY(-50%)" Top="50%" BorderRadius="0px clamp(3px,0.28rem,14px) clamp(3px,0.28rem,14px) 0px" BackgroundColor="#743FEE"/>
                    <FlexArea Height={100/OPTIONS.length+"%"} JustifyContent="flex-start" PaddingLeft="clamp(16px,1.28rem,64px)"> 
                        <ImgBox Width="clamp(16px,1.28rem,64px)" Height="clamp(16px,1.28rem,64px)" BackgroundImage={iSelectIndex===i?option.activeIco:option.defaultIco}  />
                        <TextBox Width="none" Height="none" FontSize="clamp(10px,0.65rem,32px)" FontColor={iSelectIndex===i?"#743FEE":"#333333"}>
                            {option.name}
                        </TextBox>
                        <input style={{width:"0px",border:"none"}} type="text" name={"name"+i} id={"name"+i} 
                                        onChange ={(e)=>{}} value={i}/>
                    </FlexArea>
                </BlockLabel>
            ))}
        </Block>
    )
}

export default PMSSelectMenuContainer;