import { Block, FlexArea, ImgBox, ModalBlackBox, ModalContainerBox } from "../../commonLib/StyleMasterComp";
import Gif_Loding from "../../images/modal/gif_Loading_1.gif"

function FullLoadingModal(props){
    return(
        <ModalContainerBox>
            <ModalBlackBox BackgroundColor="rgba(255,255,255,.3)"/>
            <Block Width={props.getSizeReturn("W",100)} Height={props.getSizeReturn("H",100)}>
                <FlexArea>
                    <ImgBox Width="10rem" Height="10rem" BackgroundImage={Gif_Loding}/>
                </FlexArea>
            </Block>
        </ModalContainerBox>
    )
}

export default FullLoadingModal;