// Service 페이지용 (끝에 S 붙임)

import { CommonInput, TextButton } from "../commonLib/StyleMasterComp";
import styled from "styled-components";

export const TextInputS = styled(CommonInput)`
  text-indent: 16px;
  height: ${props => props.Height || "60px"};
  background-color: ${props => props.BackgroundColor || "#EDEDED"};
  color: ${props => props.FontColor || "#333"}; 
  font-size: ${props => props.FontSize || "16px"}; 
  font-weight: ${props => props.FontWeight || "500"};
  word-spacing: ${props => props.WordSpacing || null};
  letter-spacing: ${props => props.LetterSpacing || null};
  text-align: ${props => props.TextAlign || null};
  line-height: ${props => props.LineHeight || "20px"};
  text-decoration: ${props => props.TextDecoration || null}; 
  white-space: pre-line;
  border: ${props => props.Border || "1px solid #C2C2C2"};
  border-radius: ${props => props.BorderRadius || "10px"};
  ::placeholder {
        font-size: ${props => props.PFontSize || "16px"};
        color: ${props => props.PFontColor || "#666666"};
  }
  :focus {
      outline: none;
      border: ${props => props.Border || "1px solid #C2C2C2"};
  }
  :-webkit-autofill {
  -webkit-box-shadow: 0 0 0 1000px ${props => props.BackgroundColor || "#EDEDED"} inset;
	box-shadow: 0 0 0 1000px ${props => props.BackgroundColor || "#EDEDED"} inset;
`;

export const TextAreaS = styled.textarea`
  resize: none;
  box-sizing: border-box;
  width: ${props => props.Width || '100%'};
  min-width: ${props => props.MinWidth || null};
  max-width: ${props => props.MaxWidth || null};
  height: ${props => props.Height || "60px"};
  min-height: ${props => props.MinHeight || null};
  max-height: ${props => props.MaxHeight || null};
  line-height: ${props => props.LineHeight || "20px"};
  background-color: ${props => props.BackgroundColor || "#EDEDED"};
  color: ${props => props.FontColor || "#333"};
  font-size: ${props => props.FontSize || "16px"}; 
  font-weight: ${props => props.FontWeight || "500"};
  pointer-events: ${props => props.PointerEvents || "all"};
  font-family: ${props => props.FontFamily || 'Spoqa HanSansNeo'};
  border: ${props => props.Border || "1px solid #C2C2C2"};
  border-radius: ${props => props.BorderRadius || "10px"};
  margin: ${props => props.Margin || null};
  margin-top: ${props => props.MarginTop || null};
  margin-right: ${props => props.MarginRight || null};
  margin-bottom: ${props => props.MarginBottom || null};
  margin-left: ${props => props.MarginLeft || null};
  padding: ${props => props.Padding || null};
  padding-top: ${props => props.PaddingTop || "21px"};
  padding-right: ${props => props.PaddingRight || "15px"};
  padding-bottom: ${props => props.PaddingBottom || "20px"};
  padding-left: ${props => props.PaddingLeft || "15px"};
  ::placeholder {
      font-size: ${props => props.PFontSize || "16px"};
      color: ${props => props.PFontColor || "#666666"};
  }
  :focus {
      outline: none;
      border: ${props => props.Border || "1px solid #C2C2C2"};
  }
`;

export const DefaultButtonS = styled(TextButton)`
  height: ${props => props.Height || "72px"};
  background-color: ${props => props.BackgroundColor || "#684DF1"};
  border-radius: 10px;
  color: ${props => props.FontColor || "#FFFFFF"};
  font-size: ${props => props.FontSize || "24px"};
  line-height: ${props => props.LineHeight || "30px"};
`;

export const JoinButtonS = styled(TextButton)`
  height: ${props => props.Height || "72px"};
  background-color: #FFFFFF;
  border: 1px solid #C1B5FF;
  border-radius: 10px;
  color: ${props => props.FontColor || "#684DF1"};
  font-size: ${props => props.FontSize || "24px"};
  line-height: ${props => props.LineHeight || "30px"};
`;

// export const DisabledButtonS = styled(TextButton)`
//   height: ${props => props.Height || "72px"};
//   background-color: #DEDEDE;
//   border-radius: 10px;
//   color: ${props => props.FontColor || "#FFFFFF"};
//   font-size: ${props => props.FontSize || "24px"};
//   line-height: ${props => props.LineHeight || "30px"};
// `;

