
export function SaveStorage(key, value) {
    window.sessionStorage.setItem(key, value);
}

export function LoadStorage(key) {
    // _l(`key : ${key}`, window.sessionStorage.getItem(key));
    return window.sessionStorage.getItem(key);
}

export async function RemoveStorage(key) {
    await window.sessionStorage.removeItem(key);
}

export function SaveLocalStorage(key, value) {
    window.localStorage.setItem(key, value);
}

export function LoadLocalStorage(key) {
    // _l(`key : ${key}`, window.sessionStorage.getItem(key));
    return window.localStorage.getItem(key);
}

export async function RemoveLocalStorage(key) {
    await window.localStorage.removeItem(key);
}

export function GetAccessToken()
{
    return LoadLocalStorage('access_token');
}

export function SetAccessToken(token) 
{
    return SaveLocalStorage('access_token', token);
}

export function RemoveAccessToken() 
{
    return RemoveLocalStorage('access_token');
}

/**
 * 
 * @param {*} _d             Date이거나 혹은 날짜로 변경할 수 있는 자료
 * @param {*} flagUseDot    (안써도 됨) 출력 결과 날짜 부분에 - 대신 .을 써서 출력할 것인지 여부
 * @param {*} timeZone      (안써도 됨) 원하는 지역의 time zone (-12 ~ +12). 시간 단위
 * @returns 
 */
 export function NSDate(_d=null, flagUseDot=true, timeZone=100)
 {
     var d = null;
 
     // _d에 date가 들어왔을 경우
     if ( typeof _d===Date )  
         d = _d;
     // // _d에 문자열이 들어왔을 경우 Date로 변환할 수 있는 포맷인지 체크하고 맞다면 Date 객체로 변환
     // else if ( typeof _d===String && _d.match(/^\d{2,4}.\d{2}.\d{2,4}[ T]\d{2}:\d{2}:\d{2}/) )
     //     d = new Date(d);
     // else if ( typeof _d==='number' ) // 숫자라면
     //     d = new Date(d);
     else if ( _d )  // 조건에 따라 나누지 말고 그냥 Date 객체가 알아서 변환하게 해 주자.
         d = new Date(_d);
     else if ( _d===null ) // 안들어왔다면 지금 시간으로
         d = new Date();
 
     // 타임존을 적용하여 변환
     if ( timeZone>=-12 && timeZone<=12 )
         d = d.getTime() + timeZone*3600*1000;
     else
         d = d.getTime() - d.getTimezoneOffset()*60000; // ms로 변환하여 timezone 처리
 
     var ret = new Date(d).toISOString().split('.')[0].replace(/T/, ' ');
     // var ret = new Date(d).toISOString().match(/^\d{2,4}.\d{2}.\d{2,4}[ T]\d{2}:\d{2}:\d{2}/)[0];
     console.log("date Ret : ", ret);
     return flagUseDot?ret.replace(/-/g, "."):ret;
 }