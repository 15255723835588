import React, { useState, useEffect } from "react";
import { Block, FlexArea, ImgBox, ModalBlackBox, ModalContainerBox, TextBox, TextButton, ImgButton, BlockLabel, TextInput } from "../../commonLib/StyleMasterComp";
import { DefaultButtonS } from "../../commonLib/ServiceStyleMaster";
import styled from "styled-components";

import IcoFail from "../../images/modal/ico_fail.png"
import IcoNotice from "../../images/modal/ico_notice.png"
import Utils_ui from "../../commonProj/Utils_ui";
import { NSFetch, NSFetchRelayCode, _API_URL } from "../../NSCommon/middleware/NSCommunication";
import { useNavigate } from "react-router-dom";

import IcoCloseModal from "../../images/pageService/modal/ico_closeModal.png";
import Logo from "../../images/pageService/common/logo.png";
import { TextInputS } from "../../commonLib/ServiceStyleMaster";

let ContentOutBlock=styled(Block)`
    box-shadow: 1px 4px 15px 1px rgba(0, 0, 0, 0.25);
`;

let FlexAreaBB = styled(FlexArea)`
  box-sizing: border-box;
`;

let TextShadowButton = styled(TextButton)`
    box-shadow: 2px 2px 0px rgba(0, 0, 0, 0.06);
`;

/**
 * 무료 갤러리 제작
 * 
 * js의 alert에 해당하는 모달창 띄우기
 * 
 * props.getSizeReturn      부모 컴포넌트 사이즈를 리턴하는 함수
 * props.icoImg             아이콘 이미지
 * props.strTitle           타이틀
 * props.strDesc            내용. \n으로 개행 가능
 * props.colorBtn           버튼 색
 * props.type               스타일링 타입. 1번: 파랑, notice로. 2번: 빨강, fail
 * 
 * @param {*} props 
 * @returns 
 */
function ModalCreateGallery(props){
  const navigate = useNavigate();

  const [ type, setType ] = useState( props.type??0 ); // 타입. 2 = fail(빨강), 1 = 느낌표(Blue)
  // 기본 타입은 notice 타입
  const [ icon, setIcon ] = useState( props.isoImg??IcoNotice );
  const [ colorBtn, setColorBtn ] = useState( props.colorBtn??"#389AF2" );
  const [ colorFont, setColorFont ] = useState( props.colorFont??"white" );
  const [ strBtnOK, setStrBtnOK ] = useState( props.strBtnOK??"확인" );

  const [isAvailableName, setIsAvailableName] = useState(false);           // 갤러리 이름 중복 확인
  const [isAvailableURL, setIsAvailableURL] = useState(false);           // 갤러리 URL 중복 확인
  const [isActiveRequestBtn, setIsActiveRequestBtn] = useState(false);     // 가입신청 버튼 활성화
  const [strErrorText, setStrErrorText] = useState("이메일을 입력하지 않았습니다."); // 오류 문구

  const [ galName, setGalName ] = useState('');
  const [ galURL, setGalURL ] = useState('');

  useEffect( ()=> {
    // switch (type)
    // {
    //   case 1: // notice - 파랑
    //   case "1": // notice - 파랑
    //     setColorBtn( "#389AF2" );
    //     setColorFont( "white" );
    //     setIcon( IcoNotice );
    //     setStrBtnOK( "확인" );
    //     break;
    //   case 2: // fail - 빨강
    //   case "2": // fail - 빨강
    //     setColorBtn( "#F34336" );
    //     setColorFont( "white" );
    //     setIcon( IcoFail );
    //     setStrBtnOK( "확인" );
    //     break;
    //   default:
    //     setColorBtn( "#389AF2" );
    //     setColorFont( "white" );
    //     setIcon( IcoNotice );
    //     setStrBtnOK( "확인" );
    //     break;
    // }

  }, [type]);

  function changeHandlerName(e)
  {
    setGalName(e.target.value);
    // if(isAvailableName)
    {
      setIsAvailableName(false);
      setIsActiveRequestBtn(false);
    }
  }

  function changeHandlerURL(e)
  {
    setGalURL(e.target.value);
    // if(isAvailableURL)
    {
      setIsAvailableURL(false);
      setIsActiveRequestBtn(false);
    }
  }

  // 갤러리 이름 중복 확인
  function nameDuplicationCheck()
  {
    const _url = `${_API_URL}pms/gallery/dupli`;
    const data = {
      name: galName
      // urlPathName: galURL
    };

    function okFunc(res)
    {
      alert('사용할 수 있는 이름입니다.');
      setIsAvailableName(true);
      setStrErrorText("");
      if(isAvailableURL) setIsActiveRequestBtn(true);
    }

    function errFunc(res, code)
    {
      // console.log(res, " | ", code);

      if(code == '400') setStrErrorText("이름이 규칙에 맞지 않습니다.");
      else if(code == '409') setStrErrorText("동일한 이름의 갤러리가 존재합니다.");
    }

    NSFetchRelayCode(_url, 'POST', data, okFunc, errFunc);
  }

  // 갤러리 URL 중복 확인
  function urlDuplicationCheck()
  {
    const _url = `${_API_URL}pms/gallery/dupli`;
    const data = {
      // name: galName,
      urlPathName: galURL
    };

    function okFunc(res)
    {
      alert('사용할 수 있는 URL입니다.');
      setIsAvailableURL(true);
      setStrErrorText("");
      if (isAvailableName) setIsActiveRequestBtn(true);
    }

    function errFunc(res, code)
    {
      // console.log(res, " | ", code);

      if (code == '400') setStrErrorText("URL이 규칙에 맞지 않습니다.");
      else if (code == '409') setStrErrorText("동일한 이름의 URL이 존재합니다.");
    }

    NSFetch(_url, 'POST', data, okFunc, errFunc);
  }

  /**
   * 갤러리 신청 폼 전송
   */
  function submit()
  {
  const _url = `${_API_URL}my/gallery/free`;
      const data = {
      name: galName,
      urlPathName: galURL
    };

      function okFunc(res)
      {
        console.log("res.data.urlPathName", res.data.urlPathName);

        alert('갤러리 생성에 성공했습니다.');
        // navigate('/');

        if (props.funcFlagShow) props.funcFlagShow(false);
        if (props.funcNextFlagShow)
        {
          props.funcNextFlagShow(true);
          props.funcSetURL(res.data.urlPathName);
        }
        else
          window.location.href = process.env.REACT_APP_SERVICE_PAGE_URL_BASE;
      }

      function errFunc(res, code)
      {
          alert('갤러리 생성에 실패했습니다.')
      }

      NSFetch(_url, 'POST', data, okFunc, errFunc);
  }

  return (
    <ModalContainerBox>
      <ModalBlackBox Opacity=".4" />
      <Block Width={props.getSizeReturn("W", 100)} Height={props.getSizeReturn("H", 100)}>
        <FlexArea>
          {/* Height="472px" */}
          <ContentOutBlock Position="relative" Width="700px" Height="auto" BackgroundColor="#FFFFFF" BorderRadius="11px">
            {/* 우상단 닫기 버튼 */}
            <ImgButton Position="absolute" Width="24px" Height="24px" Right="20px" Top="20px" BackgroundImage={IcoCloseModal}
              onClick={() => { if (props.funcFlagShow) props.funcFlagShow(false); }}
            ></ImgButton>

            <FlexAreaBB FlexDirection="column" Padding="32px 67px 32px 63px">
              {/* 상단 이미지
              <Block Height="30%" BackgroundColor="none">
                <FlexArea AlignItems="flex-end">
                  <ImgBox Width="60px" Height="60px" BackgroundImage={icon} />
                </FlexArea>
              </Block> */}
              {/* <Block Height="15%" BackgroundColor="none" /> */}

              <ImgBox Width="195px" Height="43px" BackgroundImage={Logo}></ImgBox>

              {/* 오류 메시지 */}
              <Block MarginTop="26px">
                <TextBox Height="18px" LineHeight="18px" FontSize="14px" FontColor="#FF3E3E" TextAlign="center">
                  {strErrorText}
                </TextBox>
              </Block>

              {/* 희망 갤러리 이름 */}
              <Block MarginTop="24px">
                {/* <BlockLabel PointerEvents="all"  Width="100%" Height={Utils_ui.GetClampSize(55)} Border="1px solid #C2C2C2" BorderRadius={Utils_ui.GetClampSize(10)} BackgroundColor="#EDEDED"
                  MarginTop="2%"> */}
                  <TextBox Height="20px" LineHeight="20px" FontSize="16px" FontColor="#333333" MarginBottom="10px">희망하는 갤러리 이름을 입력해주세요.</TextBox>
                  <FlexArea>
                    <TextInputS PFontColor="#B5B5B5" MarginRight="8px"
                      placeholder="최대 20자 (문제가 될 시 안내 없이 수정될 수 있습니다)"
                      maxLength={20}
                      onChange={changeHandlerName}
                      value={galName}>
                    </TextInputS>
                  <TextShadowButton Width="128px" MinWidth="128px" Height="60px" LineHeight="20px" FontColor="#666666" FontWeight="500" Border="1px solid #A8A8A8;" BorderRadius="5px" onClick={nameDuplicationCheck}>중복 확인</TextShadowButton>
                    {/* <Block Width="6%">
                        <ImgBox BackgroundSize="contain" BackgroundColor="none"
                        Width={Utils_ui.GetClampSize(20)}
                        // BackgroundImage={isConfirmPWViewMode?IcoPWCheck:null}
                        ></ImgBox>
                    </Block> */}
                  </FlexArea>
                {/* </BlockLabel> */}
              </Block>

              {/* 희망 갤러리 url */}
              <Block MarginTop="24px">
              {/* <BlockLabel PointerEvents="all"  Width="100%" Height={Utils_ui.GetClampSize(55)} Border="1px solid #C2C2C2" BorderRadius={Utils_ui.GetClampSize(10)} BackgroundColor="#EDEDED"
                  MarginTop="2%"> */}
                  <TextBox Height="20px" LineHeight="20px" FontColor="#333333" MarginBottom="10px">희망하는 갤러리 URL을 입력해주세요.</TextBox>
                  <FlexArea>
                    <TextBox Width="91px" Height="20px" LineHeight="20px" FontWeight="500" MarginRight="12px">HTTP://DAY.</TextBox>
                    <TextInputS PFontColor="#B5B5B5" MarginRight="8px"
                      placeholder="영어와 숫자로만 최대 20자"
                      maxLength={20}
                      onChange={changeHandlerURL}
                      value={galURL}>
                    </TextInputS>
                    <TextShadowButton Width="128px" MinWidth="128px" Height="60px" LineHeight="20px" FontColor="#666666" FontWeight="500" Border="1px solid #A8A8A8;" BorderRadius="5px" onClick={urlDuplicationCheck}>중복 확인</TextShadowButton>
                    {/* <Block Width="6%">
                        <ImgBox BackgroundSize="contain" BackgroundColor="none"
                        Width={Utils_ui.GetClampSize(20)}
                        // BackgroundImage={isConfirmPWViewMode?IcoPWCheck:null}
                        ></ImgBox>
                    </Block> */}
                  </FlexArea>
                {/* </BlockLabel> */}
              </Block>

              {/* <Block Height="25%" BackgroundColor="none" /> */}

              {/* <Block Height="30%" BackgroundColor="none"> */}
                {/* <FlexArea> */}
                  <DefaultButtonS Width="260px" Height="60px" MarginTop="32px"
                    // BackgroundColor={colorBtn}
                    FontColor={colorFont} FontSize="20px" LineHeight="25px" FontWeight="500"
                    PointerEvents={isActiveRequestBtn? "all" : "none"}
                    BackgroundColor={isActiveRequestBtn? "#5E9DF5":"#DEDEDE"}
                    onClick={submit}>
                    신청하기
                  </DefaultButtonS>
                {/* </FlexArea> */}
              {/* </Block> */}
            </FlexAreaBB>
          </ContentOutBlock>
        </FlexArea>
      </Block>
    </ModalContainerBox>
  )
}

export default ModalCreateGallery;