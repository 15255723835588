import React, { useState } from "react";
import { Block, FlexArea, ImgBox, ImgButton, TextBox, TextButton } from "../../commonLib/StyleMasterComp";

import DataManager from "../../../common/data/DataManager";

import Logout from "../../images/pageMain/pageMainMenu/ico_logout.png"

import IcoMoveGalleryArrow from "../../images/pageMain/pageMainMenu/icoMoveGalleryArrow.png"

import styled from "styled-components";
import PMSSelectMenuContainer from "./PMSSelectMenuContainer";
import ModalCreateGallery from "./ModalCreateGallery";

let MoveGalleryBtn = styled(TextButton)`
    background: linear-gradient(90deg, #7439EE 0%, #6D94E4 100%);
`;


function PMSNavBar(props){

    const [ flagShowGetFreeGal, setFlagShowGetFreeGal ] = useState(false );
    // const {/getSizeReturn} = useContext(ObjCommonContext);

    return(
        <>
        <Block>
            {/* 여백 */}
            <Block Height="8.7%" BackgroundColor="none"/>

            <Block Height="10%" BackgroundColor="none">
                <TextButton onClick={()=>{setFlagShowGetFreeGal(true);}}>무료갤러리 신청</TextButton>
            </Block>
            
            {/* 페이지 메뉴 */}
            <Block Height="54.6%" BackgroundColor="none">
                <PMSSelectMenuContainer setStrSelectPageName={props.setStrSelectPageName}/>
            </Block>
            
            {/* 여백 */}
            <Block Height="22.9%" BackgroundColor="none"/>
            
            {/* 하단 정보 */}
            <Block Height="13.8%" BackgroundColor="none">
                <Block Height="20%" BackgroundColor="none">
                    {/* <TextButton BackgroundColor="green" FontSize="10px">관리자 문의하기</TextButton> */}
                </Block>
                <FlexArea Height="60%" FlexDirection="column" JustifyContent="space-around" PaddingLeft="clamp(17px,1rem,50px)">
                    {/* <TextBox Height="30%" FontSize="clamp(10px,0.65rem,32px)" FontColor="#333333">{DataManager.GetUserData().name} 님</TextBox> */}
                    <TextBox Height="30%" FontSize="clamp(10px,0.65rem,32px)" FontColor="#333333">{"아무개"} 님</TextBox>
                    {/* 로그아웃 */}
                    <FlexArea Height="30%" JustifyContent="flex-start">
                        <ImgButton Width="clamp(10px,0.65rem,32px)" Height="clamp(10px,0.65rem,32px)" BackgroundImage={Logout}/>
                        <TextButton Width="none" FontSize="clamp(10px,0.46rem,28px)" onClick={()=>{
                                props.logout();
                            }}>
                            logout
                        </TextButton>
                    </FlexArea>
                    <TextBox Height="30%" FontColor="#828282" FontSize="clamp(10px,0.4rem,20px)">Copyrightⓒ2022 by NETSTREAM</TextBox>
                </FlexArea>
                <Block Height="20%" BackgroundColor="none"/>
            </Block>
        </Block>
        {
            flagShowGetFreeGal
            ?
                <ModalCreateGallery funcFlagShow={setFlagShowGetFreeGal} getSizeReturn={props.getSizeReturn}/>
            : null
        }
        </>
    )
}

export default PMSNavBar;