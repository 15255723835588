/**
@Class          : PageSignup.js
@Maker          : Minnie
@Date           : 2022-08-08
@Version        : 1.0
@Description    : 회원가입 페이지
 */
import React, {useState} from "react";
import { Block, BlockLabel, ContainerBox, FlexArea, ImgBox, ImgButton, TextBox, TextButton, TextInput } from "../commonLib/StyleMasterComp";
import styled from "styled-components";
import { useNavigate } from "react-router-dom";

import Utils_ui from "../commonProj/Utils_ui";
import IcoPWViewOff from "../images/pageSignup/ico_eye1.png"
import IcoPWViewOn from "../images/pageSignup/ico_eye2.png"
import IcoPWCheck from "../images/pageSignup/ico_check.png"
import IcoCheck from "../images/pageSignup/ico_checkbox.png"
import { NSFetch, _API_URL } from "../NSCommon/middleware/NSCommunication";
import PMSNavBar from "../components/pagePMS/PMSNavBar";
import FullLoadingModal from "../modals/common/FullLoadingModal";
import SelectPMSSubPage from "./SelectPMSSubPage";

let BoldSpan=styled.span`
    font-weight: 600;
`;

let MenuBlock=styled(Block)`
    box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.25);
`;

function PagePMS(props){
    const navigate = useNavigate();


    const [strErrorText,setStrErrorText] = useState("이메일을 입력하지 않았습니다."); // 오류 문구
    const [ strSelectPageName, setStrSelectPageName ] = useState( "" );

    const [ isFullLoadingModal, setIsFullLoadingModal ] = useState( false );

    /**
     * 회원 가입 폼 전송
     */
    function submit()
    {
        const _url = `${_API_URL}auth/signup`;
        const data = {
            }

        function okFunc(res)
        {
            alert('회원 가입에 성공했습니다.');
            window.location.href=res.data.url;
        }

        function errFunc(res, code)
        {
            alert('회원 가입에 실패했습니다.')
        }

        NSFetch(_url, 'POST', data, okFunc, errFunc)
    }

    function move()
    {
        alert('클릭');
        navigate('/');
    }

    return(
        <ContainerBox>
        {/* {DataManager.GetUserData()?.name && isSetUser */}
        <ImgBox Width={props.getSizeReturn("W",100)} Height={props.getSizeReturn("H",100)} Display="null"
            PointerEvents="all" BackgroundImage={""}>
            <FlexArea>
                <Block BackgroundColor="rgba(255, 255, 255, 0.5)" BorderRadius="35px"
                    MinWidth="700px" Width="54rem" MaxWidth="2800px"
                    MinHeight="455px" Height="32.4rem" MaxHeight="1820px"
                    >
                    <FlexArea>
                        <MenuBlock Width="15%" BackgroundColor="#FFFFFF" BorderRadius="35px 0px 0px 35px">
                            <PMSNavBar setStrSelectPageName={setStrSelectPageName} getSizeReturn={props.getSizeReturn} />
                        </MenuBlock>
                        <Block Width="85%">
                            <SelectPMSSubPage getSizeReturn={props.getSizeReturn} strPageName={strSelectPageName}/>
                        </Block>
                    </FlexArea>
                </Block>
            </FlexArea>
        </ImgBox>

        {/* 로딩 팝업 */}
        {isFullLoadingModal
        ?   <FullLoadingModal getSizeReturn = {props.getSizeReturn}/>
        :   null}
    </ContainerBox>
    )
}

export default PagePMS;