import React from "react";
import { Block, CommonButton, ContainerBox, FlexArea, TextButton } from "../commonLib/StyleMasterComp";
import styled from "styled-components";
import { useNavigate } from "react-router-dom";

let SpacialTextButton=styled(TextButton)`
    background: radial-gradient(50% 50% at 50% 50%, #D9D9D9 0%, rgba(217, 217, 217, 0) 100%);
`;

function PageMailAuthSuccess(props){
    const navigate = useNavigate();

    return(
        <Block>
            <FlexArea>
                <Block Width={props.getSizeReturn("W",20)} Height="100%" BackgroundColor="yellow"></Block>
                <Block Width="80%" Height="100%" BackgroundColor="green">
                    <FlexArea FlexDirection="column">
                        <Block Height="10%" BackgroundColor="magenta">회원 가입 메일 인증후 도착하는 페이지 입니다. 디자인이 필요합니다.</Block>
                        <Block Height="10%" BackgroundColor="magenta">
                            <CommonButton onClick={()=>{navigate('/')}}>
                                <a>
                                    이 링크를 눌러 시작하세요
                                </a>
                            </CommonButton> 
                        </Block>
                        <Block Height="80%" BackgroundColor="magenta" />
                    </FlexArea>
                </Block>
            </FlexArea>
        </Block>
    )
}

export default PageMailAuthSuccess;