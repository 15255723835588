import React from 'react';
import { createRoot } from 'react-dom/client';
import App from './ui/App';
import "./css/font.css";
import WebFont from "webfontloader";
import DataManager from "./common/data/DataManager";
import Constants from './common/Constants';

// import dotenv from 'dotenv';
// import reportWebVitals from './reportWebVitals';

const root = createRoot(document.getElementById('root'));

window.addEventListener('load', function () {
  window.removeEventListener('load', this);
  // dotenv.config();
  Constants.DEBUG_MODE = process.env.REACT_APP_DDEVELOP_MODE === "TRUE";
  if(!Constants.DEBUG_MODE) window['console']['log'] = function() {};
  Init();
});

const Init =() =>{
  console.log("::: FONT LOAD START :::");
  WebFont.load({
    custom: {
    families: ['Spoqa HanSansNeo', 'SEBANG_Gothic_Regular']
    },
    fontactive: (name, fvd) => {
        console.log(`::: LOADING FONT ${name}:${fvd} :::`);
    },
    active: () => {
        console.log("::: ALL FONTS LOADED :::");
        startApp();
    },
    
    inactive: () => {
        console.error("::: ERROR LOADING FONTS :::");
        // REPORT ERROR here
    },
  });
}

const  startApp = async () =>{
  console.log("::: START APP ::::");
  if( /Android/i.test(navigator.userAgent)) {
      Constants.OS_PLATFORM = Constants.OS_ANDROID;
  }else if (/iPhone|iPad|iPod/i.test(navigator.userAgent)) {
      Constants.OS_PLATFORM = Constants.OS_IOS;
  }else {
      Constants.OS_PLATFORM = Constants.OS_ECT;
  }

  DataManager.GetInstance();
  DataManager.LoadData(onDataLoadComplete, onDataLoadFail);
}

const onDataLoadFail = (_err) =>{
  console.log("::: DATALOAD FAIL ::: ", _err);
}

const onDataLoadComplete = () =>{
  console.log("::: DATALOAD COMPLETE ::: ");
  reactStart();
}

const reactStart = ()=>{
  root.render(
    <React.StrictMode>
      <App />
    </React.StrictMode>,
  );
}

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
