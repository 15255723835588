/**
 @Class          : DataManager
 @Maker          : Park Junghoon
 @Date           : 2022-03-05
 @Debug DATE     : 
 @Debug Contents : -
 @Version        : 1.0
 @Description    : 데이터 관리 클래스
                   데이터 로드및 관리, 싱글톤
 */

import Constants from "../Constants.js";
import DataLoader from "../DataLoader.js";

export default class DataManager
{
    static DATA_LOAD_COMPLETE = "Data_Load_Complete" // 데이터 로드 완료 이벤트

    static m_objInit;                   // 객체 생성 확인용
    static m_dataManager;               // 인스턴스 객체
    static m_objUserData = null;        // 사용자 데이터
    static m_arrUIDatas = null;         // UI 설정 데이터 배열

    /**
    *===============================================================================
    * constructor()      : 
    * @param _objInit   : 싱글톤 생성 확인용 객체
    *===============================================================================*/
    constructor(_objInit)
    {
        //super();

        if (_objInit !== DataManager.m_objInit) 
        {
            throw new Error( "DataManager 생성은 다음과 같이 해주세요 - DataManager.GetInstance()" ); 
           // return;
        }

    }
    
    /**
    *===============================================================================
    * GetInstance()      : 
    * @return            : 커뮤니케이터 인스턴스 리턴
    *===============================================================================*/
    static GetInstance()
    {
        if(DataManager.m_dataManager == null) 
        {
            DataManager.m_dataManager = new DataManager(DataManager.m_objInit);
            DataManager.m_objInit = {};
        }
        return DataManager.m_dataManager;
    }

    /**
    *===============================================================================
    * dispose()         : 
    *===============================================================================*/
    static dispose(){
        DataManager.m_arrCompanyDatas = null;
        DataManager.m_objInit = null;
        DataManager.m_dataManager = null;
    }

    /**
    *===============================================================================
    * reciveEvent()                 : 이벤트 수신
    * @param _funcCallBack          : 데이터 로드 성공 콜벡함수
    * @param _funcCallBackError     : 데이터 로드 실패 콜벡함수
    *===============================================================================*/
    static async LoadData(_funcCallBack, _funcCallBackError)
    {
        if(DataManager.m_dataManager === null) DataManager.GetInstance();
        DataManager.m_funcCallBack = _funcCallBack;
        
        try{
            //서버 URL 정보 로드
            Constants.BASE_URL = process.env.REACT_APP_BASE_SERVER_URL;
            Constants.MULTI_SERVER_URL.url = process.env.REACT_APP_MULTI_SERVER_URL;    
            Constants.MULTI_SERVER_URL.port = process.env.REACT_APP_MULTI_SERVER_PORT;
            
            //데이터 로드 필요할 경우 이하 코딩


            
            //UI 초기 설정 데이터 로드
            console.log("Constants.UI_DATA_URL",Constants.UI_DATA_URL)
            let objUIData = await DataLoader.LoadData(Constants.UI_DATA_URL);
            DataManager.m_objUIDatas = objUIData.data;

            _funcCallBack();

        }catch(_err){
            _funcCallBackError(_err);
        }
    }
    
    /**===============================================================================
    * SetUserData()         : 사용자 데이터 설정
    * @param _objUserData   : 사용자 데이터
    *===============================================================================*/
    static SetUserData(_objUserData){
        DataManager.m_objUserData = _objUserData;
    }
    
    /**===============================================================================
    * GetUserData()     : 사용자 데이터 게터
    * @return           : 사용자 데이터 리턴
    *===============================================================================*/
    static GetUserData(){
        return DataManager.m_objUserData;
    }
    
    
    /**===============================================================================
    * GetGalleryUIDatas()   : UI 설정 데이터
    * @param _strPage   : page이름
    * @return        : 요청한 page UI 설정 데이터
    *===============================================================================*/
    static GetUIDatas(_strPage=""){

        let objDataReturn = null;

        if(DataManager.m_objUIDatas !== null){
            switch(_strPage){
                case "lang":
                    objDataReturn = DataManager.m_objUIDatas.language;
                break;
                case "land":
                    objDataReturn = DataManager.m_objUIDatas.landing;
                break;
                case "auth":
                    objDataReturn = DataManager.m_objUIDatas.auth;
                break;
                case "my":
                    objDataReturn = DataManager.m_objUIDatas.mypage;
                break;
                default:
                    objDataReturn = DataManager.m_objUIDatas;
            }
        }

        return objDataReturn;
    }

};

