import React, { useState, useEffect } from "react";
import { Block, FlexArea, ImgBox, ModalBlackBox, ModalContainerBox, TextBox, TextButton } from "../../commonLib/StyleMasterComp";
import styled from "styled-components";

import IcoFail from "../../images/modal/ico_fail.png"
import IcoNotice from "../../images/modal/ico_notice.png"

let ContentOutBlock=styled(Block)`
    box-shadow: 1px 4px 15px 1px rgba(0, 0, 0, 0.25);
`;

/**
 * One BtnModal
 * 
 * js의 alert에 해당하는 모달창 띄우기
 * 
 * props.getSizeReturn      부모 컴포넌트 사이즈를 리턴하는 함수
 * props.icoImg             아이콘 이미지
 * props.strTitle           타이틀
 * props.strDesc            내용. \n으로 개행 가능
 * props.colorBtn           버튼 색
 * props.type               스타일링 타입. 1번: 파랑, notice로. 2번: 빨강, fail
 * 
 * @param {*} props 
 * @returns 
 */
function OneBtnModal(props){
  const [ type, setType ] = useState( props.type??0 ); // 타입. 2 = fail(빨강), 1 = 느낌표(Blue)
  // 기본 타입은 notice 타입
  const [ icon, setIcon ] = useState( props.isoImg??IcoNotice );
  const [ colorBtn, setColorBtn ] = useState( props.colorBtn??"#389AF2" );
  const [ colorFont, setColorFont ] = useState( props.colorFont??"white" );
  const [ strBtnOK, setStrBtnOK ] = useState( props.strBtnOK??"확인" );

  useEffect( ()=> {
    switch (type)
    {
      case 1: // notice - 파랑
      case "1": // notice - 파랑
        setColorBtn( "#389AF2" );
        setColorFont( "white" );
        setIcon( IcoNotice );
        setStrBtnOK( "확인" );
        break;
      case 2: // fail - 빨강
      case "2": // fail - 빨강
        setColorBtn( "#F34336" );
        setColorFont( "white" );
        setIcon( IcoFail );
        setStrBtnOK( "확인" );
        break;
      default:
        setColorBtn( "#389AF2" );
        setColorFont( "white" );
        setIcon( IcoNotice );
        setStrBtnOK( "확인" );
        break;
    }

  }, [type]);


  return (
    <ModalContainerBox Position="relative">
      <ModalBlackBox Opacity=".4" />
      <Block Width={props.getSizeReturn("W", 100)} Height={props.getSizeReturn("H", 100)}
        Position="relative" BackgroundColor="none">
        <FlexArea BackgroundColor="none">
          <ContentOutBlock Width="400px" Height="270px" BackgroundColor="white" BorderRadius="15px">
            <Block Height="30%" BackgroundColor="none">
              <FlexArea AlignItems="flex-end">
                <ImgBox Width="60px" Height="60px" BackgroundImage={icon} />
              </FlexArea>
            </Block>
            <Block Height="15%" BackgroundColor="none">
              <FlexArea>
                <TextBox Width="none" Height="none"
                  FontColor="#333333" FontSize="18px" FontWeight="500">
                  {props.strTitle}
                </TextBox>
              </FlexArea>
            </Block>
            <Block Height="25%" BackgroundColor="none">
              <FlexArea>
                <TextBox Width="none" Height="none" TextAlign="center" PaddingLeft="10px" PaddingRight="10px"
                  FontColor="#666666" FontSize="14px" FontWeight="400" LineHeight="150%">
                  {props.strDesc}
                </TextBox>
              </FlexArea>
            </Block>
            <Block Height="30%" BackgroundColor="none">
              <FlexArea>
                {/* <TextButton Width="152px" Height="40px" BackgroundColor={props.colorBtn ? props.colorBtn : "#F34336"} BorderRadius="50px"
                  FontColor={props.colorFont ? props.colorFont : "white"} FontSize="16px" FontWeight="500" */}
                <TextButton Width="152px" Height="40px" BackgroundColor={colorBtn} BorderRadius="50px"
                  FontColor={colorFont} FontSize="16px" FontWeight="500"
                  onClick={() => {
                    props.setIsClose(false);
                    if (props.funcCallback) 
                    {
                      console.log("콜 폴백쓰");
                      props.funcCallback();
                    }
                  }}>
                  {/* {props.strBtnOk ? props.strBtnOk : "확인"} */}
                  {strBtnOK}
                </TextButton>
              </FlexArea>
            </Block>
          </ContentOutBlock>
        </FlexArea>
      </Block>
    </ModalContainerBox>
  )
}

export default OneBtnModal;