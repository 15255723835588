import React from "react";
import { Block, CommonButton, ContainerBox, FlexArea, TextButton } from "../commonLib/StyleMasterComp";
import styled from "styled-components";
import { useNavigate } from "react-router-dom";

function PageTest2(props){
    const navigate = useNavigate();

    function move()
    {
        alert('클릭');
        navigate('/');
    }

    return(
        <Block >
            <CommonButton onClick={move}>
                홀롤로로로로로로로로
            </CommonButton>
        </Block>
    )
}

export default PageTest2;