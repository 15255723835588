import React, { useEffect, useState } from "react";
import { Block, FlexArea, ImgButton, ModalBlackBox, ModalContainerBox, TextBox, TextButton, TextInput } from "../../commonLib/StyleMasterComp";
import { TextInputS, DefaultButtonS, JoinButtonS } from "../../commonLib/ServiceStyleMaster";
import styled from "styled-components";

import IcoQuit from "../../images/modal/pageAuth/ico_signInQuit.png"
// import IcoPWViewOff from "../../images/modal/pageAuth/ico_eye1.png"
// import IcoPWViewOn from "../../images/modal/pageAuth/ico_eye2.png"
import UIDataManager from "../../../common/data/UIDataManager";
import LoginConfirmRules from "./components/LoginConfirmRules";

import IcoPWViewOff from "../../images/modal/pageAuth/ico_eyeOff.png"
import IcoPWViewOn from "../../images/modal/pageAuth/ico_eyeOn.png"
import { useNavigate } from "react-router-dom";

// let ContentOutBlock=styled(Block)`
//     box-shadow: 1px 4px 15px 1px rgba(0, 0, 0, 0.25);
// `;

// let LoginImgDecoArea=styled(Block)`
//     overflow: hidden;
// `;

// let SignUpBtn = styled(TextButton)`
//     box-shadow: 0px 3px 8px rgba(0, 0, 0, 0.15);
// `;

// let LoginBtn = styled(TextButton)`
//     background: linear-gradient(90deg, #7439EE 0%, #6D94E4 100%);
//     box-shadow: 0px 3px 8px rgba(0, 0, 0, 0.15);
// `;

// let InputBox = styled(TextInput)`
//     border-bottom: 1px solid #A7A7A7;
//     :focus {
//         border-bottom: 1px solid #A7A7A7;
//     }
// `;

function SignInModal(props){
    const [isPWViewMode, setIsPWViewMode] = useState(false);  // 비밀번호 보기
    const [objValues, setObjValues] = useState({});
    const [objErrors, setObjErrors] = useState({});     // 오류 메시지
    // const [flagIDPWOK, setFlagIDPWOK] = useState(false);     // 오류 메시지
    const [id, setID] = useState(null);
    const [pw, setPW] = useState(null);
    const [isActiveRequestBtn, setIsActiveRequestBtn] = useState(false);     // 가입신청 버튼 활성화

    const navigate = useNavigate();

    // ID 변경
    const handleChangeID = _ev => {
        console.log("ID 변경 handleChange", _ev.target.value);
        setID( _ev.target.value );
    }

    // PW 변경
    const handleChangePW = _ev => {
        console.log("PW 변경 handleChange", _ev.target.value);
        setPW( _ev.target.value );
    }

    // 로그인 시도시 UIDataManager에 자료 입력 및 문제 없는지 체크
    const checkAll = () => {
        // console.log("handleChange",_ev.target);

        // _ev.persist();
        console.log("-- 데이터 매니저 데이터 세팅");
        UIDataManager.SetLoginInputData({name: "email", value: id});
        UIDataManager.SetLoginInputData({name: "password", value: pw});
        console.log("-- GetLoginInputData",UIDataManager.GetLoginInputData());
        // setObjValues(UIDataManager.GetLoginInputData());
        // console.log("ObjectValues : ", objValues);
        const objValues = UIDataManager.GetLoginInputData();
        const retRule = LoginConfirmRules(objValues);
        setObjErrors(retRule);
        // ID/PW가 입력될 때 오류가 없다면 flag를 true로 둔다.
        if (Object.keys(retRule).length === 0)
            return true;
            // setFlagIDPWOK(true);
        else
            return false;
            // setFlagIDPWOK(false);
    };

    const handleSubmitErr = (_strErrMsg) => {
        console.log("handleSubmitErr:",_strErrMsg);
        setObjErrors({serverErrMsg:_strErrMsg});
    };

    const handleSubmit = _ev => {
        const flagIDPWOK = checkAll();
        console.log("handleSubmit:",_ev, flagIDPWOK);
        if (_ev) _ev.preventDefault();
        // if (Object.keys(objErrors).length === 0)
        if ( flagIDPWOK )
        {
            console.log("로그인 실행!")
            const objValues = UIDataManager.GetLoginInputData();
            props.loginStart(objValues.email, objValues.password, handleSubmitErr);
        }
    };

    useEffect(()=>{
        return()=>{
            UIDataManager.SetLoginInputData();
        }
    },[])

    return(
        <FlexArea>
            <Block Width="440px" MinWidth="440px">
                {/* <FlexArea > */}
                    {/* <Block Border="1px solid blue"> */}
                        {/* 타이틀 */}
                        <Block MarginTop="55px">
                            {/* <FlexArea FlexDirection="column"> */}
                                <TextBox Height="65px" FontSize="52px" LineHeight="65px" FontWeight="500" TextAlign="center">
                                    로그인
                                </TextBox>
                                {/* <TextBox Width="none" Height="none" FontColor="#666666" FontSize="clamp(10px,.48rem,24px)" FontWeight="200">
                                    관리자 페이지 로그인
                                </TextBox> */}
                            {/* </FlexArea> */}
                        </Block>

                        {/* 오류문구 */}
                        {/* <Block MarginTop="8px">
                            <TextBox Height="21px" FontSize="14px" FontColor="#F34336">
                                <FlexArea>
                                    아이디 혹은 비밀번호가 틀렸습니다.
                                    {Object.keys(objErrors) &&
                                        objErrors[Object.keys(objErrors)[0]]
                                    }
                                </FlexArea>
                            </TextBox>
                        </Block> */}
                        <Block MarginTop="8px">
                            <TextBox Height="21px" FontSize="14px" FontColor="#F34336" TextAlign="center">
                                {/* <FlexArea> */}
                                    {/* 아이디 혹은 비밀번호가 틀렸습니다. */}
                                    {Object.keys(objErrors) &&
                                        objErrors[Object.keys(objErrors)[0]]
                                    }
                                {/* </FlexArea> */}
                            </TextBox>
                        </Block>

                        {/* ID/PW */}
                        <form style={{ }} onSubmit={handleSubmit} noValidate>
                            {/* <Block> */}
                                {/* <FlexArea FlexDirection="column" JustifyContent="space-around"> */}
                                    {/* <Block> */}
                                        {/* 아이디 - email */}
                                        <FlexArea Position="relative" MarginTop="32px">
                                            <TextInputS
                                                autoComplete="off"
                                                placeholder="이메일 주소를 입력하세요."
                                                maxLength={256}
                                                type="email" name="email"
                                                onChange={handleChangeID}
                                                // value={objValues.email || ""}
                                                value={id || ""}
                                                required
                                            />
                                        </FlexArea>
                                    {/* </Block> */}

                                    {/* <Block Height="25%" Position="relative" BackgroundColor="none" > */}
                                        {/* 비밀번호 */}
                                        <FlexArea Position="relative" MarginTop="10px">
                                            <TextInputS
                                                autoComplete="new-password"
                                                placeholder="비밀번호를 입력하세요."
                                                maxLength={20}
                                                type={isPWViewMode?"text":"password"} name="password"
                                                onChange={handleChangePW}
                                                // value={objValues.password || ""}
                                                value={pw || ""}
                                                required
                                            />
                                            <ImgButton Position="absolute" 
                                                Width="17px" Height="16px"
                                                Top="22px" Right="16px"
                                                BackgroundImage={isPWViewMode?IcoPWViewOff:IcoPWViewOn}
                                                type="button"
                                                onClick={()=>{
                                                    setIsPWViewMode(!isPWViewMode);
                                                }}
                                            />
                                        </FlexArea>
                                    {/* </Block> */}

                                    {/* 회원가입, 문의 */}
                                    {/* <Block Height="25%" BackgroundColor="none">
                                        <FlexArea FlexDirection="column">
                                            <TextBox Width="null" Height="null" FontColor="#666666" TextAlign="center" LineHeight="clamp(12px,.6rem,48px)" FontSize="clamp(8px,.48rem,24px)" >
                                                {props.objAuthUIData?.notice_main_txt.KO}
                                            </TextBox>
                                            <TextBox Width="null" Height="null" FontColor="#999999" FontWeight="200" TextAlign="center"  LineHeight="clamp(12px,.6rem,48px)" FontSize="clamp(8px,.44rem,24px)" >
                                                {props.objAuthUIData?.notice_sub_txt.KO}
                                            </TextBox>
                                        </FlexArea>
                                    </Block> */}

                                {/* </FlexArea> */}
                            {/* </Block> */}

                            {/* 아이디/비밀번호 문의 */}
                            <FlexArea MarginTop="12px">
                                <TextButton Width="auto" Height="18px" FontSize="14px" LineHeight="18px" FontColor="#333333" FontWeight="500" onClick={(e) =>
                                {
                                    e.stopPropagation();
                                    e.preventDefault();
                                    navigate("/findid");

                                }}>
                                    아이디 찾기 &#62;
                                </TextButton>
                                <TextButton Width="auto" Height="18px" FontSize="14px" LineHeight="18px" FontColor="#333333" FontWeight="500" onClick={(e) =>
                                {
                                    e.stopPropagation();
                                    e.preventDefault();
                                    navigate("/changepw");

                                }}>
                                    비밀번호 재설정 &#62;
                                </TextButton>
                            </FlexArea>

                            {/* 로그인버튼 */}
                            {/* <Block Height="20%" BackgroundColor="none"> */}
                                <FlexArea>
                                    <DefaultButtonS MarginTop="39px"
                                        // PointerEvents={isActiveRequestBtn? "all" : "none"}
                                        // BackgroundColor={isActiveRequestBtn? "#684DF1":"#DEDEDE"}
                                    >
                                        로그인
                                    </DefaultButtonS>
                                </FlexArea>
                            {/* </Block> */}    
                        </form>

                        {/* 회원가입버튼 */}
                        {/* <Block Height="20%" BackgroundColor="none"> */}
                        <FlexArea>
                            <JoinButtonS MarginTop="16px" onClick={() =>
                            {
                                // alert("snfma");
                                // window.location.href="/signup";
                                navigate("/signup");

                            }}>
                                회원가입
                            </JoinButtonS>
                        </FlexArea>
                        {/* </Block> */}
                    {/* </Block> */}
                {/* </FlexArea> */}
            </Block>
        </FlexArea>
    )
}
function aaa()
{

}
export default SignInModal;